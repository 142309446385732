import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Container,
  Grid, Link, Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  ChangeEventHandler,
  FormEventHandler,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { STATUS_CODES } from "../../../../shared/types";
import { substitute } from "../../../../shared/utils";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useAuth } from "../../components/auth";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";

const Component: FunctionComponent = () => {
  //#region Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const history = useHistory<{ redirect?: string; }>();
  const { VERIFY_EMAIL: messages, ERRORS: errors } = useMessages();
  const { user, token, setToken } = useAuth();
  const [remainingCodeCooldown, setRemainingCodeCooldown] = useState(0);
  const snackbar = useSnackbar();
  //#endregion

  //#region State
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  //#endregion

  //#region Form Fields
  const [verificationCode, setVerificationCode] = useState("");
  //#endregion

  //#region Form Fields Errors
  const [verificationCodeErrors, setVerificationCodeErrors] = useState("");
  //#endregion

  //#region Use Effects
  // If a user manually goes to /verify, this will ensure the user is redirect to the original page.
  useEffect(() => {
    if (user && user.verified === true) {
      history.replace(
        history.location.state?.redirect || "/calendar"
      );
    }
  }, [user, history]);

  useEffect(() => {
    let timeout = 0;

    if (remainingCodeCooldown > 0) {
      timeout = setTimeout(() => {
        setRemainingCodeCooldown(remainingCodeCooldown - 1);
      }, 1000) as any;
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [remainingCodeCooldown]);
  //#endregion

  //#region Change event handlers
  const onVerifcationCodeChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    changeVerifcationCode(event.target.value);
  };

  const changeVerifcationCode = (value: string) => {
    // Validators
    if (value.length === 0) {
      setVerificationCodeErrors(errors.REQUIRED);
    } else {
      setVerificationCodeErrors("");
    }

    setVerificationCode(value);
  };
  //#endregion

  //#region Validity Checks
  const isVerificationSubmitable = () => {
    if (isLoading || verificationCode.trim() === "") return false;
    return true;
  };
  //#endregion

  //#region Submit Event Handlers
  const onVerifySubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    // Populate error messages
    changeVerifcationCode(verificationCode);

    if (!isVerificationSubmitable()) return;

    try {
      setIsLoading(true);

      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/verify`,
        {
          method: "POST",

          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            code: verificationCode.trim(),
          }),
        }
      );

      switch (response.status) {
        case STATUS_CODES.OK:
          setVerificationCodeErrors("");
          setToken((await response.json()).token);

          break;

        case STATUS_CODES.UNAUTHORIZED:
          setVerificationCodeErrors(errors.INVALID_VERIFICATION_CODE);
          break;

        default:
          snackbar.show(errors.TRY_LATER);
          break;
      }

      setIsLoading(false);
    } catch (error) {
      snackbar.show(errors.TRY_LATER);
      console.error("Network Error");
      setIsLoading(false);
    }
  };
  //#endregion

  // Send a new verification code and return true if there is an available code
  const resendVerificationCode = async () => {
    if (remainingCodeCooldown > 0) return false;

    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/verify/resend`,
        {
          method: "POST",

          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      switch (response.status) {
        case STATUS_CODES.OK:
          setRemainingCodeCooldown(60);
          setIsSent(true);
          return true;

        case STATUS_CODES.FORBIDDEN:
          setVerificationCodeErrors(errors.RATE_LIMIT);
          return true;

        default:
          snackbar.show(errors.TRY_LATER);
          break;
      }
    } catch (error) {
      snackbar.show(errors.TRY_LATER);
      console.error("Network Error");
    }

    return false;
  };

  //#region HTML
  return (
    <Container
      sx={{
        px: { xs: 0, sm: 2 },
        pb: { xs: 0, sm: 2 },
        pt: 2,
        display: "flex",
        flexDirection: "column",
        gap: { xs: 0, sm: 2 },
        mt: 10
      }}
      maxWidth="sm"
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Logo height={theme.spacing(5)} />
      </Box>
      <Paper elevation={0}>
        <Typography
          sx={{ p: { xs: 2, sm: 0 } }}
          variant="h5"
          fontWeight="bold"
          textAlign="center"
        >
          {user?.verified ? messages.SUCCESS : messages.TITLE}
        </Typography>
      </Paper>
      {!user?.verified && (
        <Box sx={{ px: { xs: 0, sm: 8 } }}>
          <Paper
            sx={{
              borderRadius: { xs: 0, sm: 1 },
              boxShadow: "none",
              p: { xs: 2, sm: 4 },
            }}
            elevation={theme.palette.mode !== "dark" ? 0 : 2}
            variant={
              theme.palette.mode === "dark" || isMobile
                ? "elevation"
                : "outlined"
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  {!user ? "" : substitute(isSent ? messages.SENT : messages.DESCRIPTION, { EMAIL: user.email })}
                </Typography>
              </Grid>
              {!isSent ? (
                <Grid item xs={12}>
                  <LoadingButton
                    sx={{ textTransform: "none" }}
                    disableElevation
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    onClick={resendVerificationCode}
                  >
                    {messages.SEND}
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  component="form"
                  onSubmit={onVerifySubmit}
                  noValidate
                >
                  <TextField
                    fullWidth
                    label={messages.CODE}
                    value={verificationCode}
                    error={verificationCodeErrors !== ""}
                    helperText={verificationCodeErrors}
                    autoComplete="one-time-code"
                    onChange={onVerifcationCodeChange}
                  />
                  <Box>
                    <Link
                      component="button"
                      variant="body1"
                      disabled={remainingCodeCooldown > 0}
                      type="button"
                      onClick={resendVerificationCode}
                    >
                      {messages.RESEND}{" "}
                      {remainingCodeCooldown > 0
                        ? `(${remainingCodeCooldown}s)`
                        : ""}
                    </Link>
                  </Box>
                  <Grid item xs={12}>
                    <LoadingButton
                      sx={{ textTransform: "none", mt: 2 }}
                      disableElevation
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                    >
                      {messages.SEND}
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      )}
    </Container>
  );
  //#endregion
};

export default Component;
