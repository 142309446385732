import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, CardActionArea, CircularProgress, Container, IconButton, InputAdornment, Paper, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import { ChangeEventHandler, FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useThrottledCallback } from 'use-debounce';
import { ACCOUNT_TYPE_NAMES } from "../../../../shared/config";
import { Role, STATUS_CODES } from "../../../../shared/types";
import { getAccountTableId, getRole, hasRole } from "../../../../shared/utils";
import { useRequireAuth } from "../../components/auth";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";

interface AdminUserSummary {
    id: number;
    name: string;
    email: string;
}

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const history = useHistory();
    const { ACCOUNTS: messages, ERRORS: errors } = useMessages();
    const { user, token } = useRequireAuth();
    const snackbar = useSnackbar();
    //#endregion

    //#region Other
    const fetchMoreData = async (newSearch: boolean) => {
        if (searchQuery.length < 3 && hasRole(user, Role.ADMIN)) {
            setUsers([]);
            setStartKey(0);
            return;
        }

        if (newSearch) setStartKey(0);

        const searchResult = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
            method: "POST",

            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ search: searchQuery, startKey: newSearch ? 0 : startKey })
        });

        switch (searchResult.status) {
            case STATUS_CODES.OK:
                let data = await searchResult.json();
                setUsers(users => newSearch ? data.data : users.concat(data.data));
                setStartKey(data.startKey);
                break;

            case STATUS_CODES.UNAUTHORIZED:
                snackbar.show(errors.UNAUTHORIZED);
                break;

            default:
                snackbar.show(errors.TRY_LATER);
                break;
        }
    };
    //#endregion

    const throttledSearch = useThrottledCallback(() => { fetchMoreData(true).then(() => { }); }, 200);

    //#region State
    const [startKey, setStartKey] = useState(0);;
    const [users, setUsers] = useState<AdminUserSummary[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    //#endregion

    //#region Use Effects
    useEffect(() => {
        if (user) {
            throttledSearch();
        }
    }, [throttledSearch, searchQuery, user]);

    useEffect(() => {
        let params = new URLSearchParams(history.location.search);
        let search = params.get("search");

        if (search) {
            setSearchQuery(search);
        }
    }, [history]);

    useEffect(() => {
        if (!user) {
            return;
        }

        let params = new URLSearchParams();
        params.set("search", searchQuery);
        history.replace({ search: params.toString() });
    }, [history, searchQuery, user]);
    //#endregion

    //#region Change event handlers
    const onSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSearchQuery(event.target.value);
    };

    const changeSearchQuery = (value: string) => {
        setSearchQuery(value);
    };
    //#endregion

    const isItemLoaded = (index: number) => startKey > 0 || index < users.length;
    const itemCount = startKey > 0 ? users.length + 1 : users.length;

    const Item = ({ index, style }: { index: number, style: React.CSSProperties; }) => {
        if (!isItemLoaded(index)) {
            return <Box style={style} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <CircularProgress size={20} />
            </Box>;
        }

        let account = users[index];
        return !account ? null : <Card
            sx={{
                borderRadius: "0px",
                boxShadow: "none",
                border: theme.palette.mode === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : undefined,
                borderBottomWidth: "0",
                "&:first-of-type": { borderTopLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderTopRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; } },
                "&:last-of-type": { borderBottomWidth: "1px", borderBottomLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderBottomRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; } }
            }}
            style={style} elevation={theme.palette.mode === "dark" ? (index % 2) * 2 + 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} key={account.id} onClick={() => { history.push(`/account/${account.id}`); }}>
            <CardActionArea sx={{ p: 2, display: "flex", flexDirection: "row", gap: 2 }}>
                {!hasRole(user, Role.ADMIN) ? null : <Typography sx={{ flex: 1 }}>{getAccountTableId(account.id)}</Typography>}
                <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", flex: "1" }}>{account.name}</Typography>
                {!hasRole(user, Role.ADMIN) ? null : <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", flex: "1" }}>{account.email}</Typography>}
                <Typography sx={{ textAlign: "right", flex: "1" }}>{ACCOUNT_TYPE_NAMES[getRole(account.id)]}</Typography>
            </CardActionArea>
        </Card>;
    };

    //#region HTML
    return (<Container sx={{ p: { xs: 0, sm: 2 }, height: "100%", display: "flex", flexDirection: "column", position: "relative" }} maxWidth="sm">
        <Toolbar />
        <WebinarAlert desktop={false} />
        <Paper elevation={0} sx={{ p: { xs: 0, sm: 2 }, flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ px: { xs: 2, sm: 0 }, pt: { xs: 2, sm: 0 }, display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Typography variant="h5" fontWeight="bold" textAlign="center" sx={{ flex: 1, pl: hasRole(user, [Role.PARENT, Role.ADMIN]) ? 7 : 0 }}>
                        {messages.TITLE}
                    </Typography>
                    {!hasRole(user, [Role.PARENT, Role.ADMIN]) ? null :
                        <IconButton aria-label="Close" onClick={() => { history.push("/enroll"); }}>
                            <AddIcon />
                        </IconButton>
                    }
                </Box>
                <Paper sx={{ borderRadius: 7, boxShadow: "none" }} elevation={theme.palette.mode === "dark" ? 2 : 0}>
                    <TextField
                        fullWidth
                        value={searchQuery}
                        placeholder={messages.ENTER_SEARCH}
                        onChange={onSearchQueryChange}
                        InputProps={{
                            startAdornment:
                                < InputAdornment position="start" >
                                    <SearchIcon />
                                </InputAdornment>,
                            sx: { borderRadius: 7 }
                        }}
                    />
                </Paper>
            </Box>
            {users.length !== 0 ? null :
                <Paper sx={{
                    p: 2, borderRadius: { xs: 0, sm: 1 }, boxShadow: "none",
                    border: theme.palette.mode === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : undefined,
                }}>
                    <Typography>{(searchQuery.length < 3 && hasRole(user, Role.ADMIN)) ? messages.ENTER_SEARCH : messages.NO_ACCOUNTS}</Typography>
                </Paper>
            }
            <Box sx={{ flex: 1 }}>
                <AutoSizer>
                    {({ height, width }) =>
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={itemCount}
                            loadMoreItems={() => { fetchMoreData(false).then(() => { }); }}
                        >
                            {({ onItemsRendered, ref }) => (
                                <FixedSizeList
                                    itemCount={itemCount}
                                    onItemsRendered={onItemsRendered}
                                    ref={ref}
                                    height={height}
                                    width={width}
                                    itemSize={56}
                                >
                                    {Item}
                                </FixedSizeList>
                            )}
                        </InfiniteLoader>
                    }
                </AutoSizer>
            </Box>
        </Paper>
    </Container >
    );
    //#endregion
};

export default Component;