import { FunctionComponent, ChangeEventHandler, useState, useEffect } from 'react'
import { Email, FacebookRounded, Instagram, Twitter, LinkedIn, Pinterest, YouTube, Google, Phone, Public} from '@mui/icons-material';
import { Avatar, TextField, Stack, IconButton, InputAdornment, Paper, Container, Card, CardContent, Toolbar, Typography, CircularProgress, Grid} from '@mui/material'; 
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { STATUS_CODES } from "../../../../shared/types";
import WebinarAlert from "../../components/webinarAlert"; 
import { useHistory } from 'react-router';
import { useRequireAuth } from '../../components/auth';
import { useSnackbar } from '../../components/snackbar';
import { useThrottledCallback } from 'use-debounce/lib';
import { useMessages } from '../../components/messages';
import Arabic from "../../../../shared/languages/ar.json"; 

interface SchoolSummary {
    id: number;
    name: string;
    email: string; 
    slogan: string; 
    address: string;
    url: string; 
    logo: string; 
    city: string; 
    country: number;
    facebook: string; 
    instagram: string; 
    twitter: string; 
    linkedin: string; 
    youtube: string; 
    pin: string; 
    google: string;
}

const Component: FunctionComponent = () => {

    const theme = useTheme();
    const history = useHistory();
    const { DIRECTORY: messages, ERRORS: errors } = useMessages();  
    const { DIRECTORY: arabicMessages } = Arabic; 
    const {user, token} = useRequireAuth();
    const snackbar = useSnackbar();  

    const fetchMoreData = async (newSearch: boolean) => {
        // if(searchQuery.length < 3){
        //     setSchools([]);
        //     setStartKey(0); 
        //     return; 
        // }

        if (newSearch) setStartKey(0); 

        const searchResult = await fetch(`${process.env.REACT_APP_API_URL}/api/directory`, {
            method: 'POST', 

            headers: {
                "content-type": "application/json", 
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({search: searchQuery, startKey: newSearch ? 0 : startKey})
        }); 

        switch (searchResult.status){
            case STATUS_CODES.OK: 
                let data = await searchResult.json(); 
                setSchools(schools => newSearch ? data.data : schools.concat(data.data)); 
                setStartKey(data.startKey); 
                break; 
            
            case STATUS_CODES.UNAUTHORIZED: 
                snackbar.show(errors.UNAUTHORIZED); 
                break; 
            default: 
                snackbar.show(errors.TRY_LATER); 
                break; 
        }
    }

    const throttledSearch = useThrottledCallback(() => {fetchMoreData(true).then(() => { }); }, 200); 

    const [startKey, setStartKey] = useState(0); 
    const [schools, setSchools] = useState<SchoolSummary[]>([]); 
    const [searchQuery, setSearchQuery] = useState(""); 

    useEffect(() => {
        if (user) {
            throttledSearch();
        }
    }, [throttledSearch, searchQuery, user]);
    
    useEffect(() => {
        let params = new URLSearchParams(history.location.search); 
        let search = params.get("search"); 

        if(search){
            setSearchQuery(search); 
        }
    }, [history]); 

    useEffect(() => {
        if(!user){
            return; 
        }

        let params = new URLSearchParams(); 
        params.set("search", searchQuery); 
        history.replace({ search: params.toString() }); 

    }, [history, searchQuery, user]); 

    const onSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSearchQuery(event.target.value); 
    }

    const changeSearchQuery = (value: string) => {
        setSearchQuery(value); 
    }

    const isItemLoaded = (index : number) => startKey > 0 || index < schools.length;

    const Item = ({index} : {index: number}) => {

        if(!isItemLoaded(index)) {
            return <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <CircularProgress size={20} />
            </Box>
        }
        
        
        let item = schools[index];
        return (
        <Card style={{ maxWidth: 600 }} sx={{display: 'flex', flexDirection: 'row', justifyContent: "initial"}} >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Avatar sx={{ margin: '28px', width: (theme) => theme.spacing(12), height: (theme) => theme.spacing(12) }} 
                src={`${process.env.REACT_APP_LEGACY_URL}/images/avatars/logos/${item.logo}`} alt="avatar" 
            />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography component="div" variant="h5">
                        {item.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {item.slogan}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" component="div">
                        <br/>
                        {item.address}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" component="div">
                        {item.city}, {item.country}
                    </Typography>
                    <br/>
                    <Box sx={{display: 'flex', justifyContent: "flex-start"}}>  
                        <Stack direction="row" alignItems="left" spacing={1}>
                            <IconButton size="small" onClick={() => window.open(item.url, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <Public/>
                            </IconButton>
                            <IconButton size="small" onClick={() => window.open(`mailto:${item.email}`, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <Email/>
                            </IconButton>
                            <IconButton size="small"style={{backgroundColor: '#FFA500'}}>
                                <Phone/>
                            </IconButton>
                            {(item.facebook !== "" && item.facebook) && (
                            <IconButton size="small" onClick={() => window.open(item.facebook, "_blank")} style={{backgroundColor: '#FFA500'}}>
                                <FacebookRounded/>
                            </IconButton>
                            )}
                            {(item.instagram !== "" && item.instagram) && (
                            <IconButton size="small" onClick={() => window.open(item.instagram, "_blank")} style={{backgroundColor: '#FFA500'}}>
                                <Instagram/>
                            </IconButton>
                            )}
                            {(item.twitter !== "" && item.twitter) && (
                            <IconButton size="small" onClick={() => window.open(item.twitter, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <Twitter/>
                            </IconButton>
                            )}
                            {(item.linkedin !== "" && item.linkedin) && (
                            <IconButton size="small" onClick={() => window.open(item.linkedin, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <LinkedIn/>
                            </IconButton>
                            )}
                            {(item.pin !== "" && item.pin) && (
                            <IconButton size="small" onClick={() => window.open(item.pin, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <Pinterest/>
                            </IconButton>
                            )}
                            {(item.youtube !== "" && item.youtube) && (
                            <IconButton size="small" onClick={() => window.open(item.youtube, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <YouTube/>
                            </IconButton>
                            )}
                            {(item.google !== "" && item.google) && (
                            <IconButton size="small" onClick={() => window.open(item.google, "_blank")}  style={{backgroundColor: '#FFA500'}}>
                                <Google/>
                            </IconButton> 
                            )}
                        </Stack>
                    </Box>
                </CardContent>
            </Box>
        </Card>
        );
    }
        return (<Container sx={{ p: { xs: 0, sm: 2 }, height: "100%", display: "flex", flexDirection: "column", position: "relative" }} maxWidth="lg">
            <Toolbar/>
            
            <WebinarAlert desktop={false}/>
            <Paper elevation={0} sx={{ p: { xs: 0, sm: 2 }, flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ px: { xs: 2, sm: 0 }, pt: { xs: 2, sm: 0 }, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column"}}>
                        <Typography variant="h5" fontWeight="bold" textAlign="center" sx={{ flex: 1, pl: 0 }}>
                            {arabicMessages.TITLE}
                        </Typography>
                        <Typography variant="h5" fontWeight="bold" textAlign="center" sx={{ flex: 1, pl: 0 }}>
                            {messages.TITLE}
                        </Typography>
                    </Box>
                    <Paper sx={{ borderRadius: 7, boxShadow: "none" }} elevation={theme.palette.mode === "dark" ? 2 : 0}>
                        <TextField
                            fullWidth
                            value={searchQuery}
                            placeholder={arabicMessages.SEARCH + " " + messages.SEARCH}
                            onChange={onSearchQueryChange}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start" >
                                        <SearchIcon />
                                    </InputAdornment>,
                                sx: { borderRadius: 7 }
                            }}
                        />
                    </Paper>
                </Box>

            {schools.length !== 0 ? null :
                searchQuery.length < 3 ? null : (
                    <Paper sx={{
                        p: 2, borderRadius: { xs: 0, sm: 1 }, boxShadow: "none",
                        border: theme.palette.mode === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : undefined,
                    }}>
                        <Typography>{arabicMessages.NOSCHOOLS + " " + messages.NOSCHOOLS}</Typography>
                    </Paper>
                )
            }
            
            <Box sx={{ width: '100%', flex: 1 }}>    
                <Grid container spacing={2} columns={16}>
                    {schools.map((_, i) => (
                    <Grid item xs={8} key={i}>    
                        <Item key={i} index={i}/>
                    </Grid>
                    ))}
                </Grid>    
            </Box>
        </Paper>
        </Container>
    ); 
}

export default Component; 

