//#region Enums
export enum STATUS_CODES {
    INTERNAL_SERVER_ERROR = 500,
    BAD_REQUEST = 422, // Supposed to be 400 but using 422 to bypass apache handler
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    CREATED = 201,
    OK = 200
}

export enum EventType {
    BOOTCAMP,
    MAIN,
    SUPPORT,
    CO_CURRICULAR,
    GROUP,
    HOLIDAY,
    PERSONAL
}

export enum Role {
    PARENT,
    STUDENT,
    TEACHER,
    ADMIN,
    SCHOOL,
}

export enum Appearance {
    LIGHT = "LIGHT",
    DARK = "DARK",
    SYSTEM = "SYSTEM",
}

export enum EnrollmentVersion {
    V1,
    V2,
    V3,
    WEBINAR1,
    WEBINAR2,
    WEBINAR3,
    WEBINAR4,
}
//#endregion

//#region Interfaces
export interface User {
    id: number,
    name: string,
    email: string,
    verified: boolean,
    schoolId?: number,
    pic: string;
}

export interface UserSummary {
    id: number;
    name: string;
}

export interface Email {
    name: string;
    variables: Variable[];
}

export interface Variable {
    name: string;
    value: string;
}

export interface Coupon {
    id: number,
    code: string,
    amount: number;
    percent: boolean;
}

export interface Time {
    time: number;
    teachers: string[];
}

export interface TimeSet {
    time: number;
    teachers: Set<string>;
}

export interface Session {
    times: Time[];
    dates: Date[];
}

export interface Booking {
    date: Date;
    type: EventType,
    teacher: string;
}

export interface Availability {
    id: string;
    start_date: Date;
    end_date: Date;
    teacher_id: number;
    bootcamp: boolean;
}

export interface BookingRequest {
    date: Date,
    type: EventType,
    teacher: string;
}

export interface RescheduleRequest {
    id: number,
    start: Date;
}

export interface DateIterable {
    start: Date;
    end: Date;
}

export interface DateIterator<T extends DateIterable> {
    index: number,
    data: T[];
}

export interface CalendarEvent extends DateIterable {
    id: number;
    start: Date;
    end: Date;
    title: string;
    description: string;
    type: EventType;
    attendees: UserSummary[];
    owner: UserSummary;
}

export interface Package {
    id: number;
    name: string;
    bootWeeks: number;
    mainWeeks: number;
    supWeeks: number;
    cocurricularSessions: number;
    registrationFee: number;
    tuitionFee: number;
    cocurricularFee: number;
    sessionMultiplier: number;
    discount: number;
    specialOffer: number;
}
//#endregion