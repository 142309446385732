import { createTheme, CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider, useMediaQuery } from "@mui/material";
import darkScrollbar from '@mui/material/darkScrollbar';
import { FunctionComponent, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Appearance } from "../../shared/types";
import AuthProvider from "./components/auth";
import EnrollmentVersionProvider from "./components/enrollmentVersion";
import MessageProvider from "./components/messages";
import Navbar from "./components/navbar";
import SnackbarProvider from "./components/snackbar";
import { useSavedState } from "./hooks/savedState";
import Account from "./routes/account";
import Accounts from "./routes/accounts";
import Calendar from "./routes/calendar";
import EmailCollect from "./routes/emailCollect";
import Enroll from "./routes/enroll";
import Login from "./routes/login";
import Register from "./routes/register";
import ResendEmail from "./routes/resendEmail";
import ResetLink from "./routes/resetLink";
import ResetPassword from "./routes/resetPassword";
import VerifyEmail from "./routes/verifyEmail";
import Checkout from "./routes/checkout";
import SubscriptionCancel from "./routes/subscriptionCancel";
import Iframe from "./routes/iframe";
import Directory from "./routes/directory";

declare module '@mui/material/styles' {
  interface Palette {
    event0: Palette['primary'];
    event1: Palette['primary'];
    event2: Palette['primary'];
    event3: Palette['primary'];
    event4: Palette['primary'];
    event5: Palette['primary'];
  }
  interface PaletteOptions {
    event0: PaletteOptions['primary'];
    event1: PaletteOptions['primary'];
    event2: PaletteOptions['primary'];
    event3: PaletteOptions['primary'];
    event4: PaletteOptions['primary'];
    event5: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    event0: true;
    event1: true;
    event2: true;
    event3: true;
    event4: true;
    event5: true;
  }
}

const App: FunctionComponent = () => {
  const [appearance, setAppearance] = useSavedState(Appearance.SYSTEM, "appearance");
  const systemAppearanceDark = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (!(appearance === Appearance.LIGHT ||
      appearance === Appearance.DARK ||
      appearance === Appearance.SYSTEM)) {
      setAppearance(Appearance.SYSTEM);
    }
  }, [appearance, setAppearance]);

  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: ((appearance === Appearance.SYSTEM && systemAppearanceDark) || appearance === Appearance.DARK) ? darkScrollbar() : null,
          "body > div": {
            whiteSpace: "pre-wrap"
          },
          "body, html, #root": {
            height: "100%"
          }
        }
      }
    },
    typography: {
      fontFamily: ["ProductSans", "sans-serif"].join(',')
    },
    palette: {
      mode: ((appearance === Appearance.SYSTEM && systemAppearanceDark) || appearance === Appearance.DARK) ? "dark" : "light",
      primary: {
        main: "#6fb55d",
        contrastText: "#fff"
      },
      event0: {
        main: "#f37341",
        contrastText: "#fff"
      },
      event1: {
        main: "#ebc637",
        contrastText: "#fff"
      },
      event2: {
        main: "#5cbfdc",
        contrastText: "#fff"
      },
      event3: {
        main: "#6fb55d",
        contrastText: "#fff"
      },
      event4: {
        main: "#292a2b",
        contrastText: "#fff"
      },
      event5: {
        main: "#9d9fa2",
        contrastText: "#fff"
      },
    },
  });

  for (let i = 0; i < 5; i++) {
    (theme.palette as any)[`event${i}`] = theme.palette.augmentColor({ color: (theme.palette as any)[`event${i}`] });
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={{
          ".MuiMonthPicker-root button": {
            background: "transparent",
            border: "none",
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadius,
            ":hover": { background: (theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)") }
          }
        }} />
        <MessageProvider>
          <AuthProvider>
            <EnrollmentVersionProvider>
              <SnackbarProvider>
                <Switch>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/v5" >
                    <EmailCollect />
                  </Route>
                  <Route exact path="/reset-password" >
                    <ResetLink />
                  </Route>
                  <Route exact path="/reset-password/:code" >
                    <ResetPassword />
                  </Route>
                  <Route exact path="/login">
                    <Login admin={false} />
                  </Route>
                  <Route exact path="/verify">
                  <Navbar appearance={appearance} setAppearance={setAppearance}  hideDrawer={true} render={(drawerWidth, isDrawerOpen) => 
                    <VerifyEmail />
                    }/>
                  </Route>
                  <Route exact path="/admin/login" >
                    <Login admin={true} />
                  </Route>
                  <Route exact path={["/resend-email/:id", "/options", "/timetable", "/calendar", "/calendar/:id", "/accounts", "/account", "/account/:id", "/i/:type", "/directory"]}>
                    <Navbar appearance={appearance} setAppearance={setAppearance} render={(drawerWidth, isDrawerOpen) =>
                      <Switch>
                        <Route exact path="/resend-email/:id">
                          <ResendEmail />
                        </Route>
                        <Route exact path={["/account", "/account/:id"]}>
                          <Account />
                        </Route>
                        <Route exact path="/options" >
                          <Redirect to="/calendar" />
                        </Route>
                        <Route exact path="/timetable" >
                          <Redirect to="/calendar" />
                        </Route>
                        <Route exact path={["/calendar", "/calendar/:id"]}>
                          <Calendar drawerWidth={drawerWidth} isDrawerOpen={isDrawerOpen} />
                        </Route>
                        <Route exact path="/accounts">
                          <Accounts />
                        </Route>
                        <Route exact path="/directory">
                          <Directory />
                        </Route>
                        <Route exact path="/i/:type">
                          <Iframe />
                        </Route>
                      </Switch>
                    } />
                  </Route>
                  <Route exact path={["/enroll", "/enroll/:id"]}>
                    <Navbar appearance={appearance} setAppearance={setAppearance}  hideDrawer={true} render={(drawerWidth, isDrawerOpen) => 
                      <Enroll />
                    }/>
                  </Route>
                  <Route exact path="/pay/:id">
                    <Checkout />
                  </Route>  
                  <Route exact path="/subscription/:subscriptionId/cancel">
                    <SubscriptionCancel />
                  </Route>
                  <Route path="*" >
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              </SnackbarProvider>
            </EnrollmentVersionProvider>
          </AuthProvider>
        </MessageProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
