import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { CANADA_INDEX, EVENT_INFO, REGISTRATION_FEE } from "../../../../../shared/config";
import { Coupon, EnrollmentVersion, EventType, Role } from "../../../../../shared/types";
import { getRole, substitute } from "../../../../../shared/utils";
import { useEnrollmentVersion } from "../../../components/enrollmentVersion";
import { useMessages } from "../../../components/messages";

interface Props {
    packageInfo: any;
    registrationFee: number;
    country: number | null;
    coupon: Coupon | null;
    sessionWeeks: number[];
    userId: number;
}

const Component: FunctionComponent<Props> = ({ packageInfo, registrationFee, country, coupon, sessionWeeks, userId }) => {
    const { PRICING: pricingMessages } = useMessages();
    const { version } = useEnrollmentVersion();

    const applyWebinarDiscount = (price: number) => {
        return price - (price * packageInfo.discount / 100);
    };

    const discountPrice = (price: number) => {
        price = applyWebinarDiscount(price);
        if (!coupon) return price;
        return coupon.percent ? (price - (price * coupon.amount / 100)) : (price - coupon.amount);
    };

    const getTutoringSessionsCount = () => {
        let total = 0;

        for (let i = 0; i < EventType.SUPPORT + 1; i++) {
            total += EVENT_INFO[i].daysPerWeek * sessionWeeks[i] * (i !== EventType.BOOTCAMP ? (packageInfo?.sessionMultiplier || 1) : 1);
        }

        return total;
    };

    return version === EnrollmentVersion.WEBINAR4 ?
        <Typography sx={{ p: 2 }}>
            {substitute(pricingMessages.ASSESSMENT_FEE, {
                AMOUNT: (packageInfo?.tuitionFee / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            })}
            {
                country !== CANADA_INDEX ? "" : substitute(`\n${pricingMessages.HST}`, {
                    AMOUNT: (Math.floor(packageInfo?.tuitionFee * 0.13) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })
            }
            <Box component="span" sx={{ fontWeight: "bold" }}>
                {substitute(`\n\n${pricingMessages.SUBTOTAL}`, {
                    AMOUNT: (Math.floor(packageInfo?.tuitionFee) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })}
            </Box>
            <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }} color="error">
                {
                    substitute(`\n\n${packageInfo?.specialOffer ? pricingMessages.SPECIAL_OFFER : pricingMessages.TOTAL}`, {
                        AMOUNT: (Math.floor((packageInfo?.specialOffer ? packageInfo?.specialOffer : packageInfo?.tuitionFee) +
                            (country === CANADA_INDEX ? Math.floor((packageInfo?.specialOffer ? packageInfo?.specialOffer : packageInfo?.tuitionFee) * 0.13) : 0)) / 100)
                            .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    })}
            </Typography>
            {
                !packageInfo?.specialOffer ? null :
                    <Box component="span" sx={{ fontWeight: "bold", color: (theme) => theme.palette.primary.main }}>
                        {`\n${pricingMessages.TOTAL_VALUE}`}<Box component="span" sx={{ fontWeight: "bold", textDecoration: "line-through" }}>
                            {substitute(pricingMessages.TOTAL_VALUE_AMOUNT, {
                                AMOUNT: (Math.floor(packageInfo?.tuitionFee + (country === CANADA_INDEX ? Math.floor(packageInfo?.tuitionFee * 0.13) : 0)) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            })}
                        </Box>
                    </Box>
            }
            <Box component="span" sx={{ fontWeight: "bold", color: (theme) => theme.palette.primary.main }} >
                {`\n\n${pricingMessages.COUPON_INFO}`}
            </Box>
        </Typography>
        :
        <Typography sx={{ p: 2 }}>
            {
                substitute(pricingMessages.TUTORING_SESSIONS, {
                    BOOTCAMP_COUNT: (EVENT_INFO[EventType.BOOTCAMP].daysPerWeek * sessionWeeks[EventType.BOOTCAMP]).toLocaleString(),
                    MAIN_COUNT: (EVENT_INFO[EventType.MAIN].daysPerWeek * sessionWeeks[EventType.MAIN] * (packageInfo?.sessionMultiplier || 1)).toLocaleString(),
                    SUPPORT_COUNT: (EVENT_INFO[EventType.SUPPORT].daysPerWeek * sessionWeeks[EventType.SUPPORT] * (packageInfo?.sessionMultiplier || 1)).toLocaleString()
                })
            }
            {
                !packageInfo?.complementaryCocurricularSessions ? null :
                    substitute(`\n${pricingMessages.COMPLEMENTARY_CO_CURRICULAR_SESSIONS}`, { AMOUNT: packageInfo?.complementaryCocurricularSessions.toLocaleString() })
            }
            {
                !packageInfo?.cocurricularSessions ? null :
                    substitute(`\n${pricingMessages.CO_CURRICULAR_SESSIONS}`, { AMOUNT: packageInfo?.cocurricularSessions.toLocaleString() })
            }
            <Box component="span" sx={{ fontWeight: "bold" }}>
                {
                    substitute(`\n${pricingMessages.TOTAL_SESSIONS}`, { AMOUNT: (getTutoringSessionsCount() + (packageInfo?.complementaryCocurricularSessions || 0) + (packageInfo?.cocurricularSessions || 0)).toLocaleString() })
                }
            </Box>
            {
                !(getRole(userId) === Role.STUDENT) &&
                substitute(`\n\n${pricingMessages.REGISTRATION_FEE}`, { AMOUNT: (registrationFee / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })
            }
            {
                substitute(`\n${pricingMessages.TUITION_FEE}`, { AMOUNT: (packageInfo?.tuitionFee / 100).toLocaleString() })
            }
            {
                !packageInfo.cocurricularFee ? null :
                    substitute(`\n${pricingMessages.CO_CURRICULAR_FEE}`, { AMOUNT: (packageInfo?.cocurricularFee / 100).toLocaleString() })
            }
            <Box component="span" sx={{ fontWeight: "bold" }}>
                {
                    substitute(`\n\n${pricingMessages.SUBTOTAL}`, {
                        AMOUNT: (Math.floor(registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) / 100)
                            .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    })
                }
            </Box>
            { // Webinar Discount
                !packageInfo?.discount ? null :
                    <Box component="span" sx={{ fontWeight: "bold", color: (theme) => theme.palette.primary.main }} >
                        {substitute(`\n${pricingMessages.WEBINAR_DISCOUNT} (${packageInfo?.discount}%)`, {
                            AMOUNT: (Math.floor((REGISTRATION_FEE + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) - applyWebinarDiscount(REGISTRATION_FEE + packageInfo?.tuitionFee + packageInfo?.cocurricularFee)) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        })}
                    </Box>
            }
            {
                !coupon ? "" : substitute(`\n${pricingMessages.COUPON_DISCOUNT}`, {
                    AMOUNT: (Math.floor(applyWebinarDiscount(registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) - discountPrice(registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee)) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })
            }
            {
                country !== CANADA_INDEX ? "" : substitute(`\n${pricingMessages.HST}`, {
                    AMOUNT: (Math.floor(discountPrice(registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) * 0.13) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })
            }
            <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }} color="error">
                {substitute(`\n\n${packageInfo?.specialOffer ? pricingMessages.SPECIAL_OFFER : pricingMessages.TOTAL}`, {
                    AMOUNT: (Math.floor(discountPrice(packageInfo?.specialOffer ? packageInfo?.specialOffer : (registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee)) +
                        (country === CANADA_INDEX ? Math.floor(discountPrice(packageInfo?.specialOffer ? packageInfo?.specialOffer : (registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee)) * 0.13) : 0)) / 100)
                        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })}
            </Typography>
            {
                !packageInfo?.specialOffer ? null :
                    (<Box component="span" sx={{ fontWeight: "bold", color: (theme) => theme.palette.primary.main }} >
                        {`\n${pricingMessages.TOTAL_VALUE}`}
                        <Box component="span" sx={{ fontWeight: "bold", textDecoration: "line-through" }}>
                            {substitute(pricingMessages.TOTAL_VALUE_AMOUNT, {
                                AMOUNT: (Math.floor((registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) +
                                    (country === CANADA_INDEX ? Math.floor(discountPrice(registrationFee + packageInfo?.tuitionFee + packageInfo?.cocurricularFee) * 0.13) : 0)) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            })}
                        </Box>
                    </Box>
                    )
            }
        </Typography>;
};

export default Component;
