import {
  Box, CircularProgress, Container, Paper, TextField, Theme, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import {
  ChangeEventHandler,
  FunctionComponent, useEffect, useState
} from "react";
import { useParams } from "react-router-dom";
import { CANADA_INDEX } from "../../../../shared/config";
import { STATUS_CODES } from "../../../../shared/types";
import { substitute } from "../../../../shared/utils";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useMessages } from "../../components/messages";
import PaymentForm from "../../components/paymentForm";
import { useSnackbar } from "../../components/snackbar";
import { countries } from "../../countries";

const Component: FunctionComponent = () => {
  //#region Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const snackbar = useSnackbar();
  const { ERRORS: errors, CHECKOUT: messages } = useMessages();
  const { id: productId } = useParams<{ id?: string; }>();
  //#endregion

  //#region State
  const [productDetails, setProductDetails] = useState<any>({});
  const [hasFetchedProduct, setHasFetchedProduct] = useState<Boolean | null>(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [enteredCouponCode, setEnteredCouponCode] = useState("");
  const [quantityErrors, setQuantityErrors] = useState("");
  const [purchaseQuantity, setPurchaseQuantity] = useState("1");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [discount, setDiscount] = useState(0);
  //#endregion

  //#region effects
  useEffect(() => {
    if (!productId) return;

    (async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/product/${productId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
            },
          }
        );

        switch (response.status) {
          case STATUS_CODES.OK:
            const prodDetails = await response.json();
            setProductDetails(prodDetails);
            setHasFetchedProduct(true);
            setPurchasePrice(prodDetails.price ?? 0);
            break;

          default:
            setHasFetchedProduct(false);
            console.error("Call to api/product failed");
            break;
        }
      } catch (error) {
        snackbar.show(errors.TRY_LATER);
        console.error("Network Error: ", error);
      }
    })();
  }, [errors.TRY_LATER, productId, snackbar]);

  // Update purchase price when quantity is changed.
  useEffect(() => {
    if (quantityErrors === "") {
      let quantity = Number(purchaseQuantity);
      let ppp = productDetails.price;

      let updatedPrice = ppp * quantity;

      setPurchasePrice(updatedPrice);
    }
  }, [productDetails.price, purchaseQuantity, quantityErrors]);

  // Update discount amount when purchase price changes
  useEffect(() => {
    let {
      couponPercent,
      couponAmount,
      isCoupon,
      couponCode: cc,
    } = productDetails;
    if (!isCoupon) return;

    if (cc === enteredCouponCode) {
      const discount = Math.floor(
        couponPercent ? purchasePrice * (couponAmount / 100) : couponAmount
      );
      setDiscount(discount);
    } else {
      setDiscount(0);
    }
  }, [enteredCouponCode, productDetails, purchasePrice]);

  // Update tax when purchase price changes
  useEffect(() => {
    let taxes =
      selectedCountry === CANADA_INDEX ? Math.round((purchasePrice - discount) * 0.13) : 0;
    setTaxes(taxes);
  }, [selectedCountry, purchasePrice, discount]);

  //#endregion

  //#region Change event handlers
  const handleQuantityChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    let val = event.target.value;

    if (val.trim().length === 0) {
      setQuantityErrors(errors.REQUIRED);
    } else if (!event.target.validity.valid) {
      setQuantityErrors(errors.INVALID_QUANTITY);
    } else if (val.trim() === "0") {
      setQuantityErrors(errors.INVALID_QUANTITY);
    } else {
      setQuantityErrors("");
    }

    setPurchaseQuantity(val);
  };
  //#endregion

  const addTransactionData = async (
    name: string,
    email: string,
    country: number | null,
    cardBrand: string,
    transactionId: string
  ) => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/transaction`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            productId: productId,
            transactionId: transactionId,
            email: email,
            name: name,
            country: country ? countries[country].label : "",
            quantity: Number(purchaseQuantity) as number,
            taxes: taxes,
            paymentMethod: cardBrand,
            discount: discount,
            subtotal: purchasePrice,
            productName: productDetails.name,
          }),
        }
      );

      switch (response.status) {
        case STATUS_CODES.OK:
          // navigate the user
          return true;

        default:
          snackbar.show(errors.TRY_LATER);
          return false;
      }
    } catch (error) {
      snackbar.show(errors.TRY_LATER);
      console.error("Network Error");
    }
  };

  const getProductPriceText = () => {
    const amt = (Math.floor(productDetails.price) / 100).toLocaleString(
      undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );

    if (!!productDetails.subscription) {
      const { duration, durationType } = productDetails;
      let text = "";
      if (duration > 1) {
        text = substitute(`\n${messages.PRICE_PER_MULTIPLE}`, { AMOUNT: amt, DURATION: duration, TYPE: `${durationType}s` });
      } else if (duration === 1) {
        text = substitute(`\n${messages.PRICE_PER_SINGLETON}`, { AMOUNT: amt, TYPE: durationType });
      }
      return text;
    } else {
      const text = substitute(`\n${messages.PRICE_PER_UNIT}`, { AMOUNT: amt });
      return text;
    }
  };

  //#region Submit Event Handlers
  const onCardSubmit = async (
    card: StripeCardElement,
    stripe: Stripe,
    name: string,
    email: string,
    country: number | null,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCardErrors: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (quantityErrors !== "") return;

    try {
      setIsLoading(true);

      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          email: email.trim(),
          name: name.trim(),
          address: {
            country: country ? countries[country].code : "US"
          }
        },
      });

      if (paymentMethod.error) {
        setIsLoading(false);
        return setCardErrors(paymentMethod.error.message ?? "");
      }

      const cardBrand = paymentMethod.paymentMethod.card?.brand ?? "unknown";

      // TODO: Look into how to handle less thanv$0.50 subscriptions.
      if (purchasePrice - discount < 50 && !productDetails.subscription) {
        await addTransactionData(name, email, country, cardBrand, "");
        setIsLoading(false);
        snackbar.show(messages.PAYMENT_SUCCESS);

        if (productDetails.confLink) {
          window.location.replace(`${productDetails.confLink}?email=${email.trim()}&transactionId=""`);
        }
        return;
      }

      let url;
      let body;

      if (!!productDetails.subscription) {
        url = `${process.env.REACT_APP_API_URL}/api/checkout/subscription`;
        body = {
          payment_method: paymentMethod.paymentMethod.id,
          email: email,
          name,
          amount: purchasePrice + taxes - discount,
          priceId: productDetails.stripePriceId,
          quantity: Number(purchaseQuantity),
          taxes: taxes,
          country: country
        };
      } else {
        url = `${process.env.REACT_APP_API_URL}/api/checkout`;
        body = {
          payment_method: paymentMethod.paymentMethod.id,
          email: email,
          name,
          amount: purchasePrice + taxes - discount,
        };
      }

      const paymentResult = await fetch(url,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      switch (paymentResult.status) {
        case STATUS_CODES.OK:
          break;

        case STATUS_CODES.UNAUTHORIZED:
          snackbar.show(errors.UNAUTHORIZED);
          return setIsLoading(false);

        default:
          snackbar.show(errors.TRY_LATER);
          return setIsLoading(false);
      }

      const { client_secret, status, id } =
        await paymentResult.json();

      if (status === "requires_action" || status === "requires_confirmation" || status === "incomplete") {
        let result = await stripe.confirmCardPayment(client_secret, {
          payment_method: paymentMethod.paymentMethod.id
        });

        if (result.error) {
          setCardErrors(result.error.message ?? errors.TRY_LATER);
          return setIsLoading(false);
        }
      }
      if (!productDetails.subscription) {
        await addTransactionData(name, email, country, cardBrand, id);
      }

      setIsLoading(false);
      snackbar.show(messages.PAYMENT_SUCCESS);
      if (productDetails.confLink) {
        window.location.replace(`${productDetails.confLink}?email=${email.trim()}&transactionId=${id}`);
      }
    } catch (error) {
      snackbar.show(errors.TRY_LATER);
      setIsLoading(false);
    }
  };
  //#endregion

  return hasFetchedProduct == null ? (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={80} />
    </Box>
  ) : !hasFetchedProduct ? (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", maxWidth: "500px" }}>
        {messages.NOT_FOUND}
      </Typography>
    </Box>
  ) : (
    <Container
      sx={{
        p: { xs: 0, sm: 2 },
        pt: { xs: 0, sm: 4 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" }
      }}
      maxWidth="lg"
    >
      <Paper sx={{
        borderRadius: 0,
        borderBottomLeftRadius: (theme) => { return { xs: 0, md: theme.shape.borderRadius }; },
        borderTopRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius, md: 0 }; },
        borderTopLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; },
        boxShadow: "none", p: 4, flex: 1,
        borderRight: { md: "none" },
        background: (theme) => theme.palette.mode !== "dark" ? theme.palette.grey[100] : undefined
      }}
        elevation={theme.palette.mode !== "dark" ? 0 : 4}
        variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            mb: 5,
          }}
        >
          <Logo height={theme.spacing(5)} />
        </Box>
        {productDetails.name !== "" && (
          <Typography sx={{ fontSize: "24px" }}>
            {productDetails.name}
          </Typography>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {productDetails.description !== "" && (
              <Typography sx={{ mt: 2 }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: productDetails.description,
                  }}
                ></span>
              </Typography>
            )}
            {getProductPriceText()}
          </Box>
          <TextField
            label={messages.QUANTITY}
            size="small"
            sx={{ minWidth: "100px" }}
            type="number"
            value={purchaseQuantity}
            error={quantityErrors !== ""}
            helperText={quantityErrors}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 1 }}
            onChange={handleQuantityChange}
            disabled={!productDetails.quantity}
          />
        </Box>
        <Typography>
          <Box component="span" sx={{ fontWeight: "bold" }}>
            {substitute(`\n\n${messages.SUBTOTAL}`, {
              AMOUNT: (Math.floor(purchasePrice) / 100).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              ),
            })}
            {!!discount &&
              substitute(`\n${messages.COUPON_DISCOUNT}`, {
                AMOUNT: (Math.floor(discount) / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              })}
            {!!taxes &&
              substitute(`\n${messages.HST}`, {
                AMOUNT: (taxes / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              })}
          </Box>
        </Typography>
        <Typography variant="h5" component="span" sx={{ fontWeight: "bold" }}>
          {substitute(`\n${messages.TOTAL}`, {
            AMOUNT: (
              Math.floor(purchasePrice + taxes - discount) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })}
        </Typography>
      </Paper>
      <Paper sx={{
        borderRadius: 0,
        borderTopRightRadius: (theme) => { return { xs: 0, md: theme.shape.borderRadius }; },
        borderBottomLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius, md: 0 }; },
        borderBottomRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; }, boxShadow: "none", p: 2, flex: 1
      }}
        elevation={theme.palette.mode !== "dark" ? 0 : 2}
        variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
        <Typography variant="h5" p={2}>
          {messages.TITLE}
        </Typography>
        <PaymentForm
          onCardSubmit={onCardSubmit}
          setSelectedCountry={setSelectedCountry}
          setEnteredCouponCode={setEnteredCouponCode}
          showCoupon={!!productDetails.isCoupon}
          expectedCoupon={productDetails.couponCode ?? ""}
        />
      </Paper>
    </Container>
  );
};

export default Component;
