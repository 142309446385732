import { addDays, addMinutes, addMonths, isAfter, isBefore, isEqual, setHours, setMilliseconds, setMinutes, setSeconds, toDate } from "date-fns";
import { END_TIME, EVENT_INFO, START_TIME, TIMESLOT_DURATION } from "../../shared/config";
import { Availability, CalendarEvent, DateIterable, DateIterator, EventType } from "../../shared/types";
import { getAccountTableId, roundUpToNearest } from "../../shared/utils";
import { advance, getUntil } from "./dateIterator";


export interface Slot extends DateIterable {
    start: Date;
    teachers: Set<string>;
}

export const isAllDay = (start: Date, end: Date, day: Date) => {
    let startDate = setHours(setMinutes(setSeconds(setMilliseconds(day, 0), 0), 0), 0);
    let endDate = addDays(startDate, 1);

    return (isBefore(start, startDate) || isEqual(start, startDate)) &&
        (isBefore(endDate, end) || isEqual(end, endDate));
};

export const overlapsEvents = (date: Date, holidays: CalendarEvent[]) => {
    for (const holiday of holidays) {
        if ((isBefore(holiday.start, date) || isEqual(holiday.start, date)) &&
            (isBefore(date, holiday.end) || isEqual(date, holiday.end)))
            return true;
    }

    return false;
};

export const getDates = (startDate: Date, type: EventType, count: number, holidays: CalendarEvent[]) => {
    let dates: Date[] = [];

    if (overlapsEvents(startDate, holidays)) return [];

    while (count > 0) {
        if (!overlapsEvents(startDate, holidays)) {
            dates.push(startDate);
            count--;
        } else if (type === EventType.BOOTCAMP) {
            return [];
        }

        startDate = addDays(startDate, 7);
    }

    return dates;
};

const getAvailableTeachers = (starts: Date[], ends: Date[], availabilities: Availability[], type: EventType) => {
    let teacherCounts: Record<string, boolean[]> = {};

    // Generate availability counts
    for (let i = 0; i < starts.length; i++) {
        let start_date = starts[i];
        let end_date = ends[i];

        for (const timeslot of availabilities) {
            if (
                (!timeslot.bootcamp || type === EventType.BOOTCAMP) &&
                timeslot.start_date.getUTCDay() === start_date.getUTCDay() &&
                (isBefore(timeslot.start_date, start_date) || isEqual(timeslot.start_date, start_date)) &&
                (isAfter(timeslot.end_date, end_date) || isEqual(timeslot.end_date, end_date)) &&
                timeslot.start_date.getUTCHours() * 60 + timeslot.start_date.getUTCMinutes() <= start_date.getUTCHours() * 60 + start_date.getUTCMinutes() &&
                timeslot.end_date.getUTCHours() * 60 + timeslot.end_date.getUTCMinutes() >= end_date.getUTCHours() * 60 + end_date.getUTCMinutes()
            ) {
                if (!(timeslot.teacher_id in teacherCounts)) {
                    teacherCounts[timeslot.teacher_id] = new Array(starts.length).fill(false);
                }

                teacherCounts[timeslot.teacher_id][i] = true;
            }
        }
    }

    // Filter out unavailiable teachers
    let output = new Set<string>();
    for (const teacher of Object.keys(teacherCounts)) {
        if (!teacherCounts[teacher].includes(false))
            output.add(teacher);
    }

    return output;
};

export const generateTeacherView = (startDate: Date, endDate: Date, events: CalendarEvent[], allEvents: CalendarEvent[], rescheduleDates: CalendarEvent[], availabilities: Availability[]): DateIterator<Slot> => {
    if (rescheduleDates.length === 0) return { index: 0, data: [] };

    events = events.filter(event => {
        if (!(event.type === EventType.BOOTCAMP || event.type === EventType.MAIN || event.type === EventType.SUPPORT)) return false;
        if (rescheduleDates.findIndex(booking => event.id === booking.id) !== -1) return false;
        if (event.attendees.length > 0 && rescheduleDates[0].attendees[0].id !== event.attendees[0].id) return false;
        return true;
    });

    //allEvents = [];

    let eventView: DateIterator<CalendarEvent> = { index: 0, data: allEvents.filter(event => event.type !== EventType.PERSONAL && rescheduleDates.findIndex(booking => event.id === booking.id) === -1) };

    // Find the first event that is not a bootcamp
    let firstNonBootcampIndex = 0;
    while (firstNonBootcampIndex < events.length) {
        if (events[firstNonBootcampIndex].type === EventType.MAIN || events[firstNonBootcampIndex].type === EventType.SUPPORT) {
            break;
        }

        firstNonBootcampIndex++;
    }

    if (rescheduleDates[0].type === EventType.BOOTCAMP) {
        // Make sure all bootcamp come before main and support
        if (firstNonBootcampIndex < events.length && isBefore(events[firstNonBootcampIndex].start, endDate)) {
            endDate = setHours(setMinutes(setSeconds(setMilliseconds(events[firstNonBootcampIndex].start, 0), 0), 0), 0);
        }
    } else {
        // Make sure all main and support come after bootcamp
        if (firstNonBootcampIndex > 0 && isBefore(startDate, events[firstNonBootcampIndex - 1].end)) {
            startDate = addDays(setHours(setMinutes(setSeconds(setMilliseconds(events[firstNonBootcampIndex - 1].end, 0), 0), 0), 0), 1);
        }
    }

    let slots: Slot[] = [];
    while (isBefore(startDate, endDate)) {

        // Limit rescheulding date difference to 6 months
        let starts = getDates(startDate, rescheduleDates[0].type, rescheduleDates.length, allEvents.filter(event => event.type === EventType.HOLIDAY));
        if (starts.length !== rescheduleDates.length ||
            isBefore(addMonths(rescheduleDates[rescheduleDates.length - 1].start, 6), starts[starts.length - 1])
        ) {
            startDate = addDays(startDate, 1);
            continue;
        }

        // Skip day if events already exist
        let tempEventView: DateIterator<CalendarEvent> = { index: 0, data: events };
        let conflicts = false;
        for (let i = 0; i < starts.length; i++) {
            advance(tempEventView, starts[i]);
            let overlap = getUntil(tempEventView, addDays(starts[i], 1));

            if (overlap.length > 0) {
                conflicts = true;
                break;
            }
        }

        if (conflicts) {
            startDate = addDays(startDate, 1);
            continue;
        }

        // Populate slots
        for (let time = START_TIME; time < END_TIME; time += roundUpToNearest(EVENT_INFO[rescheduleDates[0].type].duration, TIMESLOT_DURATION)) {
            let slotStartDate = toDate(startDate);
            slotStartDate.setUTCMinutes(time);

            let starts = getDates(slotStartDate, rescheduleDates[0].type, rescheduleDates.length, allEvents.filter(event => event.type === EventType.HOLIDAY));
            let ends = starts.map(date => addMinutes(date, EVENT_INFO[rescheduleDates[0].type].duration));

            if (starts.length !== rescheduleDates.length) continue;

            advance(eventView, starts[0]);

            const requestedTeacher = getAccountTableId(rescheduleDates[0].owner.id).toString();
            let teachers = getAvailableTeachers(starts, ends, availabilities, rescheduleDates[0].type);
            if (teachers.size === 0 || !teachers.has(requestedTeacher)) { continue; }

            // Check for event conflicts
            let tempEventView: DateIterator<CalendarEvent> = { index: eventView.index, data: eventView.data };
            let conflicts = false;
            for (let i = 0; i < starts.length; i++) {
                advance(tempEventView, starts[i]);
                let overlap = getUntil(tempEventView, ends[i]);

                for (const event of overlap) {
                    if (event.owner.id === rescheduleDates[0].owner.id) {
                        conflicts = true;
                        break;
                    }
                }

                if (conflicts) {
                    break;
                }
            }

            if (conflicts) { continue; }

            if ((new Date()).getTimezoneOffset() === 300) {
                starts[0].setHours(starts[0].getHours() + 1);
                ends[0].setHours(ends[0].getHours() + 1);
            }
            slots.push({ start: starts[0], end: ends[0], teachers: new Set([requestedTeacher]) });
        }

        startDate = addDays(startDate, 1);
    }

    return { index: 0, data: slots };
};