import { Button, Snackbar } from "@mui/material";
import { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useMessages } from "../messages";

interface Message {
    message: string;
    action?: string;
    onClick?: () => void;
}

const SnackbarContext = createContext({ show: (message: string, action?: string, onClick?: () => void) => { } });

const Component: FunctionComponent = ({ children }) => {
    //#region Hooks
    const { SNACKBAR: { DISMISS } } = useMessages();
    const value = useMemo(() => {
        return {
            show: (message: string, action?: string, onClick?: () => void) => {
                let newMessage = { message: message, action: action, onClick: onClick };
                setMessages((messages) => messages.concat(newMessage));
                setMessage(newMessage);
            }
        };
    }, []);
    //#endregion

    //#region State
    const [messages, setMessages] = useState<Message[]>([]);
    const [message, setMessage] = useState<Message>({ message: "" });
    //#endregion

    //#region Use Effects
    useEffect(() => {
        let timeout = 0;

        if (messages.length > 0) {
            timeout = setTimeout(() => { setMessages(messages.slice(0, messages.length - 1)); }, 6000) as any;
        }

        return () => { clearTimeout(timeout); };
    }, [messages]);
    //#endregion

    //#region Click Handlers
    const onDismissClick = () => {
        if (messages.length > 0) {
            setMessages(messages.slice(0, messages.length - 1));
        }
    };

    const onActionClick = () => {
        onDismissClick();
        if (message.onClick)
            message.onClick();
    };
    //#endregion

    //#region HTML
    return (
        <SnackbarContext.Provider value={value}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={messages.length > 0}
                message={message.message}
                action={<Button size="small" onClick={message.action ? onActionClick : onDismissClick}>{message.action ? message.action : DISMISS}</Button>}
            />
        </SnackbarContext.Provider>
    );
    //#endregion
};

export default Component;

//#region Custom Hooks
export const useSnackbar = () => {
    return useContext(SnackbarContext);
};
//#endregion

