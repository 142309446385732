import { isBefore, isEqual } from "date-fns";
import { DateIterable, DateIterator } from "../../shared/types";


export const advance = <T extends DateIterable>(iterator: DateIterator<T>, startDate: Date) => {
    while (iterator.index < iterator.data.length && (isBefore(iterator.data[iterator.index].end, startDate) || isEqual(iterator.data[iterator.index].end, startDate))) {
        iterator.index++;
    }
};

export const getUntil = <T extends DateIterable>(iterator: DateIterator<T>, endDate: Date) => {
    let data: T[] = [];
    let ind = iterator.index;

    while (ind < iterator.data.length && isBefore(iterator.data[ind].start, endDate)) {
        data.push(iterator.data[ind]);
        ind++;
    }

    return data;
};