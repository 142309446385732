import React, { MouseEvent, MouseEventHandler } from 'react';
import { FacebookRounded, ModeEdit, Instagram, Twitter, LinkedIn, Pinterest, YouTube, Google} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Badge, Autocomplete, Stack, Avatar, Box, Container, Grid, IconButton, Paper, TextField, Toolbar, Typography, useTheme} from "@mui/material";
import { ChangeEventHandler, FormEventHandler, FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Role, STATUS_CODES } from "../../../../../shared/types";
import { hasRole } from "../../../../../shared/utils";
import { useAuth } from "../../../components/auth";
import { useMessages } from "../../../components/messages";
import { useSnackbar } from "../../../components/snackbar";
import WebinarAlert from "../../../components/webinarAlert";
import { countries, CountryType } from "../../../countries";
import {ReactComponent as SnapChat} from "../../../assets/images/snapchat.svg"; 
import Arabic from "../../../../../shared/languages/ar.json";

const Component: FunctionComponent = () => {
    const theme = useTheme();
    const history = useHistory();
    const { ACCOUNT: { SCHOOL: messages }, ERRORS: errors } = useMessages();
    const { ACCOUNT: { SCHOOL: arabicMessages }, ERRORS: arabicErrors } = Arabic;
    const { user, token } = useAuth();
    const { id: idString } = useParams<{ id?: string; }>();
    const snackbar = useSnackbar();
    const userId = !idString ? (user ? user.id : 0) : (parseInt(idString) ? parseInt(idString) : 0);
    const logoFileInput = React.useRef<HTMLInputElement>(null); 

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    //#endregion 

    //#region Form Fields
    const [schoolName, setSchoolName] = useState("");
    const [slogan, setSlogan] = useState("");
    const [email, setEmail] = useState("");
    const [url, setURL] = useState("");
    const [address, setAddress] = useState("");
    //const [pic, setPic] = useState("avatar0010.png");
    const [country, setCountry] = useState<CountryType | null>(null);
    const [land, setLand] = useState("");
    const [spr, setSPR] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [facebook, setFaceBook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState(""); 
    const [linked, setLinked] = useState(""); 
    const [pin, setPin] = useState(""); 
    const [snap, setSnap] = useState(""); 
    const [review, setReview] = useState(""); 
    //#end region

    //#region file states
    const [logo, setLogo] = useState("");
    const [logoHash, setLogoHash] = useState(Date.now); 
    const [isLogoLoading, setIsLogoLoading] = useState(false); 
    //#end region

    //#region social media states
    const [isFacebook, setIsFacebook] = useState(true); 
    const [isTwitter, setIsTwitter] = useState(false); 
    const [isGram, setIsGram] = useState(false); 
    const [isTube, setIsTube] = useState(false);
    const [isLinked, setIsLinked] = useState(false); 
    const [isPin, setIsPin] = useState(false); 
    const [isSnap, setIsSnap] = useState(false); 
    const [isReview, setIsReview] = useState(false); 
    //#end region

    //#region Form Field Error handlers
    const [schoolNameErrors, setSchoolNameErrors] = useState("");
    const [emailErrors, setEmailErrors] = useState("");
    const [phoneErrors, setPhoneErrors] = useState("");
    const [urlErrors, setUrlErrors] = useState("");
    const [sprErrors, setSprErrors] = useState("");
    const [cityErrors, setCityErrors] = useState("");
    const [countryErrors, setCountryErrors] = useState("");
    const [addressErrors, setAddressErrors] = useState("");
    const [logoErrors, setLogoErrors] = useState(""); 
    //#end region

    useEffect(() => {
        if (!user) { return; }

        if (!hasRole(user, Role.SCHOOL)) {
            history.push("/calender");
        }

        (async () => {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ userId: userId })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    
                    let data = await result.json(); 
                    setEmail(data.email ?? "");
                    setSchoolName(data.name ?? "");
                    setLogo(data.logo === null ? "avatar0001.png": data.logo);
                    setSlogan(data.slogan ?? "");
                    setURL(data.url ?? "");
                    setPhone(data.phone ?? "");
                    setAddress(data.st_address ?? "");
                    getCountry(data.country); 
                    setCity(data.city ?? "");
                    setSPR(data.spr ?? "");
                    setFaceBook(data.facebook ?? ""); 
                    setTwitter(data.twitter ?? ""); 
                    setInstagram(data.instagram ?? ""); 
                    setPin(data.pin ?? ""); 
                    setLinked(data.linkedin ?? ""); 
                    setYoutube(data.youtube ?? ""); 
                    setSnap(data.snapchat ?? ""); 
                    setReview(data.google ?? ""); 
                    setLogo(data.logo ?? ""); 
                    break;

                case STATUS_CODES.NOT_FOUND:
                    snackbar.show(errors.MISSING_USER);
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    return;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
        })().then(() => { });
    }, [history, userId, token, messages, errors, user, snackbar]);



    // @field event handlers
    const onSchoolNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSchoolName(event.target.value);
    };

    const changeSchoolName = (value: string) => {

        if (value.trim().length === 0) {
            setSchoolNameErrors(errors.REQUIRED + " " + arabicErrors.REQUIRED);
        } else {
            setSchoolNameErrors("");
        }
        setSchoolName(value);
    };

    const onSloganChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSlogan(event.target.value);
    };

    const changeSlogan = (value: string) => {
        setSlogan(value);
    };

    const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeEmail(event.target.value);
    };

    const changeEmail = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setEmailErrors(errors.REQUIRED + " " + arabicErrors.REQUIRED);
        } else if (value.trim().length > 50) {
            setEmailErrors(errors.MAX_LENGTH_50 + " " + arabicErrors.MAX_LENGTH_50);
        } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailErrors(errors.INVALID_EMAIL + " " + arabicErrors.INVALID_EMAIL);
        } else {
            setEmailErrors("");
        }
        setEmail(value);
    };

    const onPhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changePhone(event.target.value);
    };

    const changePhone = (value: string) => {
        if (value !== "") {
            if (value.trim().length > 11) {
                setPhoneErrors(errors.MAX_LENGTH_13 + " " + arabicErrors.MAX_LENGTH_13);
            } else if (!(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value))) {
                setPhoneErrors(errors.INVALID_PHONE + " " + arabicErrors.INVALID_PHONE);
            } else {
                setPhoneErrors("");
            }
        }
        setPhone(value);
    };

    const onUrlChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeURL(event.target.value);
    };

    const changeURL = (value: string) => {
        if (value !== "") {
            if (!(/^(ftp|http|https):\/\/[^ "]+$/.test(value))) {
                setUrlErrors(errors.INVALID_URL + " " + arabicErrors.INVALID_URL);
            }
            else {
                setUrlErrors("");
            }
        }
        else {
            setUrlErrors("");
        }
        setURL(value);
    };

    const onCountryChange = (event: SyntheticEvent, value: CountryType | null) => {
        changeCountry(value);
    };

    const changeCountry = (value: CountryType | null) => {
        // Validators
        let index = value ? value.label : ""; 
        setCountryErrors("");
        setCountry(value);
        setLand(index); 

    };

    const onSprChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSpr(event.target.value);
    };

    const changeSpr = (value: string) => {

        setSprErrors("");
        setSPR(value);
    };

    const onCityChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeCity(event.target.value);
    };

    const changeCity = (value: string) => {

        setCityErrors("");
        setCity(value);
    };


    const onAddressChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeAddress(event.target.value);
    };

    const changeAddress = (value: string) => {

        setAddressErrors("");
        setAddress(value);
    };

    const onFacebookChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeFacebook(event.target.value);
    };

    const changeFacebook = (value: string) => {
        setFaceBook(value);
    };
    const onTwitterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeTwitter(event.target.value);
    };

    const changeTwitter = (value: string) => {
        setTwitter(value);
    };
    const onInstagramChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeInstagram(event.target.value);
    };

    const changeInstagram = (value: string) => {
        setInstagram(value);
    };
    
    const onYoutubeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeYoutube(event.target.value);
    };

    const changeYoutube = (value: string) => {
        setYoutube(value);
    };

    const onLinkedChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeLinked(event.target.value);
    };

    const changeLinked = (value: string) => {
        setLinked(value);
    };

    const onPinChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changePin(event.target.value);
    };

    const changePin = (value: string) => {
        setPin(value);
    };

    const onReviewChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeReview(event.target.value);
    };

    const changeReview = (value: string) => {
        setReview(value);
    };

    const onSnapChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSnap(event.target.value);
    };

    const changeSnap = (value: string) => {
        setSnap(value);
    };
    // @end region event handlers

    //@region button click handlers
    const onNoClick = () => {
        setIsFacebook(false); 
        setIsLinked(false); 
        setIsPin(false); 
        setIsReview(false);
        setIsSnap(false); 
        setIsTube(false); 
        setIsTwitter(false); 
        setIsGram(false);
    }

    const onFacebookClick: MouseEventHandler = () => {

        onNoClick(); 
        setIsFacebook(true); 
    }

    const onTwitterClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsTwitter(true); 
    }

    const onGramClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsGram(true); 
    }

    const onLinkedClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsLinked(true); 
    }

    const onPinClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsPin(true); 
    }

    const onGoogleClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsReview(true); 
    }

    const onSnapClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsSnap(true); 
    }
    const onTubeClick: MouseEventHandler = () => {
        onNoClick(); 
        setIsTube(true); 
    }

    const onLogoClick: MouseEventHandler = (event: MouseEvent) => {
        
        if(logoFileInput.current != null){
            logoFileInput.current.value = ""; 
            logoFileInput.current.click();    
        }
    }

    const handleLogoClick: ChangeEventHandler<HTMLInputElement>  = (event) => {
        const file = event.currentTarget.files
        changeLogo( file ? file[0] : null); 
        

    }

    const changeLogo = ( value: File | null) => {
        
        if (value === null) {
            setLogoErrors(errors.FILE_NOT_EXIST + " " + arabicErrors.FILE_NOT_EXIST); 
        } else if (!(/.(jpg|jpeg|png|gif)$/i).test(value.name)) {
            setLogoErrors(errors.FILE_NOT_IMAGE + " " + arabicErrors.FILE_NOT_IMAGE);
        } else if (value.size > 3000) {
            setLogoErrors(errors.FILE_SIZE_BIG + " " + arabicErrors.FILE_SIZE_BIG); 
        }else {
            setLogoErrors(""); 
        }
        
        uploadLogo(value); 

    }

    const getCountry = (value: String) => {

        countries.forEach((x) => {
            if(x.label === value){
                setCountry(x);
            }
        })
    }
    //@end region

    const uploadLogo = async (value: File | null) => {
        if (!value) {return;}
        if (logoErrors !== ""){return;}
        setLogoHash(Date.now());
        const formData = new FormData();
        formData.append('logo', value, `${userId.toString()}.png`); 

        try {
            setIsLogoLoading(true); 

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/logo/upload`, {
                method: "POST", 
                credentials: "omit", 
                headers: {
                    "Authorization": `Bearer ${token}`
                }, 
                body: formData
            }); 
            switch(result.status) {
                case STATUS_CODES.OK: 
                    setIsLogoLoading(false);
                    setLogoHash(Date.now());
                    return;
                
                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED); 
                    break; 
                
                case STATUS_CODES.NOT_FOUND: 
                    snackbar.show(logoErrors);
                    break;
                
                default: 
                    snackbar.show(errors.TRY_LATER); 
                    break; 
            }
        setIsLogoLoading(false);  
        }catch(error) {
            setIsLogoLoading(false)
            snackbar.show(errors.TRY_LATER); 
        }
    }

    // submit form
    const isFormSubmitable = () => {
        if (isLoading || schoolName === "" || email === "") {
            return false;
        }

        if (schoolNameErrors !== "" || emailErrors !== "" ||
            phoneErrors !== "" || urlErrors !== "" || countryErrors !== "" ||
            addressErrors !== "" || sprErrors !== ""
        ) {
            return false;
        }
        return true;
    };

    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        changeSchoolName(schoolName);
        changeSlogan(slogan);
        changeEmail(email);
        changePhone(phone);
        changeCountry(country);
        changeAddress(address);
        changeURL(url);
        changeSpr(spr);
        changeCity(city);

        if (!isFormSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/school`, {
                method: 'PUT',
                credentials: "omit",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: schoolName, 
                    slogan: slogan,
                    email: email,
                    url: url,
                    phone: phone,
                    address: address,
                    city: city,
                    country: land,
                    spr: spr,
                    userId: userId, 
                    facebook: facebook,
                    twitter: twitter,
                    instagram: instagram,
                    pin: pin, 
                    snapchat: snap, 
                    linkedin: linked, 
                    google: review,
                    youtube: youtube, 
                    logo: `logo_${userId.toString()}.png`
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    setIsLoading(false);
                    snackbar.show(messages.SUCCESS);
                    return;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    break;

                case STATUS_CODES.NOT_FOUND:
                    snackbar.show(errors.MISSING_USER);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };

    return (<>
        <Toolbar />
        <Container sx={{ p: { xs: 0, sm: 2 }, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
            <WebinarAlert desktop={false} />
            <Box component="form" onSubmit={onFormSubmit} noValidate>
                <Paper elevation={theme.palette.mode === "dark" ? 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} sx={{ p: 2, borderRadius: { xs: "0px", sm: 1, boxShadow: "none" } }}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} justifyContent="center">
                            <IconButton onClick={onLogoClick}>
                                <Badge sx={{}} badgeContent={isLogoLoading ? "": <ModeEdit fontSize="small" />} anchorOrigin={{vertical: 'bottom', horizontal:"right"}} color="primary">
                                    <Avatar sx={{ width: (theme) => theme.spacing(12), height: (theme) => theme.spacing(12) }} 
                                        src={`${process.env.REACT_APP_LEGACY_URL}/images/avatars/logos/${logo}?${logoHash}`} alt="avatar" />
                                </Badge>
                            </IconButton>
                            <input accept="image/png, image/jpeg, image/jpg" ref={logoFileInput} style={{display: 'none'}}  onChange={handleLogoClick} type="file" id="input"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">
                                {arabicMessages.TITLE}
                            </Typography>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">
                                {messages.TITLE}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={arabicMessages.NAME 
                                    + "  " + messages.NAME}
                                value={schoolName}
                                error={schoolNameErrors !== ""}
                                helperText={schoolNameErrors}
                                autoComplete="schoolname"
                                onChange={onSchoolNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                label={arabicMessages.SLOGAN
                                     + "  " + messages.SLOGAN}
                                value={slogan}
                                autoComplete="slogan"
                                onChange={onSloganChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={arabicMessages.EMAIL
                                    + "  " +messages.EMAIL}
                                value={email}
                                error={emailErrors !== ""}
                                helperText={emailErrors}
                                autoComplete="email"
                                onChange={onEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={arabicMessages.PHONE
                                    + "  " +messages.PHONE}
                                value={phone}
                                name="phone"
                                error={phoneErrors !== ""}
                                helperText={phoneErrors}
                                autoComplete="phone"
                                onChange={onPhoneChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={arabicMessages.URL
                                    + "  " +messages.URL}
                                value={url}
                                error={urlErrors !== ""}
                                helperText={urlErrors}
                                autoComplete="url"
                                onChange={onUrlChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                options={countries}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <Box
                                            draggable="false"
                                            component="img"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                            sx={{ userSelect: "none", mr: 2, flexShrink: 0 }}
                                        />
                                        {`${option.label} (${option.code})`}
                                    </Box>
                                )} 
                                value={country ? country : null}
                                onChange={onCountryChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={arabicMessages.COUNTRY
                                            + "  " +messages.COUNTRY}
                                        error={countryErrors !== ""}
                                        helperText={countryErrors}
                                        autoComplete="country-name"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={arabicMessages.STREET_ADDRESS
                                    + "  " +messages.STREET_ADDRESS}
                                value={address}
                                error={addressErrors !== ""}
                                helperText={addressErrors}
                                autoComplete="address"
                                onChange={onAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={arabicMessages.SPR
                                    + "  " +messages.SPR}
                                value={spr}
                                error={sprErrors !== ""}
                                helperText={sprErrors}
                                onChange={onSprChange}
                                autoComplete="SPR"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={arabicMessages.CITY
                                    + "  " +messages.CITY}
                                value={city}
                                error={cityErrors !== ""}
                                helperText={cityErrors}
                                autoComplete="city"
                                onChange={onCityChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Stack direction="row" alignItems="center" spacing={1}>

                                <IconButton disabled={isFacebook} onClick={onFacebookClick}>
                                    <FacebookRounded/>
                                </IconButton>

                                <IconButton disabled={isTwitter} onClick={onTwitterClick}>
                                    <Twitter/>
                                </IconButton>

                                <IconButton disabled={isGram} onClick={onGramClick}>
                                    <Instagram/>
                                </IconButton>

                                <IconButton disabled={isPin} onClick={onPinClick}>
                                    <Pinterest/>
                                </IconButton>

                                <IconButton disabled={isLinked} onClick={onLinkedClick}>
                                    <LinkedIn/>
                                </IconButton>
                                
                                <IconButton disabled={isReview} onClick={onGoogleClick}>
                                    <Google/>
                                </IconButton>

                                <IconButton disabled={isTube} onClick={onTubeClick}>
                                    <YouTube/>
                                </IconButton>
                                
                                <IconButton disabled={isSnap} onClick={onSnapClick}>
                                    <SnapChat/>
                                </IconButton>

                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            {isFacebook && (
                                   <TextField
                                   fullWidth
                                   label={arabicMessages.FACEBOOK
                                    + "  " +messages.FACEBOOK}
                                   value={facebook}
                                   onChange={onFacebookChange}
                               /> 
                            )}
                            {isTwitter && (
                                <TextField 
                                    fullWidth
                                    label={arabicMessages.TWITTER
                                        + "  " +messages.TWITTER}
                                    value={twitter}
                                    onChange={onTwitterChange}
                                />
                            )}
                            {isGram && (
                                <TextField
                                    fullWidth
                                    label={arabicMessages.INSTAGRAM
                                        + "  " +messages.INSTAGRAM}
                                    value={instagram}
                                    onChange={onInstagramChange}
                                />
                            )}
                            {isLinked && (
                                <TextField
                                    fullWidth
                                    label={arabicMessages.LINKEDIN
                                        + "  " +messages.LINKEDIN}
                                    value={linked}
                                    onChange={onLinkedChange}
                                />
                            )}
                            {isPin && (
                                <TextField
                                    fullWidth
                                    label={arabicMessages.PINTEREST
                                        + "  " +messages.PINTEREST}
                                    value={pin}
                                    onChange={onPinChange}
                                />
                            )}
                            {isReview && (
                                <TextField
                                    fullWidth
                                    label={arabicMessages.GOOGLE
                                        + "  " +messages.GOOGLE}
                                    value={review}
                                    onChange={onReviewChange}
                                />
                            )}
                            {isTube && (
                                <TextField
                                fullWidth
                                label={arabicMessages.YOUTUBE
                                    + "  " +messages.YOUTUBE}
                                value={youtube}
                                onChange={onYoutubeChange}
                            />
                            )}
                            {isSnap && (
                                <TextField
                                fullWidth
                                label={arabicMessages.SNAPCHAT
                                    + "  " +messages.SNAPCHAT}
                                value={snap}
                                onChange={onSnapChange}
                            />
                            )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                {arabicMessages.SAVE
                                     + "  " +messages.SAVE}
                             </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    </>
    );
};
 

export default Component;