import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Grid, Paper, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEventHandler, FormEventHandler, FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { STATUS_CODES } from "../../../../shared/types";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory<{ redirect?: string; }>();
    const { EMAIL_COLLECT: messages, ERRORS: errors } = useMessages();
    const snackbar = useSnackbar();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    //#endregion

    //#region Form Fields
    const [email, setEmail] = useState("");
    //#endregion

    //#region Form Fields Errors
    const [emailErrors, setEmailErrors] = useState("");
    //#endregion

    //#region Change event handlers
    const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeEmail(event.target.value);
    };

    const changeEmail = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setEmailErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setEmailErrors(errors.MAX_LENGTH_50);
        } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailErrors(errors.INVALID_EMAIL);
        } else {
            setEmailErrors("");
        }

        setEmail(value);
    };
    //#endregion

    //#region Validity Checks
    const isFormSubmitable = () => {
        if (isLoading || email.trim() === "") {
            return false;
        }

        if (emailErrors !== "") {
            return false;
        }

        return true;
    };
    //#endregion

    //#region Submit Event Handlers
    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        if (isSent) { return history.push("/", history.location.state); }

        // Populate error messages
        changeEmail(email);

        if (!isFormSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/register/v4`, {
                method: "POST",

                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    email: email.trim()
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    setIsSent(true);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };
    //#endregion

    //#region HTML
    return (<Container sx={{ px: { xs: 0, sm: 2 }, pb: { xs: 0, sm: 2 }, pt: 2, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
        <WebinarAlert />
        <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Logo height={theme.spacing(5)} />
        </Box>
        <Paper elevation={0}>
            <Typography sx={{ p: { xs: 2, sm: 0 } }} variant="h5" fontWeight="bold" textAlign="center">
                {messages.TITLE}
            </Typography>
        </Paper>
        <Box sx={{ px: { xs: 0, sm: 8 } }} component="form" onSubmit={onFormSubmit} noValidate>
            <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, boxShadow: "none", p: { xs: 2, sm: 4 } }} elevation={theme.palette.mode !== "dark" ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            {isSent ? messages.SENT : messages.DESCRIPTION}
                        </Typography>
                    </Grid>
                    {isSent ? null :
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={messages.EMAIL}
                                value={email}
                                error={emailErrors !== ""}
                                helperText={emailErrors}
                                type="email"
                                autoComplete="email"
                                onChange={onEmailChange}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                            {isSent ? messages.TUTORING : messages.SEND}
                        </LoadingButton >
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    </Container >
    );
    //#endregion
};

export default Component;
