import DarkModeIcon from '@mui/icons-material/DarkMode';
import EventIcon from '@mui/icons-material/Event';
import LightModeIcon from '@mui/icons-material/LightMode';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Logout from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ShareIcon from '@mui/icons-material/Share';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import FaceIcon from '@mui/icons-material/Face';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonAdd from '@mui/icons-material/PersonAdd';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { AppBar, Avatar, Box, Button, Divider, Drawer, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FunctionComponent, MouseEventHandler, ReactNode, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Appearance, Role } from "../../../../shared/types";
import { deleteCookie, hasRole, substitute } from "../../../../shared/utils";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import WebinarAlert from "../../components/webinarAlert";
import { useSavedState } from "../../hooks/savedState";
import { useAuth } from "../auth";
import { useMessages } from "../messages";
import Arabic from "../../../../shared/languages/ar.json"; 

const drawerExpandedWidth = 240;

interface IProps {
    setAppearance: (appearance: Appearance) => void;
    appearance: Appearance;
    render: (drawerWidth: string, isOpen: boolean) => ReactNode;
    hideDrawer?: boolean
}

const Component: FunctionComponent<IProps> = ({ render, setAppearance, appearance, hideDrawer = false }) => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory();
    const { NAVBAR: messages } = useMessages();
    const { user, setToken, isLoading: authLoading } = useAuth();
    const {NAVBAR: arabicMessages} = Arabic;
    //#endregion

    //#region State
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useSavedState<boolean>(false, "drawerOpen");
    //#endregion

    //#region Other
    const handleMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (event.currentTarget)
            setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    //#endregion

    const drawerWidth = (isMobile || (!user && !authLoading)) ? "0px" : (isDrawerOpen ? `${drawerExpandedWidth}px` : theme.spacing(7));

    return <>
        <AppBar position="static" elevation={0} color="transparent" sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: 1, borderColor: (theme) => theme.palette.divider,
            position: "fixed",
            top: 0,
            left: 'auto',
            right: 0,
            '@media print': {
                // Prevent the app bar to be visible on each printed page.
                position: 'absolute',
            }
        }}>
            <Paper elevation={3} sx={{ borderRadius: "0", boxShadow: "none" }} className="mui-fixed">
                <Toolbar>
                    {!user || hideDrawer ? null :
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => { setIsDrawerOpen(open => !open); }}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    <Box sx={{ flexGrow: 1 }}>
                        <Button disableElevation onClick={() => { history.push("/calendar"); }}>
                            <Logo height={theme.spacing(3)} />
                        </Button>
                    </Box>
                    <WebinarAlert mobile={false} sx={{ mr: 1 }} />
                    {(user || authLoading) ? null : <Button variant="contained" sx={{ textTransform: "none" }} disableElevation onClick={() => { history.push("/login", { redirect: history.location }); }}>{messages.LOGIN}</Button>}
                    {!user ? null :
                        <Box>
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Avatar sx={{ height: theme => theme.spacing(4), width: theme => theme.spacing(4) }} alt={user.name} src={`https://edutechnoz.com/images/avatars/${user.pic}`} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                PaperProps={{ sx: { minWidth: "250px" } }}
                            >

                                <Box sx={{ px: 2, pb: 1, gap: 2, display: "flex", flexDirection: "row" }}>
                                    <Avatar sx={{ height: theme => theme.spacing(5), width: theme => theme.spacing(5) }} alt={user.name} src={`https://edutechnoz.com/images/avatars/${user.pic}`} />
                                    <Box>
                                        <Typography variant="h6">{user.name}</Typography>
                                        <Link variant="body1" component={RouterLink} to="/account" onClick={() => { setAnchorEl(null); }}>{messages.ACCOUNT}</Link>
                                    </Box>
                                </Box>
                                <Divider sx={{ mb: 1 }} />

                                {!hasRole(user, Role.PARENT) ? null :
                                    <MenuItem onClick={() => { setAnchorEl(null); history.push("/enroll"); }}>
                                        <ListItemIcon>
                                            <PersonAdd fontSize="small" />
                                        </ListItemIcon>
                                        {messages.ENROLL}
                                    </MenuItem>}

                                {!hasRole(user, Role.PARENT) ? null : <Divider />}

                                <MenuItem onClick={() => {
                                    if (appearance === Appearance.LIGHT) setAppearance(Appearance.DARK);
                                    if (appearance === Appearance.DARK) setAppearance(Appearance.SYSTEM);
                                    if (appearance === Appearance.SYSTEM) setAppearance(Appearance.LIGHT);
                                }}>
                                    <ListItemIcon>
                                        {appearance !== Appearance.LIGHT ? null : <LightModeIcon fontSize="small" />}
                                        {appearance !== Appearance.DARK ? null : <DarkModeIcon fontSize="small" />}
                                        {appearance !== Appearance.SYSTEM ? null : <SettingsBrightnessIcon fontSize="small" />}
                                    </ListItemIcon>
                                    {substitute(messages.APPEARANCE, { APPEARANCE: messages.APPEARANCES[appearance] })}
                                </MenuItem>
                                {/* <MenuItem>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem> */}
                                <MenuItem onClick={() => { setAnchorEl(null); deleteCookie("PHPSESSID"); deleteCookie("Type"); setToken(""); history.replace("/login"); }}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    {messages.LOGOUT}
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                </Toolbar>
            </Paper>
        </AppBar>
        { !hideDrawer && 
        <Drawer variant={isMobile ? "temporary" : "permanent"} anchor="left" open={isDrawerOpen} onClose={() => { setIsDrawerOpen(false); }}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
                elevation: theme.palette.mode === "dark" ? 3 : 0,
                sx: {
                    overflowX: "hidden",
                    boxShadow: "none",
                    width: isMobile ? drawerExpandedWidth : drawerWidth,
                    transition: (theme) =>
                        isDrawerOpen ? theme.transitions.create(['width'], {
                            duration: theme.transitions.duration.enteringScreen,
                            easing: theme.transitions.easing.easeOut
                        }) : theme.transitions.create(['width'], {
                            duration: theme.transitions.duration.leavingScreen,
                            easing: theme.transitions.easing.sharp
                        })
                }
            }}
        >
            <Toolbar />
            <List>
                {(!user || hasRole(user, Role.SCHOOL) || (hasRole(user, Role.TEACHER) && user.schoolId !== 336)) ? null :
                    <ListItem button sx={{minHeight: 48}} onClick={() => { if (isMobile) { setIsDrawerOpen(false); } history.push("/calendar"); }} >
                        <ListItemIcon><EventIcon /></ListItemIcon>
                        <ListItemText primary={messages.CALENDAR} />
                    </ListItem>
                }
                {(!user || !hasRole(user, [Role.PARENT, Role.STUDENT])) ? null :
                    <ListItem button sx={{minHeight: 48}} onClick={() => {
                        if (isMobile) { setIsDrawerOpen(false); }
                        window.location.assign(hasRole(user, Role.PARENT) ?
                            "https://edutechnoz.com/Parents/pages/content_games.php" :
                            "https://edutechnoz.com/Kids/index.php"
                        );
                    }} >
                        <ListItemIcon><SportsEsportsIcon /></ListItemIcon>
                        <ListItemText primary={messages.GAMES} />
                    </ListItem>
                }
                {(!user || !hasRole(user, [Role.PARENT, Role.STUDENT])) ? null :
                    <ListItem button sx={{minHeight: 48}} onClick={() => {
                        if (isMobile) { setIsDrawerOpen(false); }
                        window.location.assign(hasRole(user, Role.PARENT) ?
                            "https://edutechnoz.com/Parents/pages/content_ebooks.php" :
                            "https://edutechnoz.com/Kids/Books.php"
                        );
                    }} >
                        <ListItemIcon><MenuBookIcon /></ListItemIcon>
                        <ListItemText primary={messages.EBOOKS} />
                    </ListItem>
                }
                {(!user || !hasRole(user, [Role.PARENT, Role.STUDENT])) ? null :
                    <ListItem button sx={{minHeight: 48}} onClick={() => {
                        if (isMobile) { setIsDrawerOpen(false); }
                        window.location.assign(hasRole(user, Role.PARENT) ?
                            "https://edutechnoz.com/Parents/pages/content_worksheets.php" :
                            "https://edutechnoz.com/Kids/Printing.php"
                        );
                    }} >
                        <ListItemIcon><ListAltIcon /></ListItemIcon>
                        <ListItemText primary={messages.WORKSHEETS} />
                    </ListItem>
                }
                {!!user && hasRole(user, Role.TEACHER) &&
                    <ListItem button sx={{minHeight: 48}} onClick={() => {
                        if (isMobile) { setIsDrawerOpen(false); }
                        history.push("/i/profileinfo")
                    }} >
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        {isDrawerOpen && <ListItemText primary={arabicMessages.PROFILE_INFO} secondary={messages.PROFILE_INFO}/>}
                    </ListItem>
                }
                {!!user && hasRole(user, Role.SCHOOL) &&
                    <ListItem button sx={{minHeight: 48}} onClick={() => { if (isMobile) { setIsDrawerOpen(false); } history.push("/account"); }} >
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        {isDrawerOpen && <ListItemText primary={arabicMessages.SCHOOL_PROFILE} secondary={messages.SCHOOL_PROFILE}/>}
                    </ListItem>
                }
                { !!user && hasRole(user, [Role.TEACHER, Role.SCHOOL]) &&
                    <>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/profilepic")
                        }} >
                            <ListItemIcon><FaceIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.PROFILE_PIC} secondary={messages.PROFILE_PIC}/>}
                        </ListItem>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/password")
                        }} >
                            <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.PASSWORD} secondary={messages.PASSWORD}/>}
                        </ListItem>
                        { hasRole(user, [Role.SCHOOL]) &&
                            <ListItem button sx={{minHeight: 48}} onClick={() => {
                                if (isMobile) { setIsDrawerOpen(false); }
                                history.push("/i/teachersprofiles")
                            }} >
                                <ListItemIcon><GroupsIcon /></ListItemIcon>
                                {isDrawerOpen && <ListItemText primary={arabicMessages.TEACHER_PROFILE} secondary={messages.TEACHER_PROFILE}/>}
                            </ListItem>
                        }
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/studentsprofiles")
                        }} >
                            <ListItemIcon><GroupsIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.STUDENT_PROFILE} secondary={messages.STUDENT_PROFILE}/>}
                        </ListItem>
                        <Divider />
                    </>
                }
                { !!user && hasRole(user, [Role.TEACHER]) &&
                    <>
                        <ListItem button sx={{minHeight: 48}} component="a" href="https://edutechnoz.com/Kids/Curriculum/index.php" target="_blank">
                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.CURRICULUM} secondary={messages.CURRICULUM}/>}
                        </ListItem>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/recommend")
                        }} >
                            <ListItemIcon><ShareIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.RECOMMEND} secondary={messages.RECOMMEND}/>}
                        </ListItem>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/recommendstatus")
                        }} >
                            <ListItemIcon><HourglassTopIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.RECOMMEND_STATUS} secondary={messages.RECOMMEND_STATUS}/>}
                        </ListItem>
                        <Divider />
                    </>
                }
                {!!user && hasRole(user, [Role.SCHOOL, Role.TEACHER]) &&
                    <>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/teachersreport")
                        }} >
                            <ListItemIcon><AssessmentIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.TEACHERS_REPORT} secondary={messages.TEACHERS_REPORT}/>}
                        </ListItem>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/studentsreport")
                        }} >
                            <ListItemIcon><AssignmentIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.STUDENTS_REPORT} secondary={messages.STUDENTS_REPORT}/>}
                        </ListItem>
                    </>
                }
                {!!user && hasRole(user, Role.SCHOOL) &&
                    <>
                        <ListItem button sx={{minHeight: 48}} onClick={() => {
                            if (isMobile) { setIsDrawerOpen(false); }
                            history.push("/i/classesreport")
                        }} >
                            <ListItemIcon><SchoolIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.CLASS_REPORT} secondary={messages.CLASS_REPORT}/>}
                        </ListItem>  
                        <Divider />
                        <ListItem button sx={{minHeight: 48}} component="a" href="https://edutechnoz.com/Kids/Curriculum/index.php" target="_blank">
                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            {isDrawerOpen && <ListItemText primary={arabicMessages.CURRICULUM} secondary={messages.CURRICULUM}/>}
                        </ListItem> 
                    </> 
                }
                {!!user && hasRole(user, Role.TEACHER) && <Divider />}
                {
                    !!user && hasRole(user, [Role.SCHOOL, Role.TEACHER]) &&
                    <ListItem button sx={{minHeight: 48}} component="a" href="https://edutechnoz.com/Help/index.php" target="_blank">
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        {isDrawerOpen && <ListItemText primary={arabicMessages.HELP} secondary={messages.HELP}/>}
                    </ListItem>
                }
                {(!user) ? null :
                    <>
                        <ListItem button sx={{minHeight: 48}} onClick={() => { if (isMobile) { setIsDrawerOpen(false); } history.push("/accounts"); }} >
                            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
                            <ListItemText primary={messages.ACCOUNTS} />
                        </ListItem>
                    </>
                }
            </List>
        </Drawer>}
        <Box sx={{
            height: "100%",
            marginLeft: drawerWidth, transition: (theme) =>
                isDrawerOpen ? theme.transitions.create(['margin-left'], {
                    duration: theme.transitions.duration.enteringScreen,
                    easing: theme.transitions.easing.easeOut
                }) : theme.transitions.create(['margin-left'], {
                    duration: theme.transitions.duration.leavingScreen,
                    easing: theme.transitions.easing.sharp
                })
        }}>
            {render(drawerWidth, isDrawerOpen)}
        </Box>
    </>;
};

export default Component;