import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Grid, Link, Paper, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEventHandler, FormEventHandler, FunctionComponent, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { STATUS_CODES } from "../../../../shared/types";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useAuth } from "../../components/auth";
import { useEnrollmentVersion } from "../../components/enrollmentVersion";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";
import { hasRole } from "../../../../shared/utils";
import { Role } from "../../../../shared/types";

interface Props {
    admin: boolean;
}

const Component: FunctionComponent<Props> = ({ admin }) => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory<{ redirect?: string; email?: string; }>();
    const { LOGIN: messages, ERRORS: errors } = useMessages();
    const { user, setToken, isLoading: authLoading } = useAuth();
    const snackbar = useSnackbar();
    const { version } = useEnrollmentVersion();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    //#endregion

    //#region Form Fields
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    //#endregion

    //#region Form Fields Errors
    const [emailErrors, setEmailErrors] = useState("");
    const [passwordErrors, setPasswordErrors] = useState("");
    //#endregion

    //#region Use Effects
    useEffect(() => {
        if (user !== null && !authLoading) {
            history.replace((history.location.state && history.location.state.redirect) ? history.location.state.redirect : (hasRole(user, [Role.TEACHER, Role.SCHOOL])) ? "/i/main" : "/calendar");
        }
    }, [user, history, authLoading]);

    useEffect(() => {
        if (history.location.state && history.location.state.email) {
            setEmail(history.location.state.email);
        }
    }, [history.location.state]);
    //#endregion

    //#region Change event handlers
    const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeEmail(event.target.value);
    };

    const changeEmail = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setEmailErrors(errors.REQUIRED);
        } else {
            setEmailErrors("");
        }

        setEmail(value);
    };

    const onPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changePassword(event.target.value);
    };

    const changePassword = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setPasswordErrors(errors.REQUIRED);
        } else {
            setPasswordErrors("");
        }

        setPassword(value);
    };
    //#endregion

    //#region Validity Checks
    const isFormSubmitable = () => {
        if (isLoading || email === "" ||
            password === "") {
            return false;
        }

        if (emailErrors !== "" ||
            passwordErrors !== "") {
            return false;
        }

        return true;
    };
    //#endregion

    //#region Submit Event Handlers
    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        // Populate error messages
        changeEmail(email);
        changePassword(password);

        if (!isFormSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: "POST",

                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    version: version,
                    admin: admin
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    return setToken((await result.json()).token);

                case STATUS_CODES.FORBIDDEN:
                    snackbar.show(errors.UNAUTHORIZED);
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    setPasswordErrors(errors.INCORRECT_PASSWORD);
                    break;

                case STATUS_CODES.NOT_FOUND:
                    setEmailErrors(errors.MISSING_USER);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };
    //#endregion

    //#region HTML
    return (
        <Container sx={{ px: { xs: 0, sm: 2 }, pb: { xs: 0, sm: 2 }, pt: 2, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
            <WebinarAlert />
            <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Logo height={theme.spacing(5)} />
            </Box>
            <Paper elevation={0}>
                <Typography sx={{ p: { xs: 2, sm: 0 } }} variant="h5" fontWeight="bold" textAlign="center">
                    {messages.DESCRIPTION}
                </Typography>
            </Paper>
            <Box sx={{ px: { xs: 0, sm: 8 } }} component="form" onSubmit={onFormSubmit} noValidate>
                <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, boxShadow: "none", p: { xs: 2, sm: 4 } }} elevation={theme.palette.mode !== "dark" ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={messages.EMAIL}
                                value={email}
                                error={emailErrors !== ""}
                                helperText={emailErrors}
                                type="email"
                                autoComplete="email"
                                onChange={onEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={messages.PASSWORD}
                                value={password}
                                error={passwordErrors !== ""}
                                helperText={passwordErrors}
                                type="password"
                                autoComplete="password"
                                onChange={onPasswordChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Link color={theme.palette.event2.main} to={{ pathname: "/reset-password", state: history.location.state }} component={RouterLink}>{messages.FORGOT_PASSWORD}</Link>
                        </Grid>
                        {!admin && 
                        <Grid item xs={12}>
                            <Typography>{messages.REGISTER[0]}<Link color={theme.palette.event2.main} to={{ pathname: "/register", state: history.location.state }} replace component={RouterLink}>{messages.REGISTER[1]}</Link></Typography>
                        </Grid>}
                        <Grid item xs={12}>
                            <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                {messages.LOGIN}
                            </LoadingButton >
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container >
    );
    //#endregion
};

export default Component;
