import { Button, Theme, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { addDays, format, getDate, getMonth, isBefore, isSameDay, setDate as setDateObjDate, setDay, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";
import { FunctionComponent } from "react";
import { CalendarEvent, DateIterator, EventType, Role } from "../../../../../shared/types";
import { getAccountColor, hasRole } from "../../../../../shared/utils";
import { useAuth } from "../../../components/auth";
import { advance, getUntil } from "../../../dateIterator";
import { isAllDay, Slot } from "../../../scheduling";

interface Props {
    onDayClick: (date: Date) => void;
    onEventClick: (event: CalendarEvent) => void;
    events: CalendarEvent[];
    date: Date;
    slots: Slot[];
    mobileView: boolean;
}

const Component: FunctionComponent<Props> = ({ mobileView, events, slots, onDayClick, onEventClick, date }) => {
    const { user } = useAuth();

    const getAttendees = (event: CalendarEvent) => {
        if (event.attendees.length === 0) {
            return "";
        }
        if (event.attendees.length === 1) {
            return event.attendees[0].name + " - ";
        }
        return "";
    };

    const getColor = (theme: Theme, type: EventType, color: string = "main"): string => {
        return (theme.palette as any)[`event${type}`][color];
    };

    const generateDay = (startDate: Date, first: boolean, eventView: DateIterator<CalendarEvent>, teacherView: DateIterator<Slot>) => {
        let endOfDay = addDays(startDate, 1);

        advance(eventView, startDate);
        advance(teacherView, startDate);

        let events: CalendarEvent[] = getUntil(eventView, endOfDay);
        let slots: Slot[] = getUntil(teacherView, endOfDay);

        return <Box key={startDate.toISOString()} sx={{ py: 0.5, display: "flex", flexDirection: "column", minWidth: 0, flex: 1, gap: 0.5, borderRight: theme => `1px solid ${theme.palette.divider}`, ":last-of-type": { borderRight: "none" } }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {first ? <Typography variant="body2" sx={{ fontWeight: "bold" }}>{format(startDate, mobileView ? "EEEEE" : "EEE")}</Typography> : null}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Button disableElevation variant={isSameDay(startDate, new Date()) ? "contained" : "text"} color={isSameDay(startDate, new Date()) ? "event2" : "inherit"} sx={{
                        py: 0.25,
                        px: 1,
                        borderRadius: theme => theme.spacing(3),
                        fontSize: theme => theme.typography.body2.fontSize,
                        textTransform: "none",
                        minWidth: 0
                    }} onClick={() => { onDayClick(startDate); }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold", color: theme => isSameDay(startDate, new Date()) ? undefined : (getMonth(startDate) !== getMonth(date) ? theme.palette.action.disabledBackground : theme.palette.action.active) }}>
                            {(getDate(startDate) !== 1 || mobileView) ? format(startDate, 'd') : format(startDate, 'LLL d')}
                        </Typography>
                    </Button>
                </Box>
            </Box>

            {
                slots.length === 0 ? null :
                    <Button
                        disableElevation
                        onClick={() => { onDayClick(startDate); }}
                        variant={mobileView ? "contained" : "text"}
                        sx={{
                            borderRadius: 0,
                            textTransform: "none", justifyContent: 'flex-start',
                            display: "flex", flexDirection: "row", alignItems: "center",
                            py: 0.5,
                            px: 1,
                            gap: 1,
                            minWidth: 0,
                            transition: "0.25s all"
                        }}
                    >
                        {mobileView ? null : <Box sx={{
                            p: 0.5,
                            background: theme => theme.palette.primary.main,
                            borderRadius: "50%",
                            width: theme => theme.spacing(0.5),
                            height: theme => theme.spacing(0.5),
                            minWidth: theme => theme.spacing(0.5),
                            maxWidth: theme => theme.spacing(0.5),
                            minHeight: theme => theme.spacing(0.5),
                            maxHeight: theme => theme.spacing(0.5),
                            overflow: "hidden",
                        }} />}
                        <Typography variant="body2" sx={{
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100%"
                        }}>
                            {`${slots.length} Time(s) Available`}
                        </Typography>
                    </Button>
            }

            {
                events.map(event => {
                    let allDay = isAllDay(event.start, event.end, startDate);
                    return <Button key={event.id}
                        disableElevation
                        onClick={() => { onEventClick(event); }}
                        variant={(mobileView || allDay) ? "contained" : "text"}
                        color={`event${event.type}` as any}
                        sx={{
                            borderRadius: 0,
                            minWidth: 0,
                            textTransform: "none", justifyContent: 'flex-start',
                            display: "flex", flexDirection: "row", alignItems: "center",
                            py: 0.5,
                            px: 1,
                            gap: 1,
                            transition: "0.25s all",
                            background: (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? getAccountColor(event.owner.id) : undefined,
                            border: (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? (theme) => `2px solid ${getColor(theme, event.type)}` : undefined,
                            color: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? theme.palette.getContrastText(getAccountColor(event.owner.id)) : undefined,
                            "&:hover": { background: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? theme.palette.augmentColor({ color: { main: getAccountColor(event.owner.id) } }).dark : undefined },
                        }}
                    >
                        {(mobileView || allDay) ? null : <Box sx={{
                            p: 0.5,
                            background: theme => getColor(theme, event.type),
                            borderRadius: "50%",
                            width: theme => theme.spacing(0.5),
                            height: theme => theme.spacing(0.5),
                            minWidth: theme => theme.spacing(0.5),
                            maxWidth: theme => theme.spacing(0.5),
                            minHeight: theme => theme.spacing(0.5),
                            maxHeight: theme => theme.spacing(0.5),
                            overflow: "hidden",
                        }} />}
                        <Typography variant="body2" sx={{
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100%"
                        }}>
                            {`${!mobileView ? (allDay ? "" : (isBefore(event.start, startDate) ? format(startDate, "h:mmaaa") : format(event.start, "h:mmaaa"))) + " " : ""}${getAttendees(event)}${event.title}`}
                        </Typography>
                    </Button>;
                }
                )
            }
        </Box >;
    };

    const generateWeek = (startDate: Date, first: boolean, eventView: DateIterator<CalendarEvent>, teacherView: DateIterator<Slot>) => {
        let days = [];

        for (let i = 0; i < 7; i++) {
            days.push(generateDay(startDate, first, eventView, teacherView));
            startDate = addDays(startDate, 1);
        }

        return <Box key={startDate.toISOString()} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, display: "flex", flexDirection: "row", flex: 1 }}>
            {days}
        </Box>;
    };

    let startDate = setDay(setDateObjDate(setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0), 1), 0);
    let eventView: DateIterator<CalendarEvent> = { index: 0, data: events };
    let teacherView: DateIterator<Slot> = { index: 0, data: slots };
    let weeks = [];

    for (let i = 0; i < 6; i++) {
        weeks.push(generateWeek(startDate, i === 0, eventView, teacherView));
        startDate = addDays(startDate, 7);
    }

    return <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} >
        {weeks}
    </Box>;
};

export default Component;;