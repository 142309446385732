import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Avatar, Box, Container, Grid, Paper, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import { ChangeEventHandler, FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACCOUNT_TYPE_NAMES } from "../../../../../shared/config";
import { Role, STATUS_CODES } from "../../../../../shared/types";
import { getRole, hasRole } from "../../../../../shared/utils";
import { useAuth } from "../../../components/auth";
import { useMessages } from "../../../components/messages";
import { useSnackbar } from "../../../components/snackbar";
import WebinarAlert from "../../../components/webinarAlert";

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const history = useHistory();
    const { ACCOUNT: messages, ERRORS: errors } = useMessages();
    const { user, token, setToken } = useAuth();
    const snackbar = useSnackbar();
    const { id: idString } = useParams<{ id?: string; }>();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [otherErrors, setOtherErrors] = useState("");
    //#endregion

    //#region Form Fields
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [pic, setPic] = useState("avatar0010.png");
    //#endregion

    //#region Form Fields Errors
    const [emailErrors, setEmailErrors] = useState("");
    const [firstNameErrors, setFirstNameErrors] = useState("");
    //#endregion

    const userId = !idString ? (user ? user.id : 0) : (parseInt(idString) ? parseInt(idString) : 0);

    //#region Use Effects
    useEffect(() => {
        if (!user || (user && !user.verified)) { return; }

        (async () => {
            const searchResult = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ userId: userId })
            });

            setOtherErrors("");
            switch (searchResult.status) {
                case STATUS_CODES.OK:
                    let data = await searchResult.json();
                    setEmail(data.email);
                    setName(data.name);
                    setPic(data.pic);
                    break;

                case STATUS_CODES.NOT_FOUND:
                    setOtherErrors(errors.MISSING_USER);
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    return;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
        })().then(() => { });
    }, [history, userId, token, messages, errors, user, snackbar]);
    //#endregion

    //#region Change event handlers
    const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeEmail(event.target.value);
    };

    const changeEmail = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setEmailErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setEmailErrors(errors.MAX_LENGTH_50);
        } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailErrors(errors.INVALID_EMAIL);
        } else {
            setEmailErrors("");
        }

        setEmail(value);
    };

    const onNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeName(event.target.value);
    };

    const changeName = (value: string) => {

        // Validators
        if (value.trim().length === 0) {
            setFirstNameErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setFirstNameErrors(errors.MAX_LENGTH_50);
        } else {
            setFirstNameErrors("");
        }

        setName(value);
    };
    //#endregion

    const onSwitchClick = async () => {
        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/switch-account`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId: userId,
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    setToken((await result.json()).token);
                    snackbar.show(messages.SWITCH_ACCOUNT_SUCCESS);
                    setIsLoading(false);
                    return;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    break;

                case STATUS_CODES.NOT_FOUND:
                    setEmailErrors(errors.MISSING_USER);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };
    //#region HTML
    return (<>
        <Toolbar />
        <Container sx={{ p: { xs: 0, sm: 2 }, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
            <WebinarAlert desktop={false} />
            {otherErrors === "" ? null : <Alert severity="error">{otherErrors}</Alert>}
            <Box component="form" onSubmit={(event: any) => { event.preventDefault(); }} noValidate>
                <Paper elevation={theme.palette.mode === "dark" ? 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} sx={{ p: 2, borderRadius: { xs: "0px", sm: 1, boxShadow: "none" } }}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} justifyContent="center">
                            <Avatar sx={{ width: (theme) => theme.spacing(12), height: (theme) => theme.spacing(12) }} src={`https://edutechnoz.com/images/avatars/${pic}`} alt={name} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" fontWeight="bold" textAlign="center">
                                {`${ACCOUNT_TYPE_NAMES[getRole(userId)]} ${messages.PROFILE.TITLE}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={messages.PROFILE.NAME}
                                value={name}
                                error={firstNameErrors !== ""}
                                helperText={firstNameErrors}
                                disabled
                                autoComplete="name"
                                onChange={onNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={messages.PROFILE.EMAIL}
                                value={email}
                                error={emailErrors !== ""}
                                helperText={emailErrors}
                                disabled
                                type="email"
                                autoComplete="email"
                                onChange={onEmailChange}
                            />
                        </Grid>

                        {!(hasRole(user, [Role.PARENT, Role.STUDENT, Role.ADMIN]) && hasRole({ id: userId, name: "" }, [Role.STUDENT, Role.TEACHER])) ? null :
                            <Grid item xs={12} sm={6}>
                                <LoadingButton sx={{ textTransform: "none" }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { history.push(`/calendar/${userId}`); }}>
                                    {messages.ACTIONS.CALENDAR}
                                </LoadingButton>
                            </Grid>
                        }

                        {!(hasRole(user, [Role.PARENT, Role.ADMIN]) && hasRole({ id: userId, name: "" }, [Role.PARENT])) ? null :
                            <Grid item xs={12} sm={6}>
                                <LoadingButton sx={{ textTransform: "none" }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { history.push(`/enroll/${userId}`); }}>
                                    {messages.ACTIONS.NEW_STUDENT}
                                </LoadingButton >
                            </Grid>
                        }

                        {!(hasRole(user, [Role.PARENT, Role.ADMIN]) && hasRole({ id: userId, name: "" }, [Role.STUDENT])) ? null :
                            <Grid item xs={12} sm={6}>
                                <LoadingButton sx={{ textTransform: "none" }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { history.push(`/enroll/${userId}`); }}>
                                    {messages.ACTIONS.NEW_SEMESTER}
                                </LoadingButton >
                            </Grid>
                        }

                        {!(hasRole(user, [Role.ADMIN]) && hasRole({ id: userId, name: "" }, [Role.PARENT, Role.STUDENT])) ? null :
                            <Grid item xs={12} sm={6}>
                                <LoadingButton sx={{ textTransform: "none" }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { history.push(`/resend-email/${userId}`); }}>
                                    {messages.ACTIONS.RESEND_EMAIL}
                                </LoadingButton >
                            </Grid>
                        }

                        {!(
                            (hasRole(user, [Role.ADMIN]) && hasRole({ id: userId, name: "" }, [Role.PARENT, Role.STUDENT, Role.TEACHER])) ||
                            (hasRole(user, [Role.PARENT]) && hasRole({ id: userId, name: "" }, [Role.STUDENT]))
                        ) ? null :
                            <Grid item xs={12} sm={6}>
                                <LoadingButton sx={{ textTransform: "none" }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={onSwitchClick}>
                                    {messages.ACTIONS.SWITCH_ACCOUNT}
                                </LoadingButton >
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Box>
        </Container>
    </>
    );
    //#endregion
};

export default Component;