import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Grid, Link, Paper, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEventHandler, FormEventHandler, FunctionComponent, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { REGISTRATION_FEE, EVENT_INFO, VERSION_INFO } from "../../../../shared/config";
import { EnrollmentVersion, EventType, STATUS_CODES } from "../../../../shared/types";
import { substitute } from "../../../../shared/utils";
import happyChild from "../../assets/images/happyChild.webp";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useAuth } from "../../components/auth";
import { useEnrollmentVersion } from "../../components/enrollmentVersion";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory<{ redirect?: string; email?: string; }>();
    const { REGISTER: messages, PRICING: pricingMessages, ERRORS: errors } = useMessages();
    const { user, setToken } = useAuth();
    const snackbar = useSnackbar();
    const { version } = useEnrollmentVersion();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [sessionWeeks, setSessionWeeks] = useState(new Array(EventType.SUPPORT + 1).fill(0));
    //#endregion

    //#region Form Fields
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [name, setName] = useState("");
    const [tos, setTos] = useState(false);
    //#endregion

    //#region Form Fields Errors
    const [emailErrors, setEmailErrors] = useState("");
    const [passwordErrors, setPasswordErrors] = useState("");
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState("");
    const [firstNameErrors, setFirstNameErrors] = useState("");
    const [tosErrors, setTosErrors] = useState("");
    //#endregion

    //#region Use Effects
    useEffect(() => {
        if (user !== null) {
            history.replace((history.location.state && (history.location.state as any).redirect) ? (history.location.state as any).redirect : "/calendar");
        }
    }, [user, history]);

    useEffect(() => {
        if (!version) return;

        if (version >= EnrollmentVersion.WEBINAR1 && version <= EnrollmentVersion.WEBINAR3) {
            const { bootWeeks, mainWeeks, supWeeks } = VERSION_INFO[version];
            setSessionWeeks([bootWeeks, mainWeeks, supWeeks]);
        }
    }, [version]);
    //#endregion

    //#region Change event handlers
    const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeEmail(event.target.value);
    };

    const changeEmail = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setEmailErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setEmailErrors(errors.MAX_LENGTH_50);
        } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailErrors("Invalid email");
        } else {
            setEmailErrors("");
        }

        setEmail(value);
    };

    const onPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changePassword(event.target.value);
    };

    const changePassword = (value: string) => {
        // Validators
        if (value.length === 0) {
            setPasswordErrors(errors.REQUIRED);
        } else if (!/[A-Z]/.test(value)) {
            setPasswordErrors(errors.MISSING_UPPERCASE_CHAR);
        } else if (!/[a-z]/.test(value)) {
            setPasswordErrors(errors.MISSING_LOWERCASE_CHAR);
        } else if (!/[\d]/.test(value)) {
            setPasswordErrors(errors.MISSING_NUMBER);
        } else if (!/[@$!%*?&]/.test(value)) {
            setPasswordErrors(errors.MISSING_SPECIAL_CHARACETER);
        } else if (value.length < 8) {
            setPasswordErrors(errors.MIN_LENGTH_8);
        } else if (value.length > 50) {
            setPasswordErrors(errors.MAX_LENGTH_50);
        } else {
            setPasswordErrors("");
        }

        if (value === confirmPassword) {
            if (confirmPasswordErrors === errors.PASSWORD_MISMATCH) {
                setConfirmPasswordErrors("");
            }
        } else if (confirmPassword !== "" && confirmPasswordErrors === "") {
            setConfirmPasswordErrors(errors.PASSWORD_MISMATCH);
        }

        setPassword(value);
    };

    const onConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeConfirmPassword(event.target.value);
    };

    const changeConfirmPassword = (value: string) => {
        // Validators
        if (value.length === 0) {
            setConfirmPasswordErrors(errors.REQUIRED);
        } else if (value !== password) {
            setConfirmPasswordErrors(errors.PASSWORD_MISMATCH);
        } else {
            setConfirmPasswordErrors("");
        }

        setConfirmPassword(value);
    };

    const onNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeName(event.target.value);
    };

    const changeName = (value: string) => {

        // Validators
        if (value.trim().length === 0) {
            setFirstNameErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setFirstNameErrors(errors.MAX_LENGTH_50);
        } else {
            setFirstNameErrors("");
        }

        setName(value);
    };

    const onTosChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeTos(event.target.checked);
    };

    const changeTos = (value: boolean) => {
        // Validators
        if (!value) {
            setTosErrors(errors.MISSING_TOS);
        } else {
            setTosErrors("");
        }

        setTos(value);
    };
    //#endregion

    //#region Validity Checks
    const isFormSubmitable = () => {
        if (isLoading || email.trim() === "" ||
            password === "" ||
            confirmPassword === "" ||
            name.trim() === "" ||
            !tos) {
            return false;
        }

        if (emailErrors !== "" ||
            passwordErrors !== "" ||
            confirmPasswordErrors !== "" ||
            firstNameErrors !== "" ||
            tosErrors !== "") {
            return false;
        }

        return true;
    };
    //#endregion

    //#region Submit Event Handlers
    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        // Populate error messages
        changeName(name);
        changeEmail(email);
        changePassword(password);
        changeConfirmPassword(confirmPassword);
        changeTos(tos);

        if (!isFormSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                method: "POST",

                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    email: email.trim(),
                    password: password,
                    name: name.trim(),
                    version: version
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    return setToken((await result.json()).token);

                case STATUS_CODES.CONFLICT:
                    return history.replace("/login", { ...history.location.state, email: email });

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };
    //#endregion

    //#region Other
    const getTutoringSessionsCount = () => {
        let total = 0;

        for (let i = 0; i < 3; i++) {
            total += EVENT_INFO[i].daysPerWeek * sessionWeeks[i] * (i !== EventType.BOOTCAMP ? VERSION_INFO[version].sessionMultiplier : 1);
        }

        return total;
    };

    const applyWebinarDiscount = (price: number) => {
        return price - (price * VERSION_INFO[version].discount / 100);
    };
    //#endregion

    //#region HTML
    return (<Container sx={{ p: { xs: 0, sm: 2 }, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="lg">
        <WebinarAlert />
        <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Logo height={theme.spacing(5)} />
        </Box>
        <Paper elevation={0}>
            <Typography sx={{ p: { xs: 2, sm: 0 } }} variant="h5" fontWeight="bold" textAlign="center">
                {messages.TITLE}
            </Typography>
        </Paper>
        <Box sx={{ px: { xs: 0, sm: 8 } }} component="form" onSubmit={onFormSubmit} noValidate>
            <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, boxShadow: "none" }} elevation={(theme.palette.mode !== "dark") ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ px: { xs: 2, sm: 4 }, pt: { xs: 2, sm: 4 }, pb: { xs: 0, md: 4 } }}>
                            {messages.DESCRIPTION}
                            {
                                version === EnrollmentVersion.V2 || version === EnrollmentVersion.WEBINAR4 ? null :
                                    <>
                                        {substitute(`\n\n${pricingMessages.TUTORING_SESSIONS}`, {
                                            BOOTCAMP_COUNT: (EVENT_INFO[EventType.BOOTCAMP].daysPerWeek * sessionWeeks[EventType.BOOTCAMP]).toLocaleString(),
                                            MAIN_COUNT: (EVENT_INFO[EventType.MAIN].daysPerWeek * sessionWeeks[EventType.MAIN] * VERSION_INFO[version].sessionMultiplier).toLocaleString(),
                                            SUPPORT_COUNT: (EVENT_INFO[EventType.SUPPORT].daysPerWeek * sessionWeeks[EventType.SUPPORT] * VERSION_INFO[version].sessionMultiplier).toLocaleString()
                                        })}

                                        {substitute(`\n${pricingMessages.COMPLEMENTARY_CO_CURRICULAR_SESSIONS}`, { AMOUNT: VERSION_INFO[version].complementaryCocurricularSessions.toLocaleString() })}

                                        {!VERSION_INFO[version].cocurricularSessions ? null : substitute(`\n${pricingMessages.CO_CURRICULAR_SESSIONS}`, { AMOUNT: VERSION_INFO[version].cocurricularSessions.toLocaleString() })}

                                        <Typography sx={{ fontWeight: "bold" }} component="span">
                                            {substitute(`\n${pricingMessages.TOTAL_SESSIONS}`, { AMOUNT: (getTutoringSessionsCount() + VERSION_INFO[version].complementaryCocurricularSessions + VERSION_INFO[version].cocurricularSessions).toLocaleString() })}
                                        </Typography>

                                        {substitute(`\n\n${pricingMessages.REGISTRATION_FEE}`, {
                                            AMOUNT: (REGISTRATION_FEE / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                        })}

                                        {substitute(`\n${pricingMessages.TUITION_FEE}`, { AMOUNT: (VERSION_INFO[version].tuitionFee / 100).toLocaleString() })}

                                        {!VERSION_INFO[version].cocurricularFee ? null : substitute(`\n${pricingMessages.CO_CURRICULAR_FEE}`, { AMOUNT: (VERSION_INFO[version].cocurricularFee / 100).toLocaleString() })}

                                        <Typography sx={{ fontWeight: "bold" }} component="span">
                                            {substitute(`\n\n${pricingMessages.SUBTOTAL}`, {
                                                AMOUNT: (Math.floor(REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee) / 100)
                                                    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            })}
                                        </Typography>

                                        {!VERSION_INFO[version].discount ? null :
                                            <Typography sx={{ fontWeight: "bold" }} color="primary" component="span">
                                                {substitute(`\n${pricingMessages.WEBINAR_DISCOUNT} (${VERSION_INFO[version].discount}%)`, {
                                                    AMOUNT: (Math.floor((REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee) - applyWebinarDiscount(REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee)) / 100)
                                                        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                })}
                                            </Typography>}

                                        <Typography sx={{ fontWeight: "bold" }} variant="h6" color="error" component="span">
                                            {substitute(`\n\n${VERSION_INFO[version].specialOffer ? pricingMessages.SPECIAL_OFFER : pricingMessages.TOTAL}`, {
                                                AMOUNT: (Math.floor(applyWebinarDiscount(VERSION_INFO[version].specialOffer ? VERSION_INFO[version].specialOffer : (REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee))) / 100)
                                                    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            })}
                                        </Typography>

                                        {!VERSION_INFO[version].specialOffer ? null :
                                            <Typography sx={{ fontWeight: "bold" }} component="span" color="primary">
                                                {`\n${pricingMessages.TOTAL_VALUE}`}
                                                <Typography sx={{ fontWeight: "bold", textDecoration: "line-through" }} component="span">
                                                    {substitute(pricingMessages.TOTAL_VALUE_AMOUNT, { AMOUNT: (Math.floor(REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })}
                                                </Typography>
                                            </Typography>}
                                    </>
                            }
                            {
                                version !== EnrollmentVersion.WEBINAR4 ? null :
                                    <>
                                        {substitute(pricingMessages.ASSESSMENT_FEE, { AMOUNT: (VERSION_INFO[version].tuitionFee / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })}

                                        <Typography sx={{ fontWeight: "bold" }} component="span">
                                            {substitute(`\n\n${pricingMessages.SUBTOTAL}`, {
                                                AMOUNT: (Math.floor(VERSION_INFO[version].tuitionFee) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            })}
                                        </Typography>

                                        <Typography sx={{ fontWeight: "bold" }} component="span" variant="h6" color="error">
                                            {substitute(`\n\n${VERSION_INFO[version].specialOffer ? pricingMessages.SPECIAL_OFFER : pricingMessages.TOTAL}`, {
                                                AMOUNT: (Math.floor(VERSION_INFO[version].specialOffer ? VERSION_INFO[version].specialOffer : VERSION_INFO[version].tuitionFee) / 100)
                                                    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            })}
                                        </Typography>

                                        {!VERSION_INFO[version].specialOffer ? null :
                                            <Typography sx={{ fontWeight: "bold" }} component="span" color="primary">
                                                {`\n${pricingMessages.TOTAL_VALUE}`}
                                                <Typography sx={{ fontWeight: "bold", textDecoration: "line-through" }} component="span">
                                                    {substitute(pricingMessages.TOTAL_VALUE_AMOUNT, { AMOUNT: (Math.floor(REGISTRATION_FEE + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })}
                                                </Typography>
                                            </Typography>}

                                        <Typography sx={{ fontWeight: "bold" }} component="span" color="primary">
                                            {`\n\n${pricingMessages.COUPON_INFO}`}
                                        </Typography>
                                    </>
                            }
                        </Typography>
                        <Box sx={{ flex: 1 }} />
                        {
                            !(version === EnrollmentVersion.V2 || version === EnrollmentVersion.WEBINAR4) ? null :
                                <Box component="img" draggable={false} src={happyChild} alt="happy child" sx={{
                                    display: { xs: "none", md: "block" }, maxHeight: 400,
                                    objectFit: "contain",
                                    objectPosition: 0,
                                    userSelect: "none"
                                }} />
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} sx={{ p: { xs: 2, sm: 4 } }}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label={messages.NAME}
                                    value={name}
                                    error={firstNameErrors !== ""}
                                    helperText={emailErrors}
                                    type="email"
                                    autoComplete="email"
                                    onChange={onNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label={messages.EMAIL}
                                    value={email}
                                    error={emailErrors !== ""}
                                    helperText={emailErrors}
                                    type="email"
                                    autoComplete="email"
                                    onChange={onEmailChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label={messages.PASSWORD}
                                    value={password}
                                    error={passwordErrors !== ""}
                                    helperText={passwordErrors}
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={onPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label={messages.CONFIRM_PASSWORD}
                                    value={confirmPassword}
                                    error={confirmPasswordErrors !== ""}
                                    helperText={confirmPasswordErrors}
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={onConfirmPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required error={tosErrors !== ""}>
                                    <FormControlLabel label={<Typography>{messages.TOS[0]}<Link color={theme.palette.event2.main} href="https://edutechnoz.com/AboutUs/Terms.php" target="_blank">{messages.TOS[1]}</Link></Typography>} control={<Checkbox checked={tos} onChange={onTosChange} />} />
                                    <FormHelperText>{tosErrors}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography >{messages.LOGIN[0]}<Link color={theme.palette.event2.main} to={{ pathname: "/login", state: history.location.state }} replace component={RouterLink}>{messages.LOGIN[1]}</Link></Typography>
                            </Grid>
                            {version !== EnrollmentVersion.V2 ? null :
                                <Grid item xs={12}>
                                    <Typography color={(theme) => theme.palette.event2.main}>{messages.NOTE_V1}</Typography>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {(
                                        version === EnrollmentVersion.V1 ||
                                        version === EnrollmentVersion.WEBINAR1 ||
                                        version === EnrollmentVersion.WEBINAR2 ||
                                        version === EnrollmentVersion.WEBINAR3 ||
                                        version === EnrollmentVersion.WEBINAR4
                                    ) ? messages.REGISTER_V1 :
                                        <Box>
                                            <Typography sx={{ fontWeight: "bold" }}>{messages.REGISTER_V2}</Typography>
                                            <Typography variant="body2">{messages.NOTE_V2}</Typography>
                                        </Box>
                                    }
                                </LoadingButton >
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    </Container >
    );
    //#endregion
};

export default Component;
