import { addSeconds } from "date-fns";
import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { VERSION_INFO, WEBINAR_TIME_LIMIT } from "../../../../shared/config";
import { EnrollmentVersion, Role } from "../../../../shared/types";
import { getCookie, hasRole, setCookie } from "../../../../shared/utils";
import { useAuth } from "../auth";

interface State {
    version: EnrollmentVersion;
    remainingTime: number | null;
    setEnrollmentVersion: (version: EnrollmentVersion, save?: boolean) => void;
}

const EnrollmentVersionContext = createContext<State>({ version: EnrollmentVersion.V1, remainingTime: null, setEnrollmentVersion: () => { } });

const Component: FunctionComponent = ({ children }) => {
    //#region Hooks
    const history = useHistory();
    const { user } = useAuth();
    //#endregion

    //#region State
    const [state, setState] = useState<State>({
        version: EnrollmentVersion.V2, remainingTime: null, setEnrollmentVersion: (version: EnrollmentVersion, save = false) => {
            if (save) { setCookie("version", version.toString()); }
            setState(state => { return { ...state, version: version, remainingTime: null }; });
        }
    });
    //#endregion

    //#region Use Effects
    useEffect(() => {
        // Choose Version
        let versionString = getCookie("version");
        let version = versionString ? parseInt(versionString) : null;
        const params = new URLSearchParams(history.location.search);
        let versionId = params.get('version');

        // Handle webinar time limit
        for (let i = EnrollmentVersion.WEBINAR1; i <= EnrollmentVersion.WEBINAR4; i++) {
            if (versionId === VERSION_INFO[i].id) {
                version = i;
                setCookie("webinarTimer", addSeconds(new Date(), WEBINAR_TIME_LIMIT).toISOString());
                break;
            }
        }

        // Choose new version
        if (version === null) {
            for (let i = EnrollmentVersion.V1; i <= EnrollmentVersion.V3; i++) {
                if (versionId === VERSION_INFO[i].id) {
                    version = i;
                    break;
                }
            }
        }

        if (version === null || isNaN(version) || version < EnrollmentVersion.V1 || version > EnrollmentVersion.WEBINAR4) {
            version = EnrollmentVersion.V2;
        }

        setCookie("version", version.toString());
        let tempEnrollmentVersion = version;
        setState(state => { return { ...state, version: tempEnrollmentVersion }; });

        if (versionId) {
            params.delete("version");
            history.replace({ search: params.toString() });
        }
    }, [history]);

    useEffect(() => {
        let title = document.title;
        let webinarTimeout = 0;

        document.title = `${title} (${VERSION_INFO[state.version].title})`;

        if (state.version >= EnrollmentVersion.WEBINAR1 && state.version <= EnrollmentVersion.WEBINAR4) {
            let date = getCookie("webinarTimer");

            if (!date) {
                date = addSeconds(new Date(), WEBINAR_TIME_LIMIT).toISOString();
                setCookie("webinarTimer", date);
            }

            if (new Date().getTime() < new Date(date).getTime() || state.remainingTime === null) {
                const callback = () => {
                    let duration = new Date(date ?? "").getTime() - new Date().getTime();
                    if (duration <= 0) {
                        if (history.location.pathname !== "/enroll") {
                            setCookie("version", EnrollmentVersion.V2.toString());
                            setState((state) => { return { ...state, version: EnrollmentVersion.V2, remainingTime: 0 }; });
                        } else {
                            setState((state) => { return { ...state, remainingTime: 0 }; });
                        }
                    } else {
                        setState((state) => { return { ...state, remainingTime: Math.ceil(duration / 1000) }; });
                    }
                };

                if (state.remainingTime === null) {
                    callback();
                } else {
                    webinarTimeout = setTimeout(callback, 1000) as any as number;
                }
            }
        }

        return () => {
            document.title = title;
            clearTimeout(webinarTimeout);
        };
    }, [state, history]);

    useEffect(() => {
        if (user && hasRole(user, Role.ADMIN)) {
            setState(state => { return { ...state, version: EnrollmentVersion.V2, remainingTime: null }; });
        }
    }, [user]);
    //#endregion

    //#region HTML
    return (
        <EnrollmentVersionContext.Provider value={state}>
            {children}
        </EnrollmentVersionContext.Provider>
    );
    //#endregion
};

export default Component;

//#region Custom Hooks
export const useEnrollmentVersion = () => {
    return useContext(EnrollmentVersionContext);
};
//#endregion