export interface CountryType {
    ind: number;
    code: string;
    label: string;
    phone: string;
}

export const countries: CountryType[] = [
    { code: "AD", label: "Andorra", phone: "376", ind: 0 },
    { code: "AE", label: "United Arab Emirates", phone: "971", ind: 1 },
    { code: "AF", label: "Afghanistan", phone: "93", ind: 2 },
    { code: "AG", label: "Antigua and Barbuda", phone: "1-268", ind: 3 },
    { code: "AI", label: "Anguilla", phone: "1-264", ind: 4 },
    { code: "AL", label: "Albania", phone: "355", ind: 5 },
    { code: "AM", label: "Armenia", phone: "374", ind: 6 },
    { code: "AO", label: "Angola", phone: "244", ind: 7 },
    { code: "AQ", label: "Antarctica", phone: "672", ind: 8 },
    { code: "AR", label: "Argentina", phone: "54", ind: 9 },
    { code: "AS", label: "American Samoa", phone: "1-684", ind: 10 },
    { code: "AT", label: "Austria", phone: "43", ind: 11 },
    { code: "AU", label: "Australia", phone: "61", ind: 12 },
    { code: "AW", label: "Aruba", phone: "297", ind: 13 },
    { code: "AX", label: "Alland Islands", phone: "358", ind: 14 },
    { code: "AZ", label: "Azerbaijan", phone: "994", ind: 15 },
    { code: "BA", label: "Bosnia and Herzegovina", phone: "387", ind: 16 },
    { code: "BB", label: "Barbados", phone: "1-246", ind: 17 },
    { code: "BD", label: "Bangladesh", phone: "880", ind: 18 },
    { code: "BE", label: "Belgium", phone: "32", ind: 19 },
    { code: "BF", label: "Burkina Faso", phone: "226", ind: 20 },
    { code: "BG", label: "Bulgaria", phone: "359", ind: 21 },
    { code: "BH", label: "Bahrain", phone: "973", ind: 22 },
    { code: "BI", label: "Burundi", phone: "257", ind: 23 },
    { code: "BJ", label: "Benin", phone: "229", ind: 24 },
    { code: "BL", label: "Saint Barthelemy", phone: "590", ind: 25 },
    { code: "BM", label: "Bermuda", phone: "1-441", ind: 26 },
    { code: "BN", label: "Brunei Darussalam", phone: "673", ind: 27 },
    { code: "BO", label: "Bolivia", phone: "591", ind: 28 },
    { code: "BR", label: "Brazil", phone: "55", ind: 29 },
    { code: "BS", label: "Bahamas", phone: "1-242", ind: 30 },
    { code: "BT", label: "Bhutan", phone: "975", ind: 31 },
    { code: "BV", label: "Bouvet Island", phone: "47", ind: 32 },
    { code: "BW", label: "Botswana", phone: "267", ind: 33 },
    { code: "BY", label: "Belarus", phone: "375", ind: 34 },
    { code: "BZ", label: "Belize", phone: "501", ind: 35 },
    { code: "CA", label: "Canada", phone: "1", ind: 36 },
    { code: "CC", label: "Cocos (Keeling) Islands", phone: "61", ind: 37 },
    { code: "CD", label: "Congo, Democratic Republic of the", phone: "243", ind: 38 },
    { code: "CF", label: "Central African Republic", phone: "236", ind: 39 },
    { code: "CG", label: "Congo, Republic of the", phone: "242", ind: 40 },
    { code: "CH", label: "Switzerland", phone: "41", ind: 41 },
    { code: "CI", label: "Cote d'Ivoire", phone: "225", ind: 42 },
    { code: "CK", label: "Cook Islands", phone: "682", ind: 43 },
    { code: "CL", label: "Chile", phone: "56", ind: 44 },
    { code: "CM", label: "Cameroon", phone: "237", ind: 45 },
    { code: "CN", label: "China", phone: "86", ind: 46 },
    { code: "CO", label: "Colombia", phone: "57", ind: 47 },
    { code: "CR", label: "Costa Rica", phone: "506", ind: 48 },
    { code: "CU", label: "Cuba", phone: "53", ind: 49 },
    { code: "CV", label: "Cape Verde", phone: "238", ind: 50 },
    { code: "CW", label: "Curacao", phone: "599", ind: 51 },
    { code: "CX", label: "Christmas Island", phone: "61", ind: 52 },
    { code: "CY", label: "Cyprus", phone: "357", ind: 53 },
    { code: "CZ", label: "Czech Republic", phone: "420", ind: 54 },
    { code: "DE", label: "Germany", phone: "49", ind: 55 },
    { code: "DJ", label: "Djibouti", phone: "253", ind: 56 },
    { code: "DK", label: "Denmark", phone: "45", ind: 57 },
    { code: "DM", label: "Dominica", phone: "1-767", ind: 58 },
    { code: "DO", label: "Dominican Republic", phone: "1-809", ind: 59 },
    { code: "DZ", label: "Algeria", phone: "213", ind: 60 },
    { code: "EC", label: "Ecuador", phone: "593", ind: 61 },
    { code: "EE", label: "Estonia", phone: "372", ind: 62 },
    { code: "EG", label: "Egypt", phone: "20", ind: 63 },
    { code: "EH", label: "Western Sahara", phone: "212", ind: 64 },
    { code: "ER", label: "Eritrea", phone: "291", ind: 65 },
    { code: "ES", label: "Spain", phone: "34", ind: 66 },
    { code: "ET", label: "Ethiopia", phone: "251", ind: 67 },
    { code: "FI", label: "Finland", phone: "358", ind: 68 },
    { code: "FJ", label: "Fiji", phone: "679", ind: 69 },
    { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500", ind: 70 },
    { code: "FM", label: "Micronesia, Federated States of", phone: "691", ind: 71 },
    { code: "FO", label: "Faroe Islands", phone: "298", ind: 72 },
    { code: "FR", label: "France", phone: "33", ind: 73 },
    { code: "GA", label: "Gabon", phone: "241", ind: 74 },
    { code: "GB", label: "United Kingdom", phone: "44", ind: 75 },
    { code: "GD", label: "Grenada", phone: "1-473", ind: 76 },
    { code: "GE", label: "Georgia", phone: "995", ind: 77 },
    { code: "GF", label: "French Guiana", phone: "594", ind: 78 },
    { code: "GG", label: "Guernsey", phone: "44", ind: 79 },
    { code: "GH", label: "Ghana", phone: "233", ind: 80 },
    { code: "GI", label: "Gibraltar", phone: "350", ind: 81 },
    { code: "GL", label: "Greenland", phone: "299", ind: 82 },
    { code: "GM", label: "Gambia", phone: "220", ind: 83 },
    { code: "GN", label: "Guinea", phone: "224", ind: 84 },
    { code: "GP", label: "Guadeloupe", phone: "590", ind: 85 },
    { code: "GQ", label: "Equatorial Guinea", phone: "240", ind: 86 },
    { code: "GR", label: "Greece", phone: "30", ind: 87 },
    { code: "GS", label: "South Georgia and the South Sandwich Islands", phone: "500", ind: 88 },
    { code: "GT", label: "Guatemala", phone: "502", ind: 89 },
    { code: "GU", label: "Guam", phone: "1-671", ind: 90 },
    { code: "GW", label: "Guinea-Bissau", phone: "245", ind: 91 },
    { code: "GY", label: "Guyana", phone: "592", ind: 92 },
    { code: "HK", label: "Hong Kong", phone: "852", ind: 93 },
    { code: "HM", label: "Heard Island and McDonald Islands", phone: "672", ind: 94 },
    { code: "HN", label: "Honduras", phone: "504", ind: 95 },
    { code: "HR", label: "Croatia", phone: "385", ind: 96 },
    { code: "HT", label: "Haiti", phone: "509", ind: 97 },
    { code: "HU", label: "Hungary", phone: "36", ind: 98 },
    { code: "ID", label: "Indonesia", phone: "62", ind: 99 },
    { code: "IE", label: "Ireland", phone: "353", ind: 100 },
    { code: "IL", label: "Israel", phone: "972", ind: 101 },
    { code: "IM", label: "Isle of Man", phone: "44", ind: 102 },
    { code: "IN", label: "India", phone: "91", ind: 103 },
    { code: "IO", label: "British Indian Ocean Territory", phone: "246", ind: 104 },
    { code: "IQ", label: "Iraq", phone: "964", ind: 105 },
    { code: "IR", label: "Iran, Islamic Republic of", phone: "98", ind: 106 },
    { code: "IS", label: "Iceland", phone: "354", ind: 107 },
    { code: "IT", label: "Italy", phone: "39", ind: 108 },
    { code: "JE", label: "Jersey", phone: "44", ind: 109 },
    { code: "JM", label: "Jamaica", phone: "1-876", ind: 110 },
    { code: "JO", label: "Jordan", phone: "962", ind: 111 },
    { code: "JP", label: "Japan", phone: "81", ind: 112 },
    { code: "KE", label: "Kenya", phone: "254", ind: 113 },
    { code: "KG", label: "Kyrgyzstan", phone: "996", ind: 114 },
    { code: "KH", label: "Cambodia", phone: "855", ind: 115 },
    { code: "KI", label: "Kiribati", phone: "686", ind: 116 },
    { code: "KM", label: "Comoros", phone: "269", ind: 117 },
    { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869", ind: 118 },
    { code: "KP", label: "Korea, Democratic People's Republic of", phone: "850", ind: 119 },
    { code: "KR", label: "Korea, Republic of", phone: "82", ind: 120 },
    { code: "KW", label: "Kuwait", phone: "965", ind: 121 },
    { code: "KY", label: "Cayman Islands", phone: "1-345", ind: 122 },
    { code: "KZ", label: "Kazakhstan", phone: "7", ind: 123 },
    { code: "LA", label: "Lao People's Democratic Republic", phone: "856", ind: 124 },
    { code: "LB", label: "Lebanon", phone: "961", ind: 125 },
    { code: "LC", label: "Saint Lucia", phone: "1-758", ind: 126 },
    { code: "LI", label: "Liechtenstein", phone: "423", ind: 127 },
    { code: "LK", label: "Sri Lanka", phone: "94", ind: 128 },
    { code: "LR", label: "Liberia", phone: "231", ind: 129 },
    { code: "LS", label: "Lesotho", phone: "266", ind: 130 },
    { code: "LT", label: "Lithuania", phone: "370", ind: 131 },
    { code: "LU", label: "Luxembourg", phone: "352", ind: 132 },
    { code: "LV", label: "Latvia", phone: "371", ind: 133 },
    { code: "LY", label: "Libya", phone: "218", ind: 134 },
    { code: "MA", label: "Morocco", phone: "212", ind: 135 },
    { code: "MC", label: "Monaco", phone: "377", ind: 136 },
    { code: "MD", label: "Moldova, Republic of", phone: "373", ind: 137 },
    { code: "ME", label: "Montenegro", phone: "382", ind: 138 },
    { code: "MF", label: "Saint Martin (French part)", phone: "590", ind: 139 },
    { code: "MG", label: "Madagascar", phone: "261", ind: 140 },
    { code: "MH", label: "Marshall Islands", phone: "692", ind: 141 },
    { code: "MK", label: "Macedonia, the Former Yugoslav Republic of", phone: "389", ind: 142 },
    { code: "ML", label: "Mali", phone: "223", ind: 143 },
    { code: "MM", label: "Myanmar", phone: "95", ind: 144 },
    { code: "MN", label: "Mongolia", phone: "976", ind: 145 },
    { code: "MO", label: "Macao", phone: "853", ind: 146 },
    { code: "MP", label: "Northern Mariana Islands", phone: "1-670", ind: 147 },
    { code: "MQ", label: "Martinique", phone: "596", ind: 148 },
    { code: "MR", label: "Mauritania", phone: "222", ind: 149 },
    { code: "MS", label: "Montserrat", phone: "1-664", ind: 150 },
    { code: "MT", label: "Malta", phone: "356", ind: 151 },
    { code: "MU", label: "Mauritius", phone: "230", ind: 152 },
    { code: "MV", label: "Maldives", phone: "960", ind: 153 },
    { code: "MW", label: "Malawi", phone: "265", ind: 154 },
    { code: "MX", label: "Mexico", phone: "52", ind: 155 },
    { code: "MY", label: "Malaysia", phone: "60", ind: 156 },
    { code: "MZ", label: "Mozambique", phone: "258", ind: 157 },
    { code: "NA", label: "Namibia", phone: "264", ind: 158 },
    { code: "NC", label: "New Caledonia", phone: "687", ind: 159 },
    { code: "NE", label: "Niger", phone: "227", ind: 160 },
    { code: "NF", label: "Norfolk Island", phone: "672", ind: 161 },
    { code: "NG", label: "Nigeria", phone: "234", ind: 162 },
    { code: "NI", label: "Nicaragua", phone: "505", ind: 163 },
    { code: "NL", label: "Netherlands", phone: "31", ind: 164 },
    { code: "NO", label: "Norway", phone: "47", ind: 165 },
    { code: "NP", label: "Nepal", phone: "977", ind: 166 },
    { code: "NR", label: "Nauru", phone: "674", ind: 167 },
    { code: "NU", label: "Niue", phone: "683", ind: 168 },
    { code: "NZ", label: "New Zealand", phone: "64", ind: 169 },
    { code: "OM", label: "Oman", phone: "968", ind: 170 },
    { code: "PA", label: "Panama", phone: "507", ind: 171 },
    { code: "PE", label: "Peru", phone: "51", ind: 172 },
    { code: "PF", label: "French Polynesia", phone: "689", ind: 173 },
    { code: "PG", label: "Papua New Guinea", phone: "675", ind: 174 },
    { code: "PH", label: "Philippines", phone: "63", ind: 175 },
    { code: "PK", label: "Pakistan", phone: "92", ind: 176 },
    { code: "PL", label: "Poland", phone: "48", ind: 177 },
    { code: "PM", label: "Saint Pierre and Miquelon", phone: "508", ind: 178 },
    { code: "PN", label: "Pitcairn", phone: "870", ind: 179 },
    { code: "PR", label: "Puerto Rico", phone: "1", ind: 180 },
    { code: "PS", label: "Palestine, State of", phone: "970", ind: 181 },
    { code: "PT", label: "Portugal", phone: "351", ind: 182 },
    { code: "PW", label: "Palau", phone: "680", ind: 183 },
    { code: "PY", label: "Paraguay", phone: "595", ind: 184 },
    { code: "QA", label: "Qatar", phone: "974", ind: 185 },
    { code: "RE", label: "Reunion", phone: "262", ind: 186 },
    { code: "RO", label: "Romania", phone: "40", ind: 187 },
    { code: "RS", label: "Serbia", phone: "381", ind: 188 },
    { code: "RU", label: "Russian Federation", phone: "7", ind: 189 },
    { code: "RW", label: "Rwanda", phone: "250", ind: 190 },
    { code: "SA", label: "Saudi Arabia", phone: "966", ind: 191 },
    { code: "SB", label: "Solomon Islands", phone: "677", ind: 192 },
    { code: "SC", label: "Seychelles", phone: "248", ind: 193 },
    { code: "SD", label: "Sudan", phone: "249", ind: 194 },
    { code: "SE", label: "Sweden", phone: "46", ind: 195 },
    { code: "SG", label: "Singapore", phone: "65", ind: 196 },
    { code: "SH", label: "Saint Helena", phone: "290", ind: 197 },
    { code: "SI", label: "Slovenia", phone: "386", ind: 198 },
    { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47", ind: 199 },
    { code: "SK", label: "Slovakia", phone: "421", ind: 200 },
    { code: "SL", label: "Sierra Leone", phone: "232", ind: 201 },
    { code: "SM", label: "San Marino", phone: "378", ind: 202 },
    { code: "SN", label: "Senegal", phone: "221", ind: 203 },
    { code: "SO", label: "Somalia", phone: "252", ind: 204 },
    { code: "SR", label: "Suriname", phone: "597", ind: 205 },
    { code: "SS", label: "South Sudan", phone: "211", ind: 206 },
    { code: "ST", label: "Sao Tome and Principe", phone: "239", ind: 207 },
    { code: "SV", label: "El Salvador", phone: "503", ind: 208 },
    { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721", ind: 209 },
    { code: "SY", label: "Syrian Arab Republic", phone: "963", ind: 210 },
    { code: "SZ", label: "Swaziland", phone: "268", ind: 211 },
    { code: "TC", label: "Turks and Caicos Islands", phone: "1-649", ind: 212 },
    { code: "TD", label: "Chad", phone: "235", ind: 213 },
    { code: "TF", label: "French Southern Territories", phone: "262", ind: 214 },
    { code: "TG", label: "Togo", phone: "228", ind: 215 },
    { code: "TH", label: "Thailand", phone: "66", ind: 216 },
    { code: "TJ", label: "Tajikistan", phone: "992", ind: 217 },
    { code: "TK", label: "Tokelau", phone: "690", ind: 218 },
    { code: "TL", label: "Timor-Leste", phone: "670", ind: 219 },
    { code: "TM", label: "Turkmenistan", phone: "993", ind: 220 },
    { code: "TN", label: "Tunisia", phone: "216", ind: 221 },
    { code: "TO", label: "Tonga", phone: "676", ind: 222 },
    { code: "TR", label: "Turkey", phone: "90", ind: 223 },
    { code: "TT", label: "Trinidad and Tobago", phone: "1-868", ind: 224 },
    { code: "TV", label: "Tuvalu", phone: "688", ind: 225 },
    { code: "TW", label: "Taiwan, Province of China", phone: "886", ind: 226 },
    { code: "TZ", label: "United Republic of Tanzania", phone: "255", ind: 227 },
    { code: "UA", label: "Ukraine", phone: "380", ind: 228 },
    { code: "UG", label: "Uganda", phone: "256", ind: 229 },
    { code: "US", label: "United States", phone: "1", ind: 230 },
    { code: "UY", label: "Uruguay", phone: "598", ind: 231 },
    { code: "UZ", label: "Uzbekistan", phone: "998", ind: 232 },
    { code: "VA", label: "Holy See (Vatican City State)", phone: "379", ind: 233 },
    { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784", ind: 234 },
    { code: "VE", label: "Venezuela", phone: "58", ind: 235 },
    { code: "VG", label: "British Virgin Islands", phone: "1-284", ind: 236 },
    { code: "VI", label: "US Virgin Islands", phone: "1-340", ind: 237 },
    { code: "VN", label: "Vietnam", phone: "84", ind: 238 },
    { code: "VU", label: "Vanuatu", phone: "678", ind: 239 },
    { code: "WF", label: "Wallis and Futuna", phone: "681", ind: 240 },
    { code: "WS", label: "Samoa", phone: "685", ind: 241 },
    { code: "XK", label: "Kosovo", phone: "383", ind: 242 },
    { code: "YE", label: "Yemen", phone: "967", ind: 243 },
    { code: "YT", label: "Mayotte", phone: "262", ind: 244 },
    { code: "ZA", label: "South Africa", phone: "27", ind: 245 },
    { code: "ZM", label: "Zambia", phone: "260", ind: 246 },
    { code: "ZW", label: "Zimbabwe", phone: "263", ind: 247 }
];