import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DatePicker from '@mui/lab/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertTitle, Autocomplete, Box, Button, Card, CardContent, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, IconButton, LinearProgress, Link, Paper, TextField, Theme, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeCardElementChangeEvent, StripeElements } from "@stripe/stripe-js";
import { format, setDay } from "date-fns";
import { ChangeEventHandler, FormEvent, FormEventHandler, FunctionComponent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useHistory, useParams } from "react-router-dom";
import { ENROLL_START_DATE, EVENT_INFO, REGISTRATION_FEE, UPCOMING_TIMEZONE_CHANGE_DATES, VERSION_INFO, WORKING_DAYS } from "../../../../shared/config";
import { Booking, CalendarEvent, Coupon, EnrollmentVersion, EventType, Package, Role, Session, STATUS_CODES, Time } from "../../../../shared/types";
import { computePrice, getRole, hasRole, substitute } from "../../../../shared/utils";
import paymentChild from "../../assets/images/paymentChild.webp";
import postPaymentChild from "../../assets/images/postPaymentChild.webp";
import { useRequireAuth } from "../../components/auth";
import { useEnrollmentVersion } from "../../components/enrollmentVersion";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";
import { countries, CountryType } from "../../countries";
import { overlapsEvents } from "../../scheduling";
import styles from "./index.module.css";
import Pricing from "./pricing";


enum Step {
    SETTINGS,
    PACKAGES,
    START_WEEK,
    BOOTCAMP,
    MAIN,
    SUPPORT,
    TIMETABLE,
    VERIFY,
    STUDENT_INFO,
    PAYMENT,
    POST_PAYMENT
}

const SESSION_CLASS_NAMES = [styles.bootcamp, styles.main, styles.support];

const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_STRIPE_PK!);

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory();
    const { ENROLL: messages, PRICING: pricingMessages, ERRORS: errors, PACKAGES: packageMessages } = useMessages();
    const { user, token, setToken } = useRequireAuth();
    const snackbar = useSnackbar();
    const { version, setEnrollmentVersion } = useEnrollmentVersion();
    const { id: idString } = useParams<{ id?: string; }>();
    const userId = !idString ? (user ? user.id : 0) : (parseInt(idString) ? parseInt(idString) : 0);
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [cardFocused, setCardFocused] = useState(false);
    const [timezoneChangeDate, setTimezoneChangeDate] = useState<Date | null>(null);
    const [packageList, setPackageList] = useState<Package[]>([]);
    const [isRenewal, setIsRenewal] = useState(false);
    const [selectedPackageIndex, setSelectedPackageIndex] = useState<number>(0);
    const [sessionWeeks, setSessionWeeks] = useState(new Array(EventType.SUPPORT + 1).fill(0));
    const [teacherId, setTeacherId] = useState(NaN);
    const [selectedDays, setSelectedDays] = useState<number[][]>(new Array(EventType.SUPPORT + 1).fill([]));
    const [sessions, setSessions] = useState<Session[][]>(new Array(EventType.SUPPORT + 1).fill([]));
    const [selectedTimes, setSelectedTimes] = useState<number[]>(new Array(EventType.SUPPORT + 1).fill(-1));
    const [times, setTimes] = useState<Time[][]>(new Array(EventType.SUPPORT + 1).fill([]));
    const [startWeek, setStartWeek] = useState<Date | null>(null);
    const [step, setStep] = useState<Step>(hasRole(user, Role.ADMIN) ? Step.SETTINGS : Step.PACKAGES);
    const [remainingCodeCooldown, setRemainingCodeCooldown] = useState(0);
    const [isCouponLoading, setIsCouponCodeLoading] = useState(false);
    const [holidayEvents, setHolidayEvents] = useState<CalendarEvent[]>([]);
    //#endregion

    //#region Form Fields
    const [verificationCode, setVerificationCode] = useState("");
    const [nameOnCard, setNameOnCard] = useState("");
    const [studentName, setStudentName] = useState("");
    const [country, setCountry] = useState<number | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [couponCode, setCouponCode] = useState("");
    const [coupon, setCoupon] = useState<Coupon | null>(null);
    //#endregion

    //#region Form Fields Errors
    const [verificationCodeErrors, setVerificationCodeErrors] = useState("");
    const [nameOnCardErrors, setNameOnCardErrors] = useState("");
    const [cardErrors, setCardErrors] = useState(" ");
    const [otherErrors, setOtherErrors] = useState("");
    const [studentNameErrors, setStudentNameErrors] = useState("");
    const [countryErrors, setCountryErrors] = useState("");
    const [dateOfBirthErrors, setDateOfBirthErrors] = useState("");
    const [couponCodeErrors, setCouponCodeErrors] = useState("");
    const [teacherIdErrors, setTeacherErrors] = useState("");
    const [bootcampErrors, setBootcampErrors] = useState("");
    const [mainErrors, setMainErrors] = useState("");
    const [supportErrors, setSupportErrors] = useState("");
    const [sendEmailErrors, setSendEmailErrors] = useState("");
    //#endregion

    //#region Other
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: "16px",
                fontFamily: theme.typography.fontFamily?.toString(),
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: theme.palette.text.secondary
                },
                color: theme.palette.text.primary
            },
            invalid: {
                color: theme.palette.error.main,
                ":focus": {
                    color: theme.palette.error.light
                },
                ":hover": {
                    color: theme.palette.error.light
                }
            }
        }
    };

    const intersectTeachers = (times: Time[][]) => {
        let teacherSet = new Set<string>();

        // Find Overlapping Teachers
        for (let i = 0; i < times.length; i++) {
            let newTeacherSet = new Set<string>();

            for (const time of times[i]) {
                for (const teacher of time.teachers) {
                    if (teacherSet.has(teacher) || i === 0)
                        newTeacherSet.add(teacher);
                }
            }

            teacherSet = newTeacherSet;
        }

        return teacherSet;
    };

    const isStartWeekAllowed = (date: Date) => {
        let result = isDateAllowed(date);
        if (!result) return false;

        let nextWeek = new Date(date);
        nextWeek.setDate(nextWeek.getDate() + 7);
        if (overlapsEvents(nextWeek, holidayEvents)) return false;

        return true;
    };

    const isDateAllowed = (date: Date) => {
        const enrollWeekStartDate = setDay(ENROLL_START_DATE, 0);
        enrollWeekStartDate.setHours(0, 0, 0, 0);

        if (overlapsEvents(date, holidayEvents) || date.getTime() < enrollWeekStartDate.getTime()) return false;

        return true;
    };

    const onClickStartWeek = (date: Date) => {
        let start = new Date(date);
        start.setHours(9, 0, 0, 0);
        setStartWeek(setDay(start, 0));
    };

    const intersectTimes = (times: Time[][]) => {
        let timesSet = new Set<number>();

        // Find Overlapping Times
        for (let i = 0; i < times.length; i++) {
            let newTimesSet = new Set<number>();

            for (const time of times[i]) {
                if (timesSet.has(time.time) || i === 0)
                    newTimesSet.add(time.time);
            }

            timesSet = newTimesSet;
        }

        return timesSet;
    };

    const getTimes = useCallback((type: EventType) => {
        let times = selectedDays[type].map(day => sessions[type].find(session => {
            if (session.times.length === 0) return false;
            let date = new Date(session.dates[0]);
            date.setUTCHours(0, session.times[0].time, 0, 0);
            return (day === date.getDay());
        })?.times ?? []);

        if (selectedDays[type].length < EVENT_INFO[type].daysPerWeek) {
            times = sessions[type].map(day => day.times);
        }

        if (selectedTimes[type] !== -1) {
            times = times.map(times => times.filter(time => time.time === selectedTimes[type]));
        }

        let timeSet = intersectTimes(times);

        let output: Time[] = [];
        for (const time of Array.from(timeSet)) {
            let teacherSet = intersectTeachers(times.map(times => times.filter(timeObj => timeObj.time === time)));
            let teachers = Array.from(teacherSet);

            if (teachers.length > 0) {
                output.push({ time: time, teachers: teachers });
            }
        }

        return output;
    }, [selectedDays, selectedTimes, sessions]);

    const getRenewalStatus = useCallback(async () => {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/checkRenewal/${userId}`,
                {
                    method: "GET",
                    headers: {
                        "content-type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            switch (response.status) {
                case STATUS_CODES.OK:
                    const { renewalStatus } = await response.json();
                    setIsRenewal(renewalStatus);
                    return renewalStatus;

                default:
                    setIsRenewal(false);
                    return false;
            }
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            console.error("Network Error");
        }
    }, [errors.TRY_LATER, snackbar, token, userId]);
    //#endregion

    //#region Use Effects
    useEffect(() => {
        if (user && !hasRole(user, [Role.PARENT, Role.ADMIN])) {
            history.replace("/");
        }
    }, [history, user]);

    useEffect(() => {
        (async () => {
            try {
                let response = await fetch(`${process.env.REACT_APP_API_URL}/api/events`, {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        "authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({ holidayOnly: true })
                });

                switch (response.status) {
                    case STATUS_CODES.OK:
                        let data = await response.json();
                        let newEvents: CalendarEvent[] = data.events;

                        // Convert Dates
                        for (let i = 0; i < newEvents.length; i++) {
                            newEvents[i].start = new Date(newEvents[i].start);
                            newEvents[i].end = new Date(newEvents[i].end);
                        }

                        setHolidayEvents(newEvents);
                        break;

                    default:
                        snackbar.show(errors.TRY_LATER);
                        break;
                }

            } catch (error) {
                snackbar.show(errors.TRY_LATER);
                console.error("Network Error");
            }
        })();
    }, [errors, snackbar, token]);

    useEffect(() => {
        if (!user || !hasRole(user, [Role.PARENT])) {
            return;
        }

        if (version >= EnrollmentVersion.WEBINAR1 && version <= EnrollmentVersion.WEBINAR4) {
            const versionInfo = new Array(1).fill(VERSION_INFO[version]);
            setPackageList(versionInfo);
            setSelectedPackageIndex(0);

            if (version !== EnrollmentVersion.WEBINAR4) {
                setSessionWeeks([versionInfo[0].bootWeeks, versionInfo[0].mainWeeks, versionInfo[0].supWeeks]);
                setStep(Step.START_WEEK);
            }
            return;
        }

        (async () => {
            try {
                let isRenewal = await getRenewalStatus();

                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/packages?renewal=${+isRenewal}`,
                    {
                        method: "GET",
                        headers: {
                            "content-type": "application/json"
                        },
                    }
                );

                switch (response.status) {
                    case STATUS_CODES.OK:
                        const packages = await response.json();
                        setPackageList(packages);
                        return;

                    default:
                        snackbar.show(errors.TRY_LATER);
                        console.error("Call to api/packages failed");
                        return;
                }
            } catch (error) {
                snackbar.show(errors.TRY_LATER);
                console.error("Network Error: ", error);
            }
        })();
    }, [errors.TRY_LATER, getRenewalStatus, snackbar, user, version, step]);

    useEffect(() => {
        if (!user || !hasRole(user, [Role.PARENT]) || version !== EnrollmentVersion.WEBINAR4) {
            return;
        }

        if (user.verified) {
            if (step < Step.PAYMENT) {
                setStep(Step.PAYMENT);
            }
        } else {
            if (step < Step.VERIFY) {
                setStep(Step.VERIFY);
            }
        }
    }, [version, user, step]);

    useEffect(() => {
        let timeout = 0;

        setCoupon(null);
        setCouponCodeErrors("");

        if (couponCode.trim() !== "") {
            setIsCouponCodeLoading(true);
            timeout = setTimeout(async () => {
                const result = await fetch(`${process.env.REACT_APP_API_URL}/api/coupon`, {
                    method: "POST",
                    credentials: "omit",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({ code: couponCode })
                });

                setIsCouponCodeLoading(false);

                switch (result.status) {
                    case STATUS_CODES.OK:
                        setCoupon(await result.json());
                        setCouponCodeErrors("");
                        break;

                    case STATUS_CODES.NOT_FOUND:
                        setCouponCodeErrors(errors.INVALID_COUPON);
                        break;

                    default:
                        snackbar.show(errors.TRY_LATER);;
                        return setIsLoading(false);
                }
            }, 1000) as any;
        } else {
            setIsCouponCodeLoading(false);
        }

        return () => { clearTimeout(timeout); };
    }, [couponCode, errors, snackbar]);

    useEffect(() => {
        let timeout = 0;

        if (remainingCodeCooldown > 0) {
            timeout = setTimeout(() => { setRemainingCodeCooldown(remainingCodeCooldown - 1); }, 1000) as any;
        }

        return () => { clearTimeout(timeout); };
    }, [remainingCodeCooldown]);

    useEffect(() => {
        let newStart = new Date();
        newStart.setHours(9, 0, 0, 0);
        newStart = setDay(newStart, 0);
        let start = startWeek ?? newStart;

        if (!isStartWeekAllowed(start) || startWeek === null || startWeek.getDay() !== 0) {
            while (!isStartWeekAllowed(start)) start.setDate(start.getDate() + 7);
            onClickStartWeek(start);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startWeek]);

    useEffect(() => {
        let times = [getTimes(EventType.BOOTCAMP), getTimes(EventType.MAIN), getTimes(EventType.SUPPORT)];
        let teacherSet = intersectTeachers(times.filter((times, ind) => selectedDays[ind].length === EVENT_INFO[ind].daysPerWeek));

        if (!isNaN(teacherId)) {
            if (teacherSet.has(teacherId.toString())) {
                teacherSet = new Set();
                teacherSet.add(teacherId.toString());
            } else {
                teacherSet = new Set();
            }
        }

        setTimes(times.map(times => times.filter(time => {
            for (const teacher of time.teachers) {
                if (teacherSet.has(teacher)) return true;
            }
            return false;
        })));
    }, [selectedDays, sessions, selectedTimes, getTimes, teacherId]);
    //#endregion

    // re-enrolling students should not be charged registration fee 

    const registrationFee = isRenewal ? 0 : (packageList[selectedPackageIndex]?.registrationFee ?? REGISTRATION_FEE);

    //#region Click Handlers
    const onClickDay = (day: number, type: EventType) => {
        if (selectedDays[type].findIndex(date => date === day) !== -1) {
            if (type === EventType.BOOTCAMP && startWeek?.getDay() === day) return;

            setSelectedDays(selectedDays.map((tab, ind) => ind === type ? tab.filter(date => date !== day) : tab));
            setSelectedTimes(selectedTimes.map((tab, ind) => ind === type ? -1 : tab));
            return;
        }

        setSelectedDays(selectedDays.map((tab, ind) => ind === type ? [day, ...tab].slice(0, EVENT_INFO[type].daysPerWeek) : tab));
    };

    const onClickTime = (time: number, type: EventType) => {
        if (selectedTimes[type] === time) {
            setSelectedTimes(selectedTimes.map((tab, ind) => ind === type ? -1 : tab));
            return;
        }
        setSelectedTimes(selectedTimes.map((tab, ind) => ind === type ? time : tab));
    };

    const getBookings = useCallback((): Booking[] => {
        let times = [getTimes(EventType.BOOTCAMP), getTimes(EventType.MAIN), getTimes(EventType.SUPPORT)];
        let teacherSet = intersectTeachers(times.filter((times, ind) => selectedDays[ind].length === EVENT_INFO[ind].daysPerWeek));

        if (!isNaN(teacherId)) {
            if (teacherSet.has(teacherId.toString())) {
                teacherSet = new Set();
                teacherSet.add(teacherId.toString());
            } else {
                teacherSet = new Set();
            }
        }

        let teachers = Array.from(teacherSet.values());
        let teacher = teachers[Math.floor(Math.random() * teachers.length)];

        return selectedDays.flatMap((days, type) =>
            days.flatMap(day => {
                let session = sessions[type].find(session => {
                    if (session.times.length === 0) return false;
                    let date = new Date(session.dates[0]);
                    date.setUTCHours(0, session.times[0].time, 0, 0);
                    return (day === date.getDay());
                });

                if (!session) return [];

                return session.dates.flatMap(date => {
                    let datetime = new Date(date);
                    datetime.setUTCHours(0, selectedTimes[type], 0, 0);

                    return {
                        date: datetime,
                        type: type,
                        teacher: teacher
                    };
                });
            })
        );
    }, [getTimes, selectedDays, selectedTimes, sessions, teacherId]);

    const onNextStepClick = async () => {
        if (step + 1 > Step.POST_PAYMENT) return;

        if (step === Step.PAYMENT - 1 && nameOnCard === "") {
            changeNameOnCard(user ? user.name : "");
        }

        setOtherErrors("");

        let newStep = step + 1;

        // Admins don't need to see the packages
        if (newStep === Step.PACKAGES && hasRole(user, Role.ADMIN)) {
            newStep++;
        }

        if (newStep === Step.VERIFY && (user ? user.verified : false)) {
            newStep++;
        }

        if (newStep === Step.STUDENT_INFO && userId && getRole(userId) === Role.STUDENT) {
            newStep++;
        }

        while ((newStep === Step.BOOTCAMP && sessionWeeks[EventType.BOOTCAMP] === 0) ||
            (newStep === Step.MAIN && sessionWeeks[EventType.MAIN] === 0) ||
            (newStep === Step.SUPPORT && sessionWeeks[EventType.SUPPORT] === 0)) {
            newStep++;
        }

        if (newStep === Step.PAYMENT && hasRole(user, Role.ADMIN)) {
            setIsLoading(true);
            await enrollStudent();
            setIsLoading(false);
        } else {
            setStep(newStep);
        }
    };

    const onPreviousStepClick = () => {
        if (step - 1 < 0 || step === Step.POST_PAYMENT) return;

        setOtherErrors("");

        let newStep = step - 1;

        // Admins don't need to see the packages
        if (newStep === Step.PACKAGES && hasRole(user, Role.ADMIN)) {
            newStep--;
        }

        if (newStep === Step.VERIFY && (user ? user.verified : false)) {
            newStep--;
        }

        while ((newStep === Step.BOOTCAMP && sessionWeeks[EventType.BOOTCAMP] === 0) ||
            (newStep === Step.MAIN && sessionWeeks[EventType.MAIN] === 0) ||
            (newStep === Step.SUPPORT && sessionWeeks[EventType.SUPPORT] === 0)) {
            newStep--;
        }

        if (newStep === Step.STUDENT_INFO && userId && getRole(userId) === Role.STUDENT) {
            newStep--;
        }

        setStep(newStep);
    };

    const onPackageClick = (packageIndex: number) => {
        setSessionWeeks([packageList[packageIndex].bootWeeks, packageList[packageIndex].mainWeeks, packageList[packageIndex].supWeeks]);
        setSelectedPackageIndex(packageIndex);
        onNextStepClick();
    };
    //#endregion

    //#region Change event handlers
    const onCouponCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeCouponCode(event.target.value);
    };

    const changeCouponCode = (value: string) => {
        // Validators
        setCouponCode(value);
    };

    const onNameOnCardChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeNameOnCard(event.target.value);
    };

    const changeNameOnCard = (value: string) => {
        // Validators        
        setNameOnCardErrors("");
        setNameOnCard(value);
    };

    const onDateOfBirthChange = (value: unknown) => {
        changeDateOfBirth(value as Date);
    };

    const changeDateOfBirth = (value: Date | null) => {
        // Validators
        if (value == null) {
            setDateOfBirthErrors(errors.REQUIRED);
        } else {
            setDateOfBirthErrors("");
        }

        setDateOfBirth(value);
    };

    const onCountryChange = (event: SyntheticEvent, value: CountryType | null) => {
        changeCountry(value ? value.ind : null);
    };

    const changeCountry = (value: number | null) => {
        // Validators
        if (value === null) {
            setCountryErrors(errors.REQUIRED);
        } else {
            setCountryErrors("");
        }

        setCountry(value);
    };

    const onCardChange = (event: StripeCardElementChangeEvent) => {
        if (event.empty) {
            setCardErrors(errors.REQUIRED);
        } else if (event.error) {
            setCardErrors(event.error.message);
        } else if (!event.complete) {
            setCardErrors(errors.INCOMPLETE_CARD);
        } else {
            setCardErrors("");
        }
    };

    const onStudentNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeStudentName(event.target.value);
    };

    const changeStudentName = (value: string) => {
        // Validators
        if (value.trim().length === 0) {
            setStudentNameErrors(errors.REQUIRED);
        } else if (value.trim().length > 50) {
            setStudentNameErrors(errors.MAX_LENGTH_50);
        } else {
            setStudentNameErrors("");
        }

        setStudentName(value);
    };

    const onBootcampChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeBootcamp(event.target.value);
    };

    const changeBootcamp = (value: string) => {
        // Validators
        let count = parseInt(value);
        if (count < 0) count = 0;

        if (value.trim().length === 0) {
            setBootcampErrors(errors.REQUIRED);
        } else {
            setBootcampErrors("");
        }

        setSessionWeeks([count, sessionWeeks[1], sessionWeeks[2]]);
    };

    const onTeacherIdChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeTeacherId(event.target.value);
    };

    const changeTeacherId = (value: string) => {
        // Validators
        let id = parseInt(value);
        if (id < 1) id = 1;

        setTeacherErrors("");
        setTeacherId(id);
    };

    const onMainChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeMain(event.target.value);
    };

    const changeMain = (value: string) => {
        // Validators
        let count = parseInt(value);
        if (count < 0) count = 0;

        if (value.trim().length === 0) {
            setMainErrors(errors.REQUIRED);
        } else {
            setMainErrors("");
        }

        setSessionWeeks([sessionWeeks[0], count, sessionWeeks[2]]);
    };

    const onSupportChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSupport(event.target.value);
    };

    const changeSupport = (value: string) => {
        // Validators
        let count = parseInt(value);
        if (count < 0) count = 0;

        if (value.trim().length === 0) {
            setSupportErrors(errors.REQUIRED);
        } else {
            setSupportErrors("");
        }

        setSessionWeeks([sessionWeeks[0], sessionWeeks[1], count]);
    };

    const onSendEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeSendEmail(event.target.checked);
    };

    const changeSendEmail = (value: boolean) => {
        // Validators
        setSendEmailErrors("");
        setSendEmail(value);
    };

    const onVerifcationCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeVerifcationCode(event.target.value);
    };

    const changeVerifcationCode = (value: string) => {
        // Validators
        if (value.length === 0) {
            setVerificationCodeErrors(errors.REQUIRED);
        } else {
            setVerificationCodeErrors("");
        }

        setVerificationCode(value);
    };
    //#endregion

    //#region Validity Checks
    const isDayAllowed = (day: number, type: EventType) => {
        if (!WORKING_DAYS.includes(day)) return false;

        if (type === EventType.SUPPORT && selectedDays[EventType.MAIN].includes(day)) return false;
        if (type === EventType.MAIN && selectedDays[EventType.SUPPORT].includes(day)) return false;

        return true;
    };

    const isVerificationSubmitable = () => {
        if (isLoading || verificationCode.trim() === "") return false;
        return true;
    };

    const isCardSubmitable = () => {
        if (isLoading
            || nameOnCard.trim() === ""
            || nameOnCardErrors !== ""
            || cardErrors !== ""
            || countryErrors !== ""
            || country === 0
            || couponCodeErrors !== "") return false;
        return true;
    };

    const isStudentInfoSubmitable = () => {
        if (isLoading || studentName.trim() === "" || studentNameErrors !== "" || dateOfBirth === null) return false;
        return true;
    };

    const isSettingsSubmitable = () => {
        if (isLoading ||
            bootcampErrors !== "" ||
            mainErrors !== "" ||
            supportErrors !== "" ||
            sendEmailErrors !== "" ||
            teacherIdErrors !== "") return false;
        return true;
    };

    const isStartWeekSubmitable = () => {
        if (isLoading) return false;
        return true;
    };
    //#endregion

    //#region Submit Event Handlers
    const onPostPaymentSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if (version >= EnrollmentVersion.WEBINAR1 && version <= EnrollmentVersion.WEBINAR4 && step === Step.POST_PAYMENT) {
            setEnrollmentVersion(EnrollmentVersion.V2, true);
        }
        history.push("/calendar");
    };

    const trackTransaction = (cost: number, transactionId?: string) => {
        // Report enrollment data to Google Tag Manager.
        (window as any)?.gtag('event', 'conversion', {
            'send_to': 'AW-998470063/OmrgCMK3wPgCEK_jjdwD',
            'value': (cost / 100),
            'currency': 'USD',
            'transaction_id': transactionId ? transactionId : ""
        });
    };

    const onCardSubmit = async (event: FormEvent, stripe: Stripe | null, elements: StripeElements | null) => {
        event.preventDefault();

        // WEBINAR4 does not enroll student into any classes, its only an assessment package.
        if (coupon && country && version !== EnrollmentVersion.WEBINAR4) {
            const totalPrice = computePrice(version, registrationFee, coupon, country);

            if (totalPrice < 50) {
                await enrollStudent();
                trackTransaction(totalPrice);
                return;
            }
        }
        // Populate error messages
        changeCountry(country);
        changeNameOnCard(nameOnCard);
        if (cardErrors === " ") setCardErrors(errors.REQUIRED);

        if (!isCardSubmitable()) return;

        if (!stripe || !elements) return;

        const card = elements.getElement(CardElement);

        if (card === null) return;

        try {
            setIsLoading(true);

            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                    email: user ? user.email : "",
                    name: nameOnCard.trim(),

                },
            });

            if (paymentMethod.error) {
                setIsLoading(false);
                return setCardErrors(paymentMethod.error.message ?? "");
            }

            const paymentResult = await fetch(`${process.env.REACT_APP_API_URL}/api/enroll/pay`, {
                method: "POST",

                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    payment_method: paymentMethod.paymentMethod.id,
                    country: country,
                    couponCode: couponCode,
                    version: version,
                    registrationFee: registrationFee
                })
            });

            switch (paymentResult.status) {
                case STATUS_CODES.OK:
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    return setIsLoading(false);

                default:
                    snackbar.show(errors.TRY_LATER);
                    return setIsLoading(false);
            }

            const { client_secret, status, transactionId, cost } = await paymentResult.json();

            if (status === "requires_action" || status === "requires_confirmation") {
                let result = await stripe.confirmCardPayment(client_secret);

                if (result.error) {
                    setCardErrors(result.error.message ?? errors.TRY_LATER);
                    return setIsLoading(false);
                }
            }
            trackTransaction(cost, transactionId);

            if (version === EnrollmentVersion.WEBINAR4) {
                setStep(Step.POST_PAYMENT);
                setIsLoading(false);
                return;
            }

            await enrollStudent();
            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);;
            setIsLoading(false);
        }
    };

    const enrollStudent = async () => {
        try {
            const bookingResult = await fetch(`${process.env.REACT_APP_API_URL}/api/enroll`, {
                method: "POST",

                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    bookings: getBookings(),
                    studentName: studentName,
                    dateOfBirth: dateOfBirth,
                    version: version,
                    userId: userId,
                    sendEmail: sendEmail
                })
            });

            switch (bookingResult.status) {
                case STATUS_CODES.OK:
                    setStep(Step.POST_PAYMENT);
                    return true;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    return false;

                case STATUS_CODES.NOT_FOUND:
                    snackbar.show(errors.MISSING_USER);
                    return false;

                case STATUS_CODES.BAD_REQUEST:
                    snackbar.show(errors.MISSING_USER);
                    return false;

                case STATUS_CODES.CONFLICT:
                    snackbar.show(errors.TAKEN);
                    setStep(Step.START_WEEK);
                    return false;

                default:
                    snackbar.show(errors.TRY_LATER);
                    return false;
            }
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
        }
    };

    const onVerifySubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        // Populate error messages
        changeVerifcationCode(verificationCode);

        if (!isVerificationSubmitable()) return;

        try {
            setIsLoading(true);

            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify`, {
                method: "POST",

                headers: {
                    "content-type": "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    "code": verificationCode.trim()
                })
            });

            switch (response.status) {
                case STATUS_CODES.OK:
                    setVerificationCodeErrors("");
                    setToken((await response.json()).token);
                    onNextStepClick();
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    setVerificationCodeErrors(errors.INVALID_VERIFICATION_CODE);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            console.error("Network Error");
            setIsLoading(false);
        }
    };

    const onStudentNameSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        // Populate error messages
        changeStudentName(studentName);
        changeDateOfBirth(dateOfBirth);

        if (!isStudentInfoSubmitable()) return;
        onNextStepClick();
    };

    const onSettingsSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        // Populate error messages
        changeBootcamp(!isNaN(sessionWeeks[EventType.BOOTCAMP]) ? sessionWeeks[EventType.BOOTCAMP].toString() : "");
        changeMain(!isNaN(sessionWeeks[EventType.MAIN]) ? sessionWeeks[EventType.MAIN].toString() : "");
        changeSupport(!isNaN(sessionWeeks[EventType.SUPPORT]) ? sessionWeeks[EventType.SUPPORT].toString() : "");

        if (!isSettingsSubmitable()) return;
        onNextStepClick();
    };

    const onStartWeekSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        if (!isStartWeekSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/availability`, {
                method: "POST",

                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    startDate: startWeek,
                    version: version,
                    sessionWeeks: sessionWeeks
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    onNextStepClick();
                    let res = await result.json();
                    // Format dates
                    for (let type = 0; type < EventType.SUPPORT + 1; type++) {
                        for (const session of res[type]) {
                            session.dates = session.dates.map((date: string) => new Date(date));

                            // // FOR TESTING
                            // if (type === SessionType.BOOTCAMP && session.dates[0].getDay() === WORKING_DAYS[0]) {
                            //     session.times = session.times.slice(10);
                            // }
                        }
                    }

                    setSessions(res);
                    setSelectedTimes(new Array(EventType.SUPPORT + 1).fill(-1));
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Network Error");
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };

    const onGeneralSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onNextStepClick();
    };

    const onEventSubmit = (eventType: EventType) => {
        if (selectedDays[eventType].length < EVENT_INFO[eventType].daysPerWeek) {
            return setOtherErrors(errors.SELECT_DAYS);
        }

        if (selectedTimes[eventType] === -1) {
            return setOtherErrors(errors.SELECT_TIME);
        }

        onNextStepClick();
    };
    //#endregion

    //#region Other
    const getColor = (theme: Theme, type: EventType, color: string = "main"): string => {
        return (theme.palette as any)[`event${type}`][color];
    };

    const convertTimeToDate = (time: number) => {
        let end = new Date();
        end.setUTCHours(0, time, 0, 0);
        return end;
    };

    const isInStartWeek = (date: Date) => {
        if (!startWeek) return false;

        date.setHours(9, 0, 0, 0);

        let nextWeek = new Date(startWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        return date.getTime() >= startWeek.getTime() && date.getTime() < nextWeek.getTime();
    };

    useEffect(() => {
        const bookings = getBookings();
        const upcomingTimezoneDates = UPCOMING_TIMEZONE_CHANGE_DATES;
        setTimezoneChangeDate(null);

        for (const changeDate of upcomingTimezoneDates) {
            let isLess = false;
            let isMore = false;
            for (const booking of bookings) {
                if (booking.date >= changeDate) {
                    isMore = true;
                } else {
                    isLess = true;
                }
            }
            if (isMore && isLess) {
                setTimezoneChangeDate(changeDate);
                return;
            }
        }
    }, [getBookings]);

    // Send a new verification code and return true if there is an available code
    const resendVerificationCode = async () => {
        if (remainingCodeCooldown > 0) return false;

        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify/resend`,
                {
                    method: "POST",

                    headers: {
                        "content-type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                });

            switch (response.status) {
                case STATUS_CODES.OK:
                    setRemainingCodeCooldown(60);
                    return true;

                case STATUS_CODES.FORBIDDEN:
                    setVerificationCodeErrors(errors.RATE_LIMIT);
                    return true;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            console.error("Network Error");
        }

        return false;
    };
    //#endregion

    //#region HTML
    const getScheduleClassName = (date: Date) => {
        let bookings = getBookings();

        for (const booking of bookings) {
            if (booking.date.getFullYear() === date.getFullYear() &&
                booking.date.getMonth() === date.getMonth() &&
                booking.date.getDate() === date.getDate()
            ) {
                return SESSION_CLASS_NAMES[booking.type];
            }
        }
        return "";
    };

    const generateDayPicker = (type: EventType) => {
        return <ToggleButtonGroup
            value={selectedDays[type]}
            size="large"
            orientation={isMobile ? "vertical" : "horizontal"}
        >
            {
                new Array(7).fill("").map((day, ind) => {
                    return <ToggleButton
                        key={ind}
                        color={`event${type}` as any}
                        value={ind}
                        type="button"
                        sx={{ flex: 1 }}
                        onClick={() => { onClickDay(ind, type); }}
                        disabled={!isDayAllowed(ind, type)}
                    >
                        {format(setDay(new Date(), ind), "EEE").toUpperCase()}
                    </ToggleButton>;
                })
            }
        </ToggleButtonGroup>;
    };

    const generateTimePicker = (type: EventType) => {
        if (selectedDays[type].length !== EVENT_INFO[type].daysPerWeek) {
            return <Typography color="primary">{substitute(messages.TIME_PICKER.INCOMPLETE, { DAYS: (EVENT_INFO[type].daysPerWeek - selectedDays[type].length).toString() })}</Typography>;
        }

        if (times[type].length === 0) {
            return <Typography color="error">{messages.TIME_PICKER.UNAVAILABLE}</Typography>;
        }

        return <ToggleButtonGroup
            value={selectedTimes[type]}
            exclusive
            size="large"
            orientation="vertical"
        >
            {
                times[type].map(time => {
                    return <ToggleButton
                        key={time.time.toString()}
                        color={`event${type}` as any}
                        value={time.time}
                        type="button"
                        onClick={() => { onClickTime(time.time, type); }}>
                        {`${convertTimeToDate(time.time).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })} - ${convertTimeToDate(time.time + EVENT_INFO[type].duration).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`}
                    </ToggleButton>;
                })
            }
        </ToggleButtonGroup>;
    };

    const generateTimetableLegend = (type: EventType) => {
        let count = EVENT_INFO[type].daysPerWeek * sessionWeeks[type] * (type !== EventType.BOOTCAMP ? (packageList[selectedPackageIndex]?.sessionMultiplier || 1) : 1);

        return count === 0 ? null : <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
            <Typography sx={{
                minWidth: 40,
                maxWidth: 40,
                height: 40,
                lineHeight: "40px",
                borderRadius: "50%",
                textAlign: "center",
                background: (theme) => getColor(theme, type),
                color: (theme) => getColor(theme, type, "contrastText")
            }}>{count}</Typography>
            <Typography>
                {
                    substitute(messages.TIMETABLE.SESSION_TIMES[type], {
                        TIME: `${convertTimeToDate(selectedTimes[type]).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
                            } - ${convertTimeToDate(selectedTimes[type] + EVENT_INFO[type].duration).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`
                    })
                }
            </Typography>
        </Box>;
    };

    return (<>
        <Container sx={{ py: { xs: 0, sm: 0 }, px: { xs: 0, sm: 2 }, height: "100%", overflow: "auto" }} maxWidth="lg">
            <Box sx={{ position: "sticky", top: "0px", display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 }, zIndex: (theme) => theme.zIndex.drawer - 1 }}>
                <Toolbar />
                <WebinarAlert desktop={false} />
                <Paper sx={{ overflow: "hidden", borderTopLeftRadius: theme => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderTopRightRadius: theme => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, boxShadow: "none", borderBottom: theme => `1px solid ${theme.palette.divider}` }} elevation={theme.palette.mode !== "dark" ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                    <LinearProgress sx={{ height: (theme) => theme.spacing(2) }} variant="determinate" value={(step - (hasRole(user, Role.ADMIN) ? Step.SETTINGS : Step.PACKAGES)) / (Step.POST_PAYMENT - (hasRole(user, Role.ADMIN) ? Step.SETTINGS : Step.PACKAGES)) * 100} />
                    <Box sx={{ pr: 2, py: 2, display: "flex", flexDirection: "row" }}>
                        <Box bgcolor="primary.main" sx={{
                            borderTopRightRadius: (theme) => theme.shape.borderRadius,
                            borderBottomRightRadius: (theme) => theme.shape.borderRadius,
                            py: 1, px: 2,
                        }}>
                            <Typography variant="h6" color={(theme) => theme.palette.primary.contrastText} sx={{ fontWeight: "bold" }}>
                                {messages.STEP} {(step - (hasRole(user, Role.ADMIN) ? Step.SETTINGS : Step.PACKAGES) + 1).toString()}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }} />
                        {
                            ((hasRole(user, Role.PARENT) && step === Step.PACKAGES) || step === Step.SETTINGS || step === Step.POST_PAYMENT || version === EnrollmentVersion.WEBINAR4 || (version >= EnrollmentVersion.WEBINAR1 && version <= EnrollmentVersion.WEBINAR3 && step === Step.START_WEEK)) ? null :
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <IconButton aria-label="Previous" onClick={onPreviousStepClick}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                </Box>
                        }
                    </Box>
                </Paper>
            </Box>

            <Paper sx={{ flex: 1, display: "flex", flexDirection: "column", boxShadow: "none", borderBottomLeftRadius: theme => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderBottomRightRadius: theme => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0 }} elevation={theme.palette.mode !== "dark" ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                <Box sx={{ flex: 1, display: "flex", flexDirection: { xs: "column", md: step === Step.PACKAGES ? "column" : "row" } }}>
                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontWeight: "bold", p: 2, pb: 0 }} variant="h4">{messages.STEP_TITLES[step]}</Typography>
                        {
                            (step === Step.START_WEEK) &&
                            (<Typography sx={{ p: 2 }}>{messages.STEP_DESCRIPTIONS[step]}</Typography>)
                        }
                        { // Admins don't need to see the price on the left column
                            hasRole(user, Role.ADMIN) || (step !== Step.PAYMENT && step !== Step.START_WEEK) ? null :
                                <Pricing packageInfo={packageList[selectedPackageIndex]} registrationFee={registrationFee} country={country} coupon={coupon} sessionWeeks={sessionWeeks} userId={userId} />
                        }
                        {
                            step !== Step.VERIFY ? null :
                                <Typography sx={{ p: 2 }}>{substitute(messages.STEP_DESCRIPTIONS[step], { EMAIL: user ? user.email : "" })}</Typography>
                        }
                        {
                            (step === Step.PAYMENT || step === Step.VERIFY || step === Step.START_WEEK || messages.STEP_DESCRIPTIONS[step] === "") ? null :
                                <Typography sx={{ p: 2 }}>{messages.STEP_DESCRIPTIONS[step]}</Typography>
                        }
                        {
                            step !== Step.STUDENT_INFO ? null :
                                <Box component="img" draggable={false} src={paymentChild} alt="child playing game" sx={{
                                    maxHeight: 400,
                                    minHeight: 400,
                                    objectFit: "contain",
                                    objectPosition: 0,
                                    display: { xs: "none", md: "block" },
                                    userSelect: "none"
                                }} />
                        }
                        {
                            step !== Step.POST_PAYMENT ? null :
                                <Box component="img" draggable={false} src={postPaymentChild} alt="child with award" sx={{
                                    maxHeight: 400,
                                    objectFit: "contain",
                                    objectPosition: "50%",
                                    display: { xs: "none", md: "block" },
                                    userSelect: "none"
                                }} />
                        }
                        {
                            step !== Step.TIMETABLE ? null :
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
                                        {generateTimetableLegend(EventType.BOOTCAMP)}
                                        {generateTimetableLegend(EventType.MAIN)}
                                        {generateTimetableLegend(EventType.SUPPORT)}
                                    </Box>
                                    {timezoneChangeDate &&
                                        <Alert severity="warning" variant="outlined" sx={{ m: 2 }}>
                                            <AlertTitle>Warning</AlertTitle>
                                            {
                                                substitute(messages.TIMETABLE.WARNING, { DATE: format(timezoneChangeDate, "dd MMMM, yyyy") })
                                            }
                                        </Alert>}
                                </>

                        }
                        <Box sx={{ flex: 1 }} />
                        {step !== Step.START_WEEK ? null :
                            <Typography sx={{ fontWeight: "bold", p: 2 }}>{messages.START_WEEK.NOTE} <Link href="mailto:center@edutechnoz.com">{messages.CONTACT_US}</Link ></Typography>}
                        {step !== Step.POST_PAYMENT ? null :
                            <Typography sx={{ fontWeight: "bold", p: 2 }}>{messages.POST_PAYMENT.NOTE} <Link href="mailto:center@edutechnoz.com">{messages.CONTACT_US}</Link ></Typography>}
                        {!(step === Step.BOOTCAMP || step === Step.MAIN || step === Step.SUPPORT) ? null :
                            <Typography sx={{ fontWeight: "bold", p: 2 }}>{messages.TIME_PICKER.NOTE} <Link href="mailto:center@edutechnoz.com">{messages.CONTACT_US}</Link ></Typography>}
                        {step !== Step.TIMETABLE ? null :
                            <Typography sx={{ fontWeight: "bold", p: 2 }}>{messages.TIMETABLE.NOTE}</Typography>}
                    </Box>
                    {
                        step !== Step.SETTINGS ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onSettingsSubmit} noValidate>
                                <TextField
                                    fullWidth
                                    label={messages.SETTINGS.BOOTCAMP}
                                    value={!isNaN(sessionWeeks[EventType.BOOTCAMP]) ? sessionWeeks[EventType.BOOTCAMP] : ""}
                                    error={bootcampErrors !== ""}
                                    helperText={bootcampErrors}
                                    type="number"
                                    onChange={onBootcampChange}
                                />
                                <TextField
                                    fullWidth
                                    label={messages.SETTINGS.MAIN}
                                    value={!isNaN(sessionWeeks[EventType.MAIN]) ? sessionWeeks[EventType.MAIN] : ""}
                                    error={mainErrors !== ""}
                                    helperText={mainErrors}
                                    type="number"
                                    onChange={onMainChange}
                                />
                                <TextField
                                    fullWidth
                                    label={messages.SETTINGS.SUPPORT}
                                    value={!isNaN(sessionWeeks[EventType.SUPPORT]) ? sessionWeeks[EventType.SUPPORT] : ""}
                                    error={supportErrors !== ""}
                                    helperText={supportErrors}
                                    type="number"
                                    onChange={onSupportChange}
                                />
                                <TextField
                                    fullWidth
                                    label={messages.SETTINGS.TEACHER_ID}
                                    value={!isNaN(teacherId) ? teacherId : ""}
                                    error={teacherIdErrors !== ""}
                                    helperText={teacherIdErrors}
                                    type="number"
                                    onChange={onTeacherIdChange}
                                />
                                <FormControl required error={sendEmailErrors !== ""}>
                                    <FormControlLabel label={messages.SETTINGS.SEND_EMAIL} control={<Checkbox checked={sendEmail} onChange={onSendEmailChange} />} />
                                    <FormHelperText>{sendEmailErrors}</FormHelperText>
                                </FormControl>
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>
                    }
                    {
                        step === Step.PACKAGES && (
                            <Box sx={{ p: 2, flex: 1, display: "flex", gap: 2, flexWrap: "wrap", justifyContent: "center" }} component="div">
                                {packageList.map((packageInfo, ind) =>
                                    <Card key={packageInfo.id} sx={{ maxWidth: 300, borderRadius: 1 }} variant="outlined">
                                        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
                                            <Typography variant="h6">{packageInfo.name}</Typography>
                                            <Button sx={{ textTransform: "none", display: "flex" }} variant="contained" size="large" onClick={() => onPackageClick(ind)} disableElevation>
                                                {packageMessages.CTA}
                                            </Button>
                                            <Pricing packageInfo={packageInfo} registrationFee={registrationFee} country={country} coupon={coupon} sessionWeeks={[packageInfo.bootWeeks, packageInfo.mainWeeks, packageInfo.supWeeks]} userId={userId} />
                                        </CardContent>
                                    </Card>
                                )}
                            </Box>
                        )
                    }
                    {
                        step !== Step.START_WEEK ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onStartWeekSubmit} noValidate>
                                <Calendar
                                    className={`${styles.calendar} ${styles.startWeekCalendar}`}
                                    onClickDay={onClickStartWeek}
                                    calendarType="US"
                                    value={startWeek}
                                    minDate={new Date()}
                                    tileDisabled={(obj) => obj.view === "month" && !isStartWeekAllowed(obj.date)}
                                    tileClassName={(obj) => { if (obj.view !== "month") return ""; return `${isInStartWeek(obj.date) ? styles.selected : ""}`; }}
                                />
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" disabled={startWeek === null} loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>
                    }
                    {
                        (() => {
                            if (step < Step.BOOTCAMP || step > Step.SUPPORT) return null;

                            let eventType = step - Step.BOOTCAMP;
                            return <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={(event: FormEvent) => { event.preventDefault(); onEventSubmit(eventType); }} noValidate>
                                {generateDayPicker(eventType)}
                                {generateTimePicker(eventType)}
                                <Box sx={{ flex: 1 }} />
                                <Box sx={{ color: (theme) => theme.palette.error.main }}>{otherErrors}</Box>
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>;
                        })()
                    }
                    {
                        step !== Step.TIMETABLE ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onGeneralSubmit} noValidate>
                                <Calendar
                                    className={`${styles.calendar} ${styles.timetable}`}
                                    calendarType="US"
                                    value={startWeek}
                                    minDate={new Date()}
                                    tileDisabled={(obj) => obj.view === "month" && !isDateAllowed(obj.date)}
                                    tileClassName={(obj) => { if (obj.view !== "month") return ""; return getScheduleClassName(obj.date); }}
                                />
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>
                    }
                    {
                        step !== Step.VERIFY ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onVerifySubmit} noValidate>
                                <TextField
                                    fullWidth
                                    label={messages.VERIFY.CODE}
                                    value={verificationCode}
                                    error={verificationCodeErrors !== ""}
                                    helperText={verificationCodeErrors}
                                    autoComplete="one-time-code"
                                    onChange={onVerifcationCodeChange}
                                />
                                <Box>
                                    <Link component="button" variant="body1" disabled={remainingCodeCooldown > 0} type="button" onClick={resendVerificationCode}>
                                        {messages.VERIFY.RESEND} {remainingCodeCooldown > 0 ? `(${remainingCodeCooldown}s)` : ""}
                                    </Link>
                                </Box>
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>
                    }
                    {
                        step !== Step.STUDENT_INFO ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onStudentNameSubmit} noValidate>
                                <TextField
                                    fullWidth
                                    label={messages.STUDENT_INFO.NAME}
                                    value={studentName}
                                    error={studentNameErrors !== ""}
                                    helperText={studentNameErrors}
                                    autoComplete="name"
                                    onChange={onStudentNameChange}
                                />
                                <DatePicker
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    maxDate={new Date()}
                                    label={messages.STUDENT_INFO.DATE_OF_BIRTH}
                                    value={dateOfBirth}
                                    onChange={onDateOfBirthChange}
                                    renderInput={(params) => <TextField  {...params} error={dateOfBirthErrors !== ""} helperText={dateOfBirthErrors} />}
                                />
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.CONTINUE}
                                </LoadingButton >
                            </Box>
                    }
                    {
                        step !== Step.PAYMENT ? null :
                            <Elements stripe={STRIPE_PROMISE}>
                                <ElementsConsumer>
                                    {({ stripe, elements }) => (
                                        <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={(event: FormEvent) => { onCardSubmit(event, stripe, elements); }} noValidate>
                                            <TextField
                                                fullWidth
                                                label={messages.PAYMENT.NAME}
                                                value={nameOnCard}
                                                error={nameOnCardErrors !== ""}
                                                helperText={nameOnCardErrors}
                                                autoComplete="cc-name"
                                                onChange={onNameOnCardChange}
                                            />
                                            <Autocomplete
                                                fullWidth
                                                options={countries}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props}>
                                                        <Box
                                                            draggable="false"
                                                            component="img"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                            sx={{ userSelect: "none", mr: 2, flexShrink: 0 }}
                                                        />
                                                        {`${option.label} (${option.code})`}
                                                    </Box>
                                                )}
                                                value={country ? countries[country] : null}
                                                onChange={onCountryChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={messages.PAYMENT.COUNTRY}
                                                        error={countryErrors !== ""}
                                                        helperText={countryErrors}
                                                        autoComplete="country-name"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <FormControl fullWidth required error={cardErrors.trim() !== ""}>
                                                <Box sx={{
                                                    padding: 2.25,
                                                    border: cardFocused ? 2 : 1,
                                                    borderColor: (theme) => cardErrors.trim() !== "" ? theme.palette.error.main : (cardFocused ? theme.palette.primary.main : (theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)")),
                                                    borderRadius: 1,
                                                    ":hover": {
                                                        borderColor: (cardFocused || cardErrors.trim() !== "") ? null : (theme.palette.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.87)")
                                                    }
                                                }}>
                                                    <Box sx={{
                                                        border: cardFocused ? 0 : 1,
                                                        borderColor: "transparent",
                                                    }}>
                                                        <CardElement options={CARD_ELEMENT_OPTIONS} onFocus={() => { setCardFocused(true); }} onBlur={() => { setCardFocused(false); }} onChange={onCardChange} />
                                                    </Box>
                                                </Box>
                                                <FormHelperText>{cardErrors.trim()}</FormHelperText>
                                            </FormControl>
                                            {(version >= EnrollmentVersion.WEBINAR1 && version <= EnrollmentVersion.WEBINAR4) ? null :
                                                <TextField
                                                    fullWidth
                                                    label={pricingMessages.COUPON_CODE}
                                                    value={couponCode}
                                                    error={couponCodeErrors !== ""}
                                                    helperText={couponCodeErrors}
                                                    onChange={onCouponCodeChange}
                                                />
                                            }
                                            <Box sx={{ flex: 1 }} />
                                            <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" disabled={!stripe} loading={isLoading || isCouponLoading}>
                                                {messages.PAYMENT.CONTINUE}
                                            </LoadingButton >
                                        </Box>
                                    )}
                                </ElementsConsumer>
                            </Elements>
                    }
                    {
                        step !== Step.POST_PAYMENT ? null :
                            <Box sx={{ p: 2, flex: 1, minHeight: { xs: 0, md: 500 }, display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={onPostPaymentSubmit} noValidate>
                                {version === EnrollmentVersion.WEBINAR4 ? messages.POST_PAYMENT.ASSESSMENT_MESSAGE : messages.POST_PAYMENT.MESSAGE}
                                <Box sx={{ flex: 1 }} />
                                <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                    {messages.POST_PAYMENT.CLOSE}
                                </LoadingButton >
                            </Box>
                    }
                </Box>
            </Paper>
        </Container >
    </>
    );
    //#endregion
};

export default Component;
