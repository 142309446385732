import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import English from "../../../../shared/languages/en.json";
import Arabic from "../../../../shared/languages/ar.json"; 
import {getCookie} from "../../../../shared/utils"; 
import { STATUS_CODES } from "../../../../shared/types";


interface State {
    messages: typeof English | typeof Arabic;
    language: string;
}

const MessageContext = createContext<State>({ messages: English || Arabic, language: "en" || "ar"});

const Component: FunctionComponent = ({ children }) => {
    //#region State
    const [state, setState] = useState<State>({ messages: English || Arabic, language: "en" || "ar" });
    //#endregion

    useEffect(() => {

        let session = getCookie("PHPSESSID");

        if(session)
        {
            (async () => {
                let response = await fetch(`${process.env.REACT_APP_API_URL}/api/lang`, {

                    method: "POST", 
                    credentials: "omit",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        session: session,
                    })
                });

                switch (response.status) {
                    case STATUS_CODES.OK:                    
                        let data = await response.json(); 
                        switch (data) {
                            case "en": 
                                setState({messages: English, language: "en"});
                                break;  
                            case "ar": 
                                setState({messages: Arabic, language: "ar"}); 
                                break; 
                            default: 
                                setState({messages: English, language: "en"});
                                break; 
                        }
                        break; 
                    default: 
                        break; 
                }
                
            })();
        }
        else{
            setState({messages: English, language: "en"})
        }
    }, [setState])

    //#region HTML
    return (
        <MessageContext.Provider value={state}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {children}
            </LocalizationProvider>
        </MessageContext.Provider>
    );
    //#endregion
};

export default Component;

//#region Custom Hooks
export const useMessages = () => {
    return useContext(MessageContext).messages;
};
//#endregion

