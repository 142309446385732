import { FunctionComponent } from "react";
import { Role } from "../../../../shared/types";
import { hasRole } from "../../../../shared/utils";
import { useRequireAuth } from "../../components/auth";
import Default from "./default";
import School from "./school";

const Component: FunctionComponent = () => {
    const { user } = useRequireAuth();

    return (hasRole(user, Role.SCHOOL) ? <School /> : <Default />);
};

export default Component;