
//#region Scheduling
export const MIN_HOURS_BEFORE = 12;
export const TIMESLOT_DURATION = 20;
export const START_TIME = 20; // Keep compatible with exisiting times
export const END_TIME = 24 * 60;
const startDate = new Date(Date.now() + 12096e5); // Current date + 14 days
startDate.setHours(0, 0, 0, 0);
export const ENROLL_START_DATE = new Date(startDate);
export const WORKING_DAYS = [
    new Date(2021, 5, 29, 9, 0, 0, 0).getDay(),
    new Date(2021, 5, 30, 9, 0, 0, 0).getDay(),
    new Date(2021, 6, 1, 9, 0, 0, 0).getDay(),
    new Date(2021, 6, 3, 9, 0, 0, 0).getDay()
];

export const EVENT_INFO = [
    { duration: 30, daysPerWeek: 3, name: "Bootcamp" },
    { duration: 30, daysPerWeek: 1, name: "Main" },
    { duration: 15, daysPerWeek: 1, name: "Support" },
    { duration: 60, daysPerWeek: 1, name: "Co-Curricular" },
    { duration: 90, daysPerWeek: 1, name: "Group" },
    { duration: 1, daysPerWeek: 1, name: "Holiday" },
    { duration: 1, daysPerWeek: 1, name: "Personal" },
];
//#endregion

//#region Payment
export const REGISTRATION_FEE = 14987;
export const COUNTRY_CODES = ["US", "CANADA"];
export const CANADA_INDEX = 36;
//#endregion

//#region Enrollment Versions
export const VERSION_INFO = [{
    id: "1",
    title: "1",
    dbId: "online tutoring v1",
    discount: 0,
    complementaryCocurricularSessions: 3,
    cocurricularSessions: 0,
    tuitionFee: 108000,
    cocurricularFee: 0,
    sessionMultiplier: 1,
    specialOffer: 0,
    additionalYears: 1,
    freeSample: false,
},
{
    id: "2",
    title: "2",
    dbId: "online tutoring v2",
    discount: 0,
    complementaryCocurricularSessions: 3,
    cocurricularSessions: 0,
    tuitionFee: 108000,
    cocurricularFee: 0,
    sessionMultiplier: 1,
    specialOffer: 0,
    additionalYears: 1,
    freeSample: true,
},
{
    id: "3",
    title: "3",
    dbId: "online tutoring v3",
    discount: 0,
    complementaryCocurricularSessions: 3,
    cocurricularSessions: 0,
    tuitionFee: 108000,
    cocurricularFee: 0,
    sessionMultiplier: 1,
    specialOffer: 0,
    additionalYears: 1,
    freeSample: true,
},
{
    id: "webinar1",
    title: "e10",
    dbId: "easywebinar10",
    discount: 10,
    complementaryCocurricularSessions: 3,
    cocurricularSessions: 0,
    tuitionFee: 108000,
    cocurricularFee: 0,
    sessionMultiplier: 1,
    specialOffer: 0,
    additionalYears: 1,
    freeSample: false,
    bootWeeks: 2,
    mainWeeks: 10,
    supWeeks: 11
},
{
    id: "webinar2",
    title: "e20",
    dbId: "easywebinar20",
    discount: 0,
    complementaryCocurricularSessions: 6,
    cocurricularSessions: 0,
    tuitionFee: 213000,
    cocurricularFee: 0,
    sessionMultiplier: 2,
    specialOffer: 180000,
    additionalYears: 2,
    freeSample: false,
    bootWeeks: 2,
    mainWeeks: 10,
    supWeeks: 11
},
{
    id: "webinar3",
    title: "e3",
    dbId: "easywebinar100",
    discount: 0,
    complementaryCocurricularSessions: 6,
    cocurricularSessions: 6,
    tuitionFee: 213000,
    cocurricularFee: 9900,
    sessionMultiplier: 2,
    specialOffer: 189900,
    additionalYears: 2,
    freeSample: false,
    bootWeeks: 2,
    mainWeeks: 10,
    supWeeks: 11
},
{
    id: "webinar4",
    title: "e4",
    dbId: "easywebinar5",
    discount: 0,
    complementaryCocurricularSessions: 3,
    cocurricularSessions: 0,
    tuitionFee: 3500,
    cocurricularFee: 0,
    sessionMultiplier: 1,
    specialOffer: 0,
    additionalYears: 1,
    freeSample: false,
},
];
export const WEBINAR_TIME_LIMIT = 30 * 60;
export const WEBINAR_LOW_TIME = 2 * 60;
//#endregion

//#region Account
export const ACCOUNT_TYPE_NAMES = ["Parent", "Student", "Teacher", "Admin", "School"];
export const ACCOUNT_SEARCH_COUNT = 20;
export const DIRECTORY_SEARCH_COUNT = 12;
//#endregion

//#region Other
export const NOTIFICATION_INTERVAL = 5 * 60 * 1000;
//#endregion

// List of dates of DST changes
export const UPCOMING_TIMEZONE_CHANGE_DATES = [
    new Date(2022, 2, 13, 0, 0, 0, 0),
    new Date(2022, 10, 6, 0, 0, 0, 0),
    new Date(2023, 2, 12, 0, 0, 0, 0),
    new Date(2023, 10, 5, 0, 0, 0, 0),
];
