import jwt from "jsonwebtoken";
import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EnrollmentVersion, STATUS_CODES, User } from "../../../../shared/types";
import { getCookie } from "../../../../shared/utils";

interface State {
    user: User | null;
    token: string;
    setToken: (token: string) => void;
    isLoading: boolean;
}

const AuthContext = createContext<State>({ user: null, token: "", setToken: () => { }, isLoading: true });

const Component: FunctionComponent = ({ children }) => {
    //#region Hooks
    const setToken = useCallback((token: string) => {
        localStorage.setItem("token", token);

        if (token) {
            let user = jwt.decode(token, { json: true });
            if (user && user.exp && Math.floor(Date.now() / 1000) <= user.exp) {
                setState(state => { return { ...state, user: user as User, token: token, isLoading: false }; });
                return;
            }
        }

        setState(state => { return { ...state, user: null, token: "", isLoading: false }; });
    }, []);
    //#endregion

    //#region State
    const [state, setState] = useState<State>({ user: null, token: "", setToken, isLoading: true });
    //#endregion

    //#region Use Effects
    useEffect(() => {
        let session = getCookie("PHPSESSID");
        let token = localStorage.getItem("token");

        if (!session) token = "";

        setToken(token ?? "");

        if (token) { return; }

        if (session) {
            (async () => {
                let result = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                    method: "POST",
                    credentials: "omit",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        session: session,
                        version: EnrollmentVersion.V2
                    })
                });

                switch (result.status) {
                    case STATUS_CODES.OK:
                        setToken((await result.json()).token);
                        break;

                    default:
                        break;
                }
            })();
        }
    }, [setToken]);
    //#endregion

    //#region HTML
    return (
        <AuthContext.Provider value={state}>
            {children}
        </AuthContext.Provider>
    );
    //#endregion
};

export default Component;

//#region Custom Hooks
export const useAuth = () => {
    return useContext(AuthContext);
};

export const useRequireAuth = () => {
    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (auth.user === null && !auth.isLoading) {
            history.replace({ pathname: document.referrer === `${process.env.REACT_APP_LEGACY_URL}/online-tutoring/` ? "/register" : "/login", state: { redirect: history.location } });
        } else if (auth.user && !auth.isLoading && !auth.user.verified && history.location.pathname !== "/enroll") {
            history.replace({ pathname: "/verify", state: { redirect: history.location } });
        }
    }, [auth, history]);

    return auth;
};
//#endregion