import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { addDays, format, getMonth, isSameDay, setDay, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";
import { FunctionComponent } from "react";

interface Props {
    onDayClick: (date: Date) => void;
    date: Date;
}

const Component: FunctionComponent<Props> = ({ onDayClick, date }) => {
    let startDate = setDay(setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0), 0);
    let days = [
        <Box key="spacer"
            sx={{
                display: "flex", flexDirection: "row",
                minWidth: "60px",
                px: 2,
                py: 1
            }} />
    ];

    const generateDay = (startDate: Date) => {
        return <Box key={startDate.toISOString()} sx={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "center", pb: 0.5 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {format(startDate, 'EEE')}
            </Typography>
            <Button disableElevation variant={isSameDay(startDate, new Date()) ? "contained" : "text"} color={isSameDay(startDate, new Date()) ? "event2" : "inherit"} sx={{
                py: 0.25,
                px: 1,
                borderRadius: theme => theme.spacing(3),
                fontSize: theme => theme.typography.h6.fontSize,
                textTransform: "none",
                minWidth: "2em"
            }} onClick={() => { onDayClick(startDate); }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: theme => isSameDay(startDate, new Date()) ? undefined : (getMonth(startDate) !== getMonth(date) ? theme.palette.action.disabledBackground : theme.palette.action.active) }}>
                    {format(startDate, "d")}
                </Typography>
            </Button>
        </Box>;
    };

    for (let i = 0; i < 7; i++) {
        days.push(generateDay(startDate));
        startDate = addDays(startDate, 1);
    }

    return <Box sx={{ display: "flex", flexDirection: "row" }}>
        {days}
    </Box>;
};

export default Component;