import { FunctionComponent } from "react";
import { Container } from "@mui/material";

import { useParams } from "react-router-dom";
import { useAuth } from "../../components/auth";
import { Role } from "../../../../shared/types";
import { getAccountTableId, hasRole } from "../../../../shared/utils";

const urls: any = {
  Teacher: {
    profileinfo: "https://edutechnoz.com/iSchools/Subscribed/Teachers/ModifyProfile_Teacher.php",
    profilepic: "https://edutechnoz.com/iSchools/Subscribed/Teachers/ModifyAvatar.php",
    password:  "https://edutechnoz.com/iSchools/Subscribed/Teachers/ModifyPassword.php?ID=$Teacher_ID&Type=Teacher",
    studentsprofiles: "https://edutechnoz.com/iSchools/Subscribed/Teachers/StudentsProfile.php?ID=$Teacher_ID&Type=Teacher",
    recommend: "https://edutechnoz.com/iSchools/Subscribed/Teachers/Recommend.php",
    recommendstatus: "https://edutechnoz.com/iSchools/Subscribed/Teachers/RecGamesStatus.php",
    studentsreport: "https://edutechnoz.com/iSchools/Subscribed/Teachers/Filter.php",
    teachersreport: "https://edutechnoz.com/iSchools/Subscribed/Teachers/Report.php",
    main: "https://edutechnoz.com/iSchools/Subscribed/Teachers/index.php"
  },
  School: {
    schoolprofile: "https://edutechnoz.com/iSchools/Subscribed/ModifyProfile.php",
    profilepic: "https://edutechnoz.com/iSchools/Subscribed/ModifyAvatar.php",
    password:  "https://edutechnoz.com/iSchools/Subscribed/ModifyPassword.php",
    studentsprofiles: "https://edutechnoz.com/iSchools/Subscribed/StudentsProfile.php",
    studentsreport: "https://edutechnoz.com/iSchools/Subscribed/Filter.php",
    teachersreport: "https://edutechnoz.com/iSchools/Subscribed/TeacherFilter.php ",
    classesreport: "https://edutechnoz.com/iSchools/Subscribed/Filter.php",
    main: "https://edutechnoz.com/iSchools/Subscribed/index.php",
    teachersprofiles: "https://edutechnoz.com/iSchools/Subscribed/Teachers/ModifyProfile.php"
  }
};

const Component: FunctionComponent = () => {
  const { type } = useParams<{ type: string }>();
  const { user } = useAuth();
  const userRole = hasRole(user, Role.TEACHER) ? "Teacher" : "School";

  const queryParams = user ? `?ID=${getAccountTableId(user.id)}&Type=${userRole}` : "";

  return user && urls[userRole][type] ? (
    <Container sx={{ pt: 9, px: { xs: 0, sm: 2 } }}>
      <iframe
        src={`${urls[userRole][type]}${queryParams}`}
        title="Iframe content"
        style={{ width: "100%", border: "none", minHeight: "calc(100vh - 80px)" }}
      ></iframe>
    </Container>
  ) : (
    <Container sx={{ pt: 9, px: { xs: 0, sm: 2 } }}>
      <p>Page not found</p>
    </Container>
  );
};

export default Component;
