import { CANADA_INDEX, UPCOMING_TIMEZONE_CHANGE_DATES, VERSION_INFO } from "./config";
import { Coupon, EnrollmentVersion, Role, User, UserSummary } from "./types";

//#region Account
export const getAccountId = (id: number, type: Role): Role => {
    return id + type * Math.pow(2, 32);
};

export const getAccountTableId = (id: number) => {
    return Math.floor(id % Math.pow(2, 32));
};

export const getRole = (id: number): Role => {
    return Math.floor(id / Math.pow(2, 32));
};

export const hasRole = (user: User | UserSummary | null, roles: Role | Role[]) => {
    if (!user) { return false; }

    if (Array.isArray(roles)) {
        for (let i = 0; i < roles.length; i++) {
            if (getRole(user.id) === roles[i]) {
                return true;
            }
        }
        return false;
    }
    return getRole(user.id) === roles;
};

export const getAccountColor = (id: number) => {
    return `hsla(${((getAccountTableId(id) * 20) % 263) + 96}, 50%, ${((getAccountTableId(id) * 13) % 41) + 10}%, 0.75)`;
};
//#endregion

//#region Cookies
export const getCookie = (c_name: string) => {
    let c_value: string | null = " " + document.cookie;
    let c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start === -1) {
        c_value = null;
    }
    else {
        c_start = c_value.indexOf("=", c_start) + 1;
        let c_end = c_value.indexOf(";", c_start);
        if (c_end === -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
};

export const setCookie = (name: string, value: string, domain: string = ".edutechnoz.com") => {
    document.cookie = `${name}=${value}; domain=${domain}; Path=/;`;
};

export const deleteCookie = (name: string, domain: string = ".edutechnoz.com") => {
    document.cookie = `${name}=; domain=${domain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
//#endregion

//#region Other
export const roundUpToNearest = (value: number, interval: number) => {
    return Math.ceil(value / interval) * interval;
};

export const formatTime = (time: number) => {
    let seconds = Math.floor(time % 60);
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));

    return `${hours.toFixed().padStart(2, "0")}:${minutes.toFixed().padStart(2, "0")}:${seconds.toFixed().padStart(2, "0")}`;
};

export const substitute = (value: string, mappings: Record<string, string>) => {
    return value.replace(new RegExp(Object.keys(mappings).map(value => `\\$\\$${value}\\$\\$`).join("|"), "g"), (matched) => mappings[matched.slice(2, matched.length - 2)]);
};

export const applyWebinarDiscount = (price: number, version: EnrollmentVersion) => {
    if (version === EnrollmentVersion.WEBINAR4) return VERSION_INFO[version].tuitionFee;
    return price - (price * VERSION_INFO[version].discount / 100);
};

export const discountPrice = (price: number, coupon: Coupon | null, version: EnrollmentVersion) => {
    price = applyWebinarDiscount(price, version);
    if (!coupon) return price;
    return coupon.percent ? (price - (price * coupon.amount / 100)) : (price - coupon.amount);
};

export const computePrice = (version: EnrollmentVersion, registrationFee: number, coupon: Coupon | null, country: number | null) => {
    return Math.floor(
        discountPrice(VERSION_INFO[version].specialOffer ? VERSION_INFO[version].specialOffer : (registrationFee + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee), coupon, version) +
        (country === CANADA_INDEX ? Math.floor(discountPrice(VERSION_INFO[version].specialOffer ? VERSION_INFO[version].specialOffer : (registrationFee + VERSION_INFO[version].tuitionFee + VERSION_INFO[version].cocurricularFee), coupon, version) * 0.13) : 0)
    );
};

export const getUpcomingTimezoneChangeDate = () => {
    const currDate = new Date();
    for (const date of UPCOMING_TIMEZONE_CHANGE_DATES) {
        if (date >= currDate) {
            return date;
        }
    }

    return UPCOMING_TIMEZONE_CHANGE_DATES[UPCOMING_TIMEZONE_CHANGE_DATES.length - 1];
};

//#endregion
