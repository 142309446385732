import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertProps, IconButton, Theme, useMediaQuery } from "@mui/material";
import React, { FunctionComponent } from "react";
import { WEBINAR_LOW_TIME } from "../../../../shared/config";
import { EnrollmentVersion } from "../../../../shared/types";
import { formatTime, substitute } from "../../../../shared/utils";
import { useEnrollmentVersion } from "../enrollmentVersion";
import { useMessages } from "../messages";

interface Props extends AlertProps {
    mobile?: boolean;
    desktop?: boolean;
}

const Component: FunctionComponent<Props> = ({ mobile = true, desktop = true, ...props }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const { NAVBAR: navbarMessages } = useMessages();
    const { remainingTime, setEnrollmentVersion } = useEnrollmentVersion();

    return !(remainingTime !== null && (isMobile ? mobile : desktop)) ? null :
        <Alert severity={remainingTime < WEBINAR_LOW_TIME ? "error" : "warning"} {...props} sx={{ fontWeight: "bold", ...props.sx }}
            action={
                remainingTime > 0 ? null :
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => { setEnrollmentVersion(EnrollmentVersion.V2, true); }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
            }
        >
            {remainingTime === 0 ? navbarMessages.OUT_OF_TIME : substitute(navbarMessages.REMAINING_TIME, { AMOUNT: formatTime(remainingTime) })}
        </Alert>;
};

export default Component;