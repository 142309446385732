import { FunctionComponent, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { STATUS_CODES } from "../../../../shared/types";
import { useParams } from "react-router-dom";

const Component: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/subscription/cancel`,
          {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              subscriptionId: subscriptionId,
            }),
          }
        );

        switch (response.status) {
          case STATUS_CODES.OK:
            const { endDate } = await response.json();
            setLoading(false);
            setMessage(
              `Your subscription has been canceled successfully. You can still access the product until: ${endDate}`
            );
            break;

          case STATUS_CODES.NOT_FOUND:
            setLoading(false);
            setMessage(`This subscription does not exist.`);
            break;

          default:
            setLoading(false);
            setMessage(
              `We were unable to cancel your subscription. Please try again later.`
            );
            break;
        }
      } catch (error) {
        setLoading(false);
        setMessage(
          `We were unable to cancel your subscription. Please try again later.`
        );
        console.error("Network Error: ", error);
      }
    })();
  }, [subscriptionId]);

  return loading ? (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={80} />
    </Box>
  ) : (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", maxWidth: "500px" }}>
        {message}
      </Typography>
    </Box>
  );
};

export default Component;
