import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DateTimePicker, LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardActionArea, CircularProgress, Container, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { addDays, addHours, addMonths, differenceInHours, format, getDay, getHours, getMinutes, isBefore, setDate as setDateObjDate, setDay, setHours, setMilliseconds, setMinutes, setSeconds, subDays, subHours, subMinutes, subMonths } from "date-fns";
import { saveAs } from "file-saver";
import { createEvents, EventAttributes } from "ics";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACCOUNT_TYPE_NAMES, EVENT_INFO, MIN_HOURS_BEFORE } from "../../../../shared/config";
import { Availability, CalendarEvent, EventType, Role, STATUS_CODES, UserSummary } from "../../../../shared/types";
import { getAccountColor, getAccountTableId, getRole, hasRole } from "../../../../shared/utils";
import { useRequireAuth } from "../../components/auth";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";
import { generateTeacherView, getDates, Slot } from "../../scheduling";
import DayCalendar from "./day";
import MonthCalendar from "./month";
import ScheduleCalendar from "./schedule";
import WeekCalendar from "./week";
import WeekCalendarHeader from "./week/header";


enum CalendarView {
    DAY,
    WEEK,
    MONTH,
    SCHEDULE,
}

interface Props {
    drawerWidth: string,
    isDrawerOpen: boolean;
}

const Component: FunctionComponent<Props> = ({ drawerWidth, isDrawerOpen }) => {
    //#region Hooks
    const theme = useTheme();
    const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const history = useHistory();
    const { CALENDAR: messages, ERRORS: errors } = useMessages();
    const { user, token } = useRequireAuth();
    const snackbar = useSnackbar();
    const { id: idString } = useParams<{ id?: string; }>();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [allEvents, setAllEvents] = useState<CalendarEvent[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<number>(0);
    const [availabilities, setAvailabilities] = useState<Availability[]>([]);
    const [rescheduleDates, setRescheduleDates] = useState<CalendarEvent[]>([]);
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState(CalendarView.MONTH);
    const [users, setUsers] = useState<UserSummary[]>([]);
    const [numAPICalls, setNumAPICalls] = useState(0);
    const [showNewFeatures, setShowNewFeatures] = useState(false);
    const [showNoChildren, setShowNoChildren] = useState(false);
    const [shownModal, setShownModal] = useState(false);
    //#endregion

    // const hideCalendar = user && (hasRole(user, Role.SCHOOL) || (hasRole(user, Role.TEACHER) && user.schoolId !== 336));
    const userId = !idString ? 0 : (parseInt(idString) ? parseInt(idString) : 0);

    const downloadReminders = () => {
        let icsEvents: EventAttributes[] = [];
        for (const event of events) {
            if (event.type !== EventType.BOOTCAMP &&
                event.type !== EventType.MAIN &&
                event.type !== EventType.SUPPORT &&
                event.type !== EventType.CO_CURRICULAR &&
                event.type !== EventType.GROUP) {
                continue;
            }

            let icsEvent: EventAttributes = {
                start: [event.start.getFullYear(), event.start.getMonth() + 1, event.start.getDate(), event.start.getHours(), event.start.getMinutes()],
                duration: { minutes: EVENT_INFO[event.type].duration },
                title: `Edutechnoz ${EVENT_INFO[event.type].name}`
            };

            let reminder1Date = subMinutes(event.start, 10);
            let reminder1: EventAttributes = {

                start: [reminder1Date.getFullYear(), reminder1Date.getMonth() + 1, reminder1Date.getDate(), reminder1Date.getHours(), reminder1Date.getMinutes()],
                duration: { minutes: 1 },
                title: `Edutechnoz ${EVENT_INFO[event.type].name} 10m Reminder`,
            };

            let reminder2Date = subHours(event.start, 2);
            let reminder2: EventAttributes = {
                startOutputType: "local",
                start: [reminder2Date.getFullYear(), reminder2Date.getMonth() + 1, reminder2Date.getDate(), reminder2Date.getHours(), reminder2Date.getMinutes()],
                duration: { minutes: 1 },
                title: `Edutechnoz ${EVENT_INFO[event.type].name} 2h Reminder`,
            };

            let reminder3Date = subHours(event.start, 24);
            let reminder3: EventAttributes = {
                startOutputType: "local",
                start: [reminder3Date.getFullYear(), reminder3Date.getMonth() + 1, reminder3Date.getDate(), reminder3Date.getHours(), reminder3Date.getMinutes()],
                duration: { minutes: 1 },
                title: `Edutechnoz ${EVENT_INFO[event.type].name} 24h Reminder`,
            };

            icsEvents.push(icsEvent);
            icsEvents.push(reminder1);
            icsEvents.push(reminder2);
            icsEvents.push(reminder3);
        }

        if (icsEvents.length === 0) {
            snackbar.show(errors.MISSING_EVENTS);
            return;
        }

        const { error, value } = createEvents(icsEvents);

        if (error || !value) {
            snackbar.show(errors.TRY_LATER);
            console.error(error);
        } else {
            saveAs(new Blob([value], { type: "text/calendar;charset=utf-8" }), "calendar.ics");
            localStorage.setItem("downloadedReminders", "true");

            if (user && hasRole(user, [Role.PARENT, Role.STUDENT])) {
                fetch(`${process.env.REACT_APP_API_URL}/api/reminders`, {
                    method: "POST",
                    credentials: "omit",
                    headers: {
                        "content-type": "application/json",
                        "authorization": `Bearer ${token}`,
                    }
                }).then(() => { });
            }
        }
    };

    //#region Use Effects
    useEffect(() => {
        if (users.length > 0) {
            if (users.findIndex(user => user.id === userId && hasRole(user, [Role.TEACHER, Role.STUDENT])) === -1) {
                history.replace({ pathname: "/calendar", search: history.location.search });
            }
        }
    }, [userId, users, history]);

    // useEffect(() => {
    //     if (hideCalendar) {
    //         history.replace("/i/main");
    //     }
    // }, [hideCalendar, history]);

    useEffect(() => {
        // if (hideCalendar || shownModal) return;
        if (shownModal) return;

        if (events.length > 0) {
            setShownModal(true);

            if (events.findIndex(event => isBefore(new Date(), event.start) && event.type !== EventType.HOLIDAY && event.type !== EventType.PERSONAL) === -1) {
                return setShowNoChildren(true);
            }

            if (!localStorage.getItem("downloadedReminders") && hasRole(user, [Role.PARENT, Role.STUDENT, Role.TEACHER])) {
                return setShowNewFeatures(true);
            }
        }

        setShowNewFeatures(false);
        setShowNoChildren(false);
    }, [events, user, shownModal]);

    useEffect(() => {
        // if (!token || (user && !user.verified) || hideCalendar) return;
        if (!token || (user && !user.verified)) return;

        (async () => {
            try {
                setEvents([]);

                let startKey = 0;
                let users = [];
                do {
                    let response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
                        method: "POST",

                        headers: {
                            "content-type": "application/json",
                            "authorization": `Bearer ${token}`
                        },
                        body: JSON.stringify({ search: "", startKey: startKey, teachersOnly: true })
                    });

                    switch (response.status) {
                        case STATUS_CODES.OK:
                            let data = await response.json();
                            users.push(...data.data);
                            startKey = data.startKey;
                            break;

                        case STATUS_CODES.UNAUTHORIZED:
                            return snackbar.show(errors.UNAUTHORIZED);

                        default:
                            return snackbar.show(errors.TRY_LATER);
                    }
                } while (startKey);
                setNumAPICalls(prevNumCalls => prevNumCalls + 1);
                setUsers([...users.filter(user => hasRole(user, Role.TEACHER)), ...users.filter(user => !hasRole(user, Role.TEACHER))]);
            } catch (error) {
                snackbar.show(errors.TRY_LATER);
                console.error("Network Error");
            }
        })();
    }, [token, snackbar, errors, user]);

    useEffect(() => {
        // if (!token || (user && !user.verified) || hideCalendar) return;
        if (!token || (user && !user.verified)) return;

        (async () => {
            try {
                if (allEvents.length > 0 || availabilities.length > 0) return;

                let response = await fetch(`${process.env.REACT_APP_API_URL}/api/events`, {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                });
                setNumAPICalls(prevNumCalls => prevNumCalls + 1);
                switch (response.status) {
                    case STATUS_CODES.OK:
                        let data = await response.json();
                        let newEvents: CalendarEvent[] = data.events;
                        let newAvailabilities: Availability[] = data.availabilities;

                        // Convert Dates
                        for (let i = 0; i < newEvents.length; i++) {
                            newEvents[i].start = new Date(newEvents[i].start);
                            newEvents[i].end = new Date(newEvents[i].end);
                        }

                        for (let i = 0; i < newAvailabilities.length; i++) {
                            newAvailabilities[i].start_date = new Date(newAvailabilities[i].start_date);
                            newAvailabilities[i].end_date = new Date(newAvailabilities[i].end_date);
                        }

                        if (newEvents.length > 0 || allEvents.length > 0) {
                            setAllEvents(newEvents);
                        }

                        if (newAvailabilities.length > 0 || availabilities.length > 0) {
                            setAvailabilities(newAvailabilities);
                        }
                        break;

                    default:
                        snackbar.show(errors.TRY_LATER);
                        break;
                }

            } catch (error) {
                snackbar.show(errors.TRY_LATER);
                console.error("Network Error");
            }
        })();
    }, [token, allEvents, availabilities, snackbar, errors, user]);

    useEffect(() => {
        setEvents(allEvents.filter(event => !event.owner.id || users.findIndex(account =>
            (
                event.attendees.findIndex(attendee => attendee.id === account.id) !== -1 ||
                account.id === event.owner.id
            ) &&
            (userId ? userId === account.id : true)
        ) !== -1
        ));
    }, [users, allEvents, userId]);
    //#endregion

    //#region Click Event Handlers
    const onEventClick = async (event: CalendarEvent) => {
        if (rescheduleDates.length === 0) {
            setSelectedEvent(event.id);

            // If organizer is edutechnoz, there's no need to fetch meeting link.
            if (getAccountTableId(event.owner.id) === 0) {
                return;
            }

            try {
                let result = await fetch(`${process.env.REACT_APP_API_URL}/api/meeting/${event.owner.id}`, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                });

                switch (result.status) {
                    case STATUS_CODES.OK:
                        let data = await result.json();

                        let eventInd = events.findIndex(e => e.id === event.id);
                        let newDescription = "";
                        if (newDescription) {
                            newDescription += "\n";
                        }

                        if (data.zoomLink) {
                            newDescription += `Zoom Link: ${data.zoomLink}\n`;
                        }
                        if (data.zoomId) {
                            newDescription += `Meeting ID: ${data.zoomId}\n`;
                        }
                        if (data.zoomPassword) {
                            newDescription += `Password: ${data.zoomPassword}`;
                        }

                        setEvents(oldEvents => [...oldEvents.slice(0, eventInd), { ...oldEvents[eventInd], description: newDescription }, ...oldEvents.slice(eventInd + 1)]);
                        break;

                    case STATUS_CODES.UNAUTHORIZED:
                        snackbar.show(errors.UNAUTHORIZED);
                        break;

                    case STATUS_CODES.NOT_FOUND:
                        break;

                    default:
                        snackbar.show(errors.TRY_LATER);
                        break;
                }
            } catch (err) {
                snackbar.show(errors.TRY_LATER);
            }
        }
    };

    const onSlotClick = async (slot: Slot) => {
        if (isLoading) return;

        try {
            setIsLoading(true);

            let starts = getDates(slot.start, rescheduleDates[0].type, rescheduleDates.length, allEvents.filter(event => event.type === EventType.HOLIDAY));
            if (starts.length !== rescheduleDates.length) return;

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/reschedule`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "content-type": "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    changes: rescheduleDates.map((booking, ind) => {
                        return {
                            id: booking.id,
                            start: starts[ind]
                        };
                    })
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    setRescheduleDates([]);
                    setAllEvents([]);
                    setAvailabilities([]);
                    snackbar.show(messages.RESCHEDULESUCCESS);
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };

    const onPrevClick = () => {
        if (view === CalendarView.MONTH || view === CalendarView.SCHEDULE) {
            setDate(subMonths(date, 1));
        } else if (view === CalendarView.DAY) {
            setDate(subDays(date, 1));
        } else if (view === CalendarView.WEEK) {
            setDate(subDays(date, 7));
        }
    };

    const onNextClick = () => {
        if (view === CalendarView.MONTH || view === CalendarView.SCHEDULE) {
            setDate(addMonths(date, 1));
        } else if (view === CalendarView.DAY) {
            setDate(addDays(date, 1));
        } else if (view === CalendarView.WEEK) {
            setDate(addDays(date, 7));
        }
    };
    //#endregion

    //#region Other
    const rescheduleBooking = (id: number, remainder: boolean) => {
        let booking = allEvents.find(booking => booking.id === id);
        if (!booking) { return; }

        if (differenceInHours(booking.start, new Date()) < MIN_HOURS_BEFORE) {
            return snackbar.show(errors.MIN_HOURS_48);
        }

        let newRescheduleBookings: CalendarEvent[] = [];

        for (let i = 0; i < allEvents.length; i++) {
            let tempBooking = booking;

            if (allEvents[i].id === tempBooking.id || (
                remainder &&
                isBefore(tempBooking.start, allEvents[i].start) &&
                allEvents[i].type === tempBooking.type &&
                allEvents[i].attendees.filter(attendee => tempBooking.attendees.findIndex(a => a.id === attendee.id) !== -1).length > 0 &&
                allEvents[i].owner.id === tempBooking.owner.id &&
                getDay(allEvents[i].start) === getDay(tempBooking.start) &&
                getMinutes(allEvents[i].start) === getMinutes(tempBooking.start) &&
                getHours(allEvents[i].start) === getHours(tempBooking.start)
            )) {
                newRescheduleBookings.push(allEvents[i]);
            }
        }

        setRescheduleDates(newRescheduleBookings);
    };
    //#endregion

    //#region HTML
    let eventInd = -1;

    if (selectedEvent) {
        eventInd = events.findIndex(event => event.id === selectedEvent);
        if (eventInd === -1) {
            setSelectedEvent(0);
        }
    }

    if (eventInd !== -1) {
        return (<>
            <Toolbar />
            <Container sx={{ p: { xs: 0, sm: 2 }, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
                <WebinarAlert desktop={false} />
                <Box component="form" onSubmit={(event: any) => { event.preventDefault(); }} noValidate>
                    <Paper elevation={theme.palette.mode === "dark" ? 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} sx={{ p: 2, borderRadius: { xs: "0px", sm: 1, boxShadow: "none" } }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                <TextField
                                    fullWidth
                                    disabled
                                    variant="standard"
                                    value={events[eventInd].title}
                                />
                                <IconButton aria-label="Close" onClick={() => { setSelectedEvent(0); }}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} fullWidth sx={{ minWidth: 0 }} />}
                                    label="Start"
                                    disabled
                                    value={events[eventInd].start}
                                    onChange={() => { }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} fullWidth sx={{ minWidth: 0 }} />}
                                    label="End"
                                    disabled
                                    value={events[eventInd].end}
                                    onChange={() => { }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label="Description"
                                    value={events[eventInd].description}
                                    multiline
                                />
                            </Grid>

                            {!((events[eventInd].type === EventType.BOOTCAMP || events[eventInd].type === EventType.MAIN || events[eventInd].type === EventType.SUPPORT) && hasRole(user, [Role.PARENT, Role.ADMIN]))
                                ? null : <>
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton sx={{ textTransform: "none", minWidth: 0 }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { rescheduleBooking(events[eventInd].id, false); setSelectedEvent(0); }}>
                                            {messages.RESCHEDULE}
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton sx={{ textTransform: "none", minWidth: 0 }} fullWidth disableElevation type="button" variant="contained" loading={isLoading} onClick={() => { rescheduleBooking(events[eventInd].id, true); setSelectedEvent(0); }}>
                                            {messages.RESCHEDULEREMAINING}
                                        </LoadingButton>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <Typography>{messages.ORGANIZER}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Card
                                        sx={{
                                            borderRadius: "0px",
                                            boxShadow: "none",
                                            border: theme.palette.mode === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : undefined,
                                            borderBottomWidth: "0",
                                            "&:first-of-type": { borderTopLeftRadius: (theme) => theme.shape.borderRadius, borderTopRightRadius: (theme) => theme.shape.borderRadius },
                                            "&:last-of-type": { borderBottomWidth: "1px", borderBottomLeftRadius: (theme) => theme.shape.borderRadius, borderBottomRightRadius: (theme) => theme.shape.borderRadius }
                                        }}
                                        elevation={theme.palette.mode === "dark" ? 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"}>
                                        <CardActionArea sx={{ p: 2, display: "flex", flexDirection: "row", gap: 1 }} disabled={events[eventInd].owner.id === 0} onClick={() => { history.push(`/account/${events[eventInd].owner.id}`); }}>
                                            {!hasRole(user, Role.ADMIN) ? null : <Typography sx={{ flex: 1 }}>{getAccountTableId(events[eventInd].owner.id)}</Typography>}
                                            <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", flex: "1" }}>{events[eventInd].owner.name}</Typography>
                                            {events[eventInd].owner.id === 0 ? null : <Typography sx={{ textAlign: "right", flex: "1" }}>{ACCOUNT_TYPE_NAMES[getRole(events[eventInd].owner.id)]}</Typography>}
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>{messages.ATTENDEES}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {
                                        events[eventInd].attendees.map((account, index) => {
                                            if (!(hasRole(user, Role.ADMIN) || hasRole(user, Role.TEACHER)) && users.findIndex(user => user.id === account.id) === -1) {
                                                return null;
                                            }

                                            return <Card
                                                sx={{
                                                    borderRadius: "0px",
                                                    boxShadow: "none",
                                                    border: theme.palette.mode === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : undefined,
                                                    borderBottomWidth: "0",
                                                    "&:first-of-type": { borderTopLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderTopRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; } },
                                                    "&:last-of-type": { borderBottomWidth: "1px", borderBottomLeftRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; }, borderBottomRightRadius: (theme) => { return { xs: 0, sm: theme.shape.borderRadius }; } }
                                                }}
                                                elevation={theme.palette.mode === "dark" ? (index % 2) * 2 + 2 : 0} variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} key={account.id}>
                                                <CardActionArea sx={{ p: 2, display: "flex", flexDirection: "row", gap: 1 }} disabled={account.id === 0} onClick={() => { history.push(`/account/${account.id}`); }}>
                                                    {!hasRole(user, Role.ADMIN) ? null : <Typography sx={{ flex: 1 }}>{getAccountTableId(account.id)}</Typography>}
                                                    <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", flex: "1" }}>{account.name}</Typography>
                                                    {account.id === 0 ? null : <Typography sx={{ textAlign: "right", flex: "1" }}>{ACCOUNT_TYPE_NAMES[getRole(account.id)]}</Typography>}
                                                </CardActionArea>
                                            </Card>;
                                        })
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </>
        );
    }

    return (
        <Paper square sx={{ boxShadow: "none", display: "flex", flexDirection: "column", height: "100%", overflow: "auto" }} elevation={theme.palette.mode === "dark" ? 1 : 0}>
            <Dialog onClose={() => { setShowNewFeatures(false); }} open={showNewFeatures && !showNoChildren} PaperProps={{ sx: { boxShadow: "none" } }}>
                <Box sx={{ p: 2, display: "flex", flexDirection: { xs: "column", md: "column" } }}>
                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, textAlign: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold", flex: 1, pl: 5 }}>{messages.NEWFEATURE.TITLE}</Typography>
                            <Box><IconButton aria-label="close" onClick={() => { setShowNewFeatures(false); }}><CloseIcon /></IconButton></Box>
                        </Box>
                        <Typography sx={{ py: 2 }}>{messages.NEWFEATURE.DESCRIPTION}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
                        <LoadingButton fullWidth
                            sx={{ textTransform: "none" }} variant="contained" disableElevation
                            onClick={() => { downloadReminders(); setShowNewFeatures(false); }}
                        >
                            {messages.NEWFEATURE.DOWNLOAD}
                        </LoadingButton>
                        <LoadingButton fullWidth color="error" sx={{ textTransform: "none" }} onClick={() => { localStorage.setItem("downloadedReminders", "true"); setShowNewFeatures(false); }}>
                            {messages.NEWFEATURE.DOWNLOADED}
                        </LoadingButton>
                    </Box>
                </Box>
            </Dialog>
            <Dialog onClose={() => { setShowNoChildren(false); }} open={showNoChildren && (!!user && getRole(user.id) === Role.PARENT)} PaperProps={{ sx: { boxShadow: "none", overflowY: "hidden" } }}>
                <Box sx={{ p: 2, gap: 2, display: "flex", flexDirection: { xs: "column", md: "column" } }}>
                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2, textAlign: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold", flex: 1, pl: 5 }}>{messages.NEXTSTEPS.TITLE}</Typography>
                            <Box><IconButton aria-label="close" onClick={() => { setShowNoChildren(false); }}><CloseIcon /></IconButton></Box>
                        </Box>
                        <Typography>{messages.NEXTSTEPS.DESCRIPTION}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center", width: "100%", flexDirection: { xs: "column", sm: "row" } }}>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>{messages.NEXTSTEPS.INSTRUCTION}</InputLabel>
                                <Select
                                    value={null}
                                    onChange={(e) => { history.push(`/enroll${e.target.value ? "/" + e.target.value : ""}`); }}
                                    label={messages.NEXTSTEPS.INSTRUCTION}
                                >
                                    {
                                        users.map(owner => !hasRole(owner, [Role.STUDENT, Role.TEACHER]) ? null :

                                            <MenuItem key={owner.id} value={owner.id}
                                                sx={{
                                                    background: (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(owner, Role.TEACHER)) ? getAccountColor(owner.id) : undefined,
                                                    color: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(owner, Role.TEACHER)) ? theme.palette.getContrastText(getAccountColor(owner.id)) : undefined,
                                                }}>
                                                {owner.name}
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography>OR</Typography>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <LoadingButton fullWidth sx={{ textTransform: "none" }} variant="contained" disableElevation
                                onClick={() => { setShowNoChildren(false); history.push("/enroll"); }}
                            >
                                {messages.NEXTSTEPS.ENROLL}
                            </LoadingButton>
                        </Box>
                    </Box>

                    <LoadingButton fullWidth sx={{ textTransform: "none" }} variant="contained" disableElevation
                        onClick={() => { setShowNewFeatures(false); window.location.href = `${process.env.REACT_APP_LEGACY_URL}/online-tutoring`; }}>
                        {messages.NEXTSTEPS.SALES}
                    </LoadingButton>

                </Box>
            </Dialog>
            <Paper elevation={theme.palette.mode !== "dark" ? 0 : 2} className="mui-fixed"
                sx={{
                    flex: 1, display: "flex", flexDirection: "column",
                    top: 0,
                    right: 0,
                    position: "fixed",
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    boxShadow: "none",
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    left: drawerWidth, transition: (theme) =>
                        isDrawerOpen ? theme.transitions.create(['left'], {
                            duration: theme.transitions.duration.enteringScreen,
                            easing: theme.transitions.easing.easeOut
                        }) : theme.transitions.create(['left'], {
                            duration: theme.transitions.duration.leavingScreen,
                            easing: theme.transitions.easing.sharp
                        })
                }} square
            >
                <Toolbar />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, p: 1 }}>
                    <Button variant="contained" sx={{ textTransform: "none" }} disableElevation onClick={() => { setDate(new Date()); }}>{messages.TODAY}</Button>
                    {mobileView ? null :
                        <>
                            <IconButton aria-label="Previous" onClick={onPrevClick}>
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <IconButton aria-label="Next" onClick={onNextClick}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </>
                    }
                    {mobileView ? null :
                        <Typography variant="h6" sx={{ flex: 1 }}>{view !== CalendarView.DAY ? format(date, 'MMMM y') : format(date, 'EEEE, MMMM d, y')}</Typography>
                    }
                    {(rescheduleDates.length === 0 || mobileView) ? null :
                        <Button variant="contained" sx={{ textTransform: "none" }} disableElevation onClick={() => { setRescheduleDates([]); }}>{messages.CANCEL}</Button>
                    }
                    <Select size="small"
                        sx={{ minWidth: { xs: 0, sm: 200 }, flex: { xs: 1, md: "unset" } }}
                        value={(users.findIndex(user => hasRole(user, [Role.STUDENT, Role.TEACHER]) && user.id === userId) !== -1) ? userId : 0}
                        onChange={(e) => { history.replace(`/calendar${e.target.value ? "/" + e.target.value : ""}`); }}>
                        <MenuItem value={0}>
                            {messages.EVERYONE}
                        </MenuItem>
                        {
                            users.map(owner => !hasRole(owner, [Role.STUDENT, Role.TEACHER]) ? null :
                                <MenuItem key={owner.id} value={owner.id}
                                    sx={{
                                        background: (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(owner, Role.TEACHER)) ? getAccountColor(owner.id) : undefined,
                                        color: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(owner, Role.TEACHER)) ? theme.palette.getContrastText(getAccountColor(owner.id)) : undefined,
                                    }}>
                                    {owner.name}
                                </MenuItem>)
                        }
                    </Select>
                    <Select value={view} size="small" onChange={(e) => { setView(e.target.value as CalendarView); }}>
                        <MenuItem value={CalendarView.DAY}>
                            {messages.DAY}
                        </MenuItem >
                        <MenuItem value={CalendarView.WEEK}>
                            {messages.WEEK}
                        </MenuItem >
                        <MenuItem value={CalendarView.MONTH}>
                            {messages.MONTH}
                        </MenuItem >
                        <MenuItem value={CalendarView.SCHEDULE}>
                            {messages.SCHEDULE}
                        </MenuItem >
                    </Select>
                    {mobileView ? null : <IconButton aria-label="Download" onClick={downloadReminders}>
                        <DownloadIcon />
                    </IconButton>
                    }
                </Box>
                {!mobileView ? null :
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", px: 1, pb: 1, gap: 1 }}>
                        <IconButton aria-label="Previous" onClick={onPrevClick}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{
                            flex: 1, textAlign: "center", minWidth: 0,
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100%"
                        }}>
                            {view !== CalendarView.DAY ? format(date, 'MMMM y') : format(date, 'EEE, MMM d, y')}
                        </Typography>
                        {rescheduleDates.length === 0 ? null :
                            <Button variant="contained" sx={{ textTransform: "none" }} disableElevation onClick={() => { setRescheduleDates([]); }}>{messages.CANCEL}</Button>
                        }
                        <IconButton aria-label="Download" onClick={downloadReminders}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton aria-label="Next" onClick={onNextClick}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </Box>
                }
                {view !== CalendarView.WEEK ? null :
                    <WeekCalendarHeader onDayClick={(date) => { setDate(date); setView(CalendarView.DAY); }} date={date} />
                }
            </Paper>
            <Toolbar />
            <Box sx={{ minHeight: theme => { return { xs: theme.spacing(13), md: theme.spacing(7) }; } }} />
            {view !== CalendarView.WEEK ? null : <Box sx={{ minHeight: theme => theme.spacing(7.5) }} />}
            <WebinarAlert desktop={false} />
            {
                numAPICalls < 2 ?
                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress size={80} />
                    </Box>
                    : (() => {
                        let startDate = setHours(setMinutes(setSeconds(setMilliseconds(setDay(setDateObjDate(date, 1), 1), 0), 0), 0), 0);

                        if (isBefore(startDate, addHours(new Date(), MIN_HOURS_BEFORE))) {
                            startDate = setHours(setMinutes(setSeconds(setMilliseconds(addHours(new Date(), MIN_HOURS_BEFORE), 0), 0), 0), 0);
                        }

                        let calEvents = events.filter(event => rescheduleDates.findIndex(booking => event.id === booking.id) === -1);
                        let calSlots = generateTeacherView(startDate, addDays(startDate, 7 * 6), events, allEvents, rescheduleDates, availabilities).data;

                        return <>
                            {view !== CalendarView.DAY ? null : <DayCalendar
                                onSlotClick={onSlotClick}
                                events={calEvents}
                                slots={calSlots}
                                date={date}
                                mobileView={mobileView}
                                onEventClick={onEventClick}
                            />}
                            {view !== CalendarView.WEEK ? null : <WeekCalendar
                                onSlotClick={onSlotClick}
                                events={calEvents}
                                slots={calSlots}
                                date={date}
                                mobileView={mobileView}
                                onEventClick={onEventClick}
                            />}
                            {view !== CalendarView.MONTH ? null : <MonthCalendar
                                onDayClick={(date) => { setDate(date); setView(CalendarView.DAY); }}
                                events={calEvents}
                                slots={calSlots}
                                date={date}
                                mobileView={mobileView}
                                onEventClick={onEventClick}
                            />}
                            {view !== CalendarView.SCHEDULE ? null : <ScheduleCalendar
                                onDayClick={(date) => { setDate(date); setView(CalendarView.DAY); }}
                                events={calEvents}
                                slots={calSlots}
                                date={date}
                                mobileView={mobileView}
                                onEventClick={onEventClick}
                            />}
                        </>;
                    })()
            }
        </Paper >
    );
    //#endregion
};

export default Component;