import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import { FormEventHandler, FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Email, STATUS_CODES } from "../../../../shared/types";
import { useRequireAuth } from "../../components/auth";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const { token } = useRequireAuth();
    const { RESEND_EMAIL: messages, ERRORS: errors } = useMessages();
    const snackbar = useSnackbar();
    const { id } = useParams<{ id: string; }>();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    const [emails, setEmails] = useState<Email[]>([]);
    const [selectedEmail, setSelectedEmail] = useState<number>(0);
    //#endregion

    //#region Use Effects
    useEffect(() => {
        (async () => {
            const searchResult = await fetch(`${process.env.REACT_APP_API_URL}/api/email/info`, {
                method: "POST",

                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ id: parseInt(id) })
            });

            switch (searchResult.status) {
                case STATUS_CODES.OK:
                    let data = await searchResult.json();
                    setEmails(data);
                    setSelectedEmail(0);
                    break;

                case STATUS_CODES.UNAUTHORIZED:
                    snackbar.show(errors.UNAUTHORIZED);
                    return;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }
        })().then(() => { });
    }, [id, token, errors, snackbar]);
    //#endregion

    //#region Submit Event Handlers
    const onSendSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        if (emails.length === 0) return;
        setIsLoading(true);

        const searchResult = await fetch(`${process.env.REACT_APP_API_URL}/api/email/send`, {
            method: "POST",
            credentials: "omit",
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ email: emails[selectedEmail] })
        });

        switch (searchResult.status) {
            case STATUS_CODES.OK:
                snackbar.show(messages.SENT);
                break;

            case STATUS_CODES.UNAUTHORIZED:
                snackbar.show(errors.UNAUTHORIZED);
                break;

            default:
                snackbar.show(errors.TRY_LATER);
                break;
        }

        setIsLoading(false);
    };
    //#endregion

    //#region HTML
    return (<>
        <Toolbar />
        <Container sx={{ p: { xs: 0, sm: 2 }, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
            <WebinarAlert desktop={false} />
            <Box component="form" onSubmit={onSendSubmit} noValidate>
                <Paper elevation={0} sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography variant="h5" fontWeight="bold" textAlign="center">
                        {messages.TITLE}
                    </Typography>
                    {emails.length === 0 ? <Typography sx={{ textAlign: "center" }}>{messages.NO_EMAILS}</Typography> : <>
                        <Paper elevation={theme.palette.mode === "dark" ? 1 : 0}>
                            <FormControl fullWidth>
                                <InputLabel>Email</InputLabel>
                                <Select fullWidth value={selectedEmail} label={"email"} onChange={(e) => { setSelectedEmail(e.target.value as number); }}>
                                    {emails.map((email, ind) => <MenuItem key={email.name} value={ind}>{email.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Paper>
                        <Paper variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                {
                                    selectedEmail >= emails.length ? null :
                                        emails[selectedEmail].variables.map((variable, ind) =>
                                            <Grid key={variable.name} item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={variable.name}
                                                    value={variable.value}
                                                    onChange={(e) => {
                                                        let newEmails = [...emails];
                                                        newEmails[selectedEmail].variables[ind].value = e.target.value;
                                                        setEmails(newEmails);
                                                    }}
                                                />
                                            </Grid>
                                        )
                                }
                                <Grid item xs={12}>
                                    <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                                        {messages.SEND}
                                    </LoadingButton >
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                    }
                </Paper>
            </Box>
        </Container>
    </>
    );
    //#endregion
};

export default Component;