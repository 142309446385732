import {
  FunctionComponent,
  FormEvent,
  useState,
  ChangeEventHandler,
  SyntheticEvent,
} from "react";
import {
  Box,
  FormControl,
  TextField,
  Autocomplete,
  FormHelperText,
  useTheme,
} from "@mui/material";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeCardElement,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import { countries, CountryType } from "../../countries";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMessages } from "../../components/messages";

const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_STRIPE_PK!);

interface IProps {
  showCoupon: boolean;
  expectedCoupon: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<number | null>>;
  setEnteredCouponCode: React.Dispatch<React.SetStateAction<string>>;
  onCardSubmit: (
    card: StripeCardElement,
    stripe: Stripe,
    name: string,
    email: string,
    country: number | null,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCardErrors: React.Dispatch<React.SetStateAction<string>>
  ) => void;
}

/**
 * Payment Form to handle stripe payment. Currently only used on checkout page.
 * But there's scope to use it in enrollment process as well.
 * 
 */
const PaymentForm: FunctionComponent<IProps> = ({
  showCoupon = true,
  expectedCoupon,
  setSelectedCountry,
  setEnteredCouponCode,
  onCardSubmit,
}) => {
  //#region Hooks
  const theme = useTheme();
  const { ERRORS: errors, CHECKOUT: messages } = useMessages();
  //#endregion

  //#region State
  const [couponCode, setCouponCode] = useState("");
  const [country, setCountry] = useState<number | null>(null);
  const [nameOnCard, setNameOnCard] = useState("");
  const [email, setEmail] = useState("");

  const [cardFocused, setCardFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCouponLoading, setIsCouponCodeLoading] = useState(false);

  const [countryErrors, setCountryErrors] = useState("");
  const [cardErrors, setCardErrors] = useState(" ");
  const [couponCodeErrors, setCouponCodeErrors] = useState("");
  const [nameOnCardErrors, setNameOnCardErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  //#endregion

  //#region Change event handlers
  const onCouponCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const val = event.target.value;
    setIsCouponCodeLoading(true);
    setCouponCode(val);
    setEnteredCouponCode(val);

    if (val.trim().length === 0 || val === expectedCoupon) {
      setCouponCodeErrors("");
    } else {
      setCouponCodeErrors(errors.INVALID_COUPON);
    }
    setIsCouponCodeLoading(false);
  };

  const onCountryChange = (
    event: SyntheticEvent,
    value: CountryType | null
  ) => {
    const val = value ? value.ind : null;

    if (val == null) {
      setCountryErrors(errors.REQUIRED);
    } else {
      setCountryErrors("");
    }
    setCountry(val);
    setSelectedCountry(val);
  };

  const onNameOnCardChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setNameOnCardErrors("");
    setNameOnCard(event.target.value);
  };

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;

    if (value.trim().length === 0) {
      setEmailErrors(errors.REQUIRED);
    } else if (value.trim().length > 50) {
      setEmailErrors(errors.MAX_LENGTH_50);
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setEmailErrors("Invalid email");
    } else {
      setEmailErrors("");
    }

    setEmail(value);
  };

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    if (event.empty) {
      setCardErrors(errors.REQUIRED);
    } else if (event.error) {
      setCardErrors(event.error.message);
    } else if (!event.complete) {
      setCardErrors(errors.INCOMPLETE_CARD);
    } else {
      setCardErrors("");
    }
  };

  const isCardSubmitable = () => {
    if (
      isLoading ||
      email.trim() === "" ||
      nameOnCard.trim() === "" ||
      nameOnCardErrors !== "" ||
      emailErrors !== "" ||
      cardErrors !== "" ||
      countryErrors !== "" ||
      country === 0 ||
      couponCodeErrors !== ""
    )
      return false;
    return true;
  };
  //#endregion

  //#region Submit handlers
  const handleCardSubmit = (
    event: FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    event.preventDefault();

    if (cardErrors === " ") setCardErrors(errors.REQUIRED);
    if (!isCardSubmitable()) return;
    if (!stripe || !elements) return;

    const card = elements.getElement(CardElement);
    if (card === null) return;

    onCardSubmit(
      card,
      stripe,
      nameOnCard,
      email,
      country,
      setIsLoading,
      setCardErrors
    );
  };
  //#endregion

  //#region Other
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        fontFamily: theme.typography.fontFamily?.toString(),
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: theme.palette.text.secondary,
        },
        color: theme.palette.text.primary,
      },
      invalid: {
        color: theme.palette.error.main,
        ":focus": {
          color: theme.palette.error.light,
        },
        ":hover": {
          color: theme.palette.error.light,
        },
      },
    },
  };
  //#endregion

  return (
    <Elements stripe={STRIPE_PROMISE}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Box
            sx={{
              p: 2,
              flex: 1,
              minHeight: { xs: 0, md: 500 },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            component="form"
            onSubmit={(event: FormEvent) =>
              handleCardSubmit(event, stripe, elements)
            }
            noValidate
          >
            <TextField
              label={messages.PAYMENT.EMAIL}
              value={email}
              error={emailErrors !== ""}
              helperText={emailErrors}
              autoComplete="email"
              onChange={onEmailChange}
              fullWidth
              required
            />
            <TextField
              label={messages.PAYMENT.NAME}
              value={nameOnCard}
              error={nameOnCardErrors !== ""}
              helperText={nameOnCardErrors}
              autoComplete="cc-name"
              onChange={onNameOnCardChange}
              fullWidth
              required
            />
            <Autocomplete
              fullWidth
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box
                    draggable="false"
                    component="img"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                    sx={{ userSelect: "none", mr: 2, flexShrink: 0 }}
                  />
                  {`${option.label} (${option.code})`}
                </Box>
              )}
              value={country ? countries[country] : null}
              onChange={onCountryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={messages.PAYMENT.COUNTRY}
                  error={countryErrors !== ""}
                  helperText={countryErrors}
                  autoComplete="country-name"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            <FormControl fullWidth required error={cardErrors.trim() !== ""}>
              <Box
                sx={{
                  padding: 2.25,
                  border: cardFocused ? 2 : 1,
                  borderColor: (theme) =>
                    cardErrors.trim() !== ""
                      ? theme.palette.error.main
                      : cardFocused
                      ? theme.palette.primary.main
                      : theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.23)"
                      : "rgba(0, 0, 0, 0.23)",
                  borderRadius: 1,
                  ":hover": {
                    borderColor:
                      cardFocused || cardErrors.trim() !== ""
                        ? null
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "rgba(0, 0, 0, 0.87)",
                  },
                }}
              >
                <Box
                  sx={{
                    border: cardFocused ? 0 : 1,
                    borderColor: "transparent",
                  }}
                >
                  <CardElement
                    options={CARD_ELEMENT_OPTIONS}
                    onFocus={() => {
                      setCardFocused(true);
                    }}
                    onBlur={() => {
                      setCardFocused(false);
                    }}
                    onChange={onCardChange}
                  />
                </Box>
              </Box>
              <FormHelperText>{cardErrors.trim()}</FormHelperText>
            </FormControl>
            {showCoupon && (
              <TextField
                label={messages.PAYMENT.COUPON_CODE}
                value={couponCode}
                error={couponCodeErrors !== ""}
                helperText={couponCodeErrors}
                onChange={onCouponCodeChange}
                fullWidth
              />
            )}
            <Box sx={{ flex: 1 }} />
            <LoadingButton
              sx={{ textTransform: "none" }}
              disableElevation
              fullWidth
              type="submit"
              variant="contained"
              disabled={!stripe}
              loading={isLoading || isCouponLoading}
            >
              {messages.PAYMENT.PAY}
            </LoadingButton>
          </Box>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default PaymentForm;
