import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Grid, Paper, TextField, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEventHandler, FormEventHandler, FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { STATUS_CODES } from "../../../../shared/types";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useAuth } from "../../components/auth";
import { useMessages } from "../../components/messages";
import { useSnackbar } from "../../components/snackbar";
import WebinarAlert from "../../components/webinarAlert";

const Component: FunctionComponent = () => {
    //#region Hooks
    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory<{ redirect?: string; }>();
    const { RESET_PASSWORD: messages, ERRORS: errors } = useMessages();
    const { user, setToken } = useAuth();
    const snackbar = useSnackbar();;
    const { code } = useParams<{ code: string; }>();
    //#endregion

    //#region State
    const [isLoading, setIsLoading] = useState(false);
    //#endregion

    //#region Form Fields
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    //#endregion

    //#region Form Fields Errors
    const [passwordErrors, setPasswordErrors] = useState("");
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState("");
    //#endregion

    //#region Use Effects
    useEffect(() => {
        if (user !== null) {
            history.replace((history.location.state && history.location.state.redirect) ? history.location.state.redirect : "/calendar");
        }
    }, [user, history]);
    //#endregion

    //#region Change event handlers
    const onPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changePassword(event.target.value);
    };

    const changePassword = (value: string) => {
        // Validators
        if (value.length === 0) {
            setPasswordErrors(errors.REQUIRED);
        } else if (!/[A-Z]/.test(value)) {
            setPasswordErrors(errors.MISSING_UPPERCASE_CHAR);
        } else if (!/[a-z]/.test(value)) {
            setPasswordErrors(errors.MISSING_LOWERCASE_CHAR);
        } else if (!/[\d]/.test(value)) {
            setPasswordErrors(errors.MISSING_NUMBER);
        } else if (!/[@$!%*?&]/.test(value)) {
            setPasswordErrors(errors.MISSING_SPECIAL_CHARACETER);
        } else if (value.length < 8) {
            setPasswordErrors(errors.MIN_LENGTH_8);
        } else if (value.length > 50) {
            setPasswordErrors(errors.MAX_LENGTH_50);
        } else {
            setPasswordErrors("");
        }

        if (value === confirmPassword) {
            if (confirmPasswordErrors === errors.PASSWORD_MISMATCH) {
                setConfirmPasswordErrors("");
            }
        } else if (confirmPassword !== "" && confirmPasswordErrors === "") {
            setConfirmPasswordErrors(errors.PASSWORD_MISMATCH);
        }

        setPassword(value);
    };

    const onConfirmPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        changeConfirmPassword(event.target.value);
    };

    const changeConfirmPassword = (value: string) => {
        // Validators
        if (value.length === 0) {
            setConfirmPasswordErrors(errors.REQUIRED);
        } else if (value !== password) {
            setConfirmPasswordErrors(errors.PASSWORD_MISMATCH);
        } else {
            setConfirmPasswordErrors("");
        }

        setConfirmPassword(value);
    };
    //#endregion

    //#region Validity Checks
    const isFormSubmitable = () => {
        if (isLoading ||
            password === "" ||
            confirmPassword === "") {
            return false;
        }

        if (passwordErrors !== "" ||
            confirmPasswordErrors !== "") {
            return false;
        }

        return true;
    };
    //#endregion

    //#region Submit Event Handlers
    const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        // Populate error messages
        changePassword(password);
        changeConfirmPassword(confirmPassword);

        if (!isFormSubmitable()) return;

        try {
            setIsLoading(true);

            let result = await fetch(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
                method: "POST",

                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    password: password,
                    code: code,
                })
            });

            switch (result.status) {
                case STATUS_CODES.OK:
                    snackbar.show(messages.SUCCESS);
                    return setToken((await result.json()).token);

                case STATUS_CODES.NOT_FOUND:
                    snackbar.show(errors.INVALID_LINK);
                    break;

                default:
                    snackbar.show(errors.TRY_LATER);
                    break;
            }

            setIsLoading(false);
        } catch (error) {
            snackbar.show(errors.TRY_LATER);
            setIsLoading(false);
        }
    };
    //#endregion

    //#region HTML
    return (<Container sx={{ px: { xs: 0, sm: 2 }, pb: { xs: 0, sm: 2 }, pt: 2, display: "flex", flexDirection: "column", gap: { xs: 0, sm: 2 } }} maxWidth="sm">
        <WebinarAlert />
        <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Logo height={theme.spacing(5)} />
        </Box>
        <Paper elevation={0}>
            <Typography sx={{ p: { xs: 2, sm: 0 } }} variant="h5" fontWeight="bold" textAlign="center">
                {messages.TITLE}
            </Typography>
        </Paper>
        <Box sx={{ px: { xs: 0, sm: 8 } }} component="form" onSubmit={onFormSubmit} noValidate>
            <Paper sx={{ borderRadius: { xs: 0, sm: 1 }, boxShadow: "none", p: { xs: 2, sm: 4 } }} elevation={theme.palette.mode !== "dark" ? 0 : 2} variant={(theme.palette.mode === "dark" || isMobile) ? "elevation" : "outlined"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={messages.PASSWORD}
                            value={password}
                            error={passwordErrors !== ""}
                            helperText={passwordErrors}
                            type="password"
                            autoComplete="new-password"
                            onChange={onPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={messages.CONFIRM_PASSWORD}
                            value={confirmPassword}
                            error={confirmPasswordErrors !== ""}
                            helperText={confirmPasswordErrors}
                            type="password"
                            autoComplete="new-password"
                            onChange={onConfirmPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton sx={{ textTransform: "none" }} disableElevation fullWidth type="submit" variant="contained" loading={isLoading}>
                            {messages.RESET}
                        </LoadingButton >
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    </Container>
    );
    //#endregion
};

export default Component;
