import { Button, Theme, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { addHours, addMinutes, format, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";
import { FunctionComponent } from "react";
import { CalendarEvent, DateIterator, EventType, Role } from "../../../../../shared/types";
import { getAccountColor, hasRole } from "../../../../../shared/utils";
import { useAuth } from "../../../components/auth";
import { advance, getUntil } from "../../../dateIterator";
import { isAllDay, Slot } from "../../../scheduling";

interface Props {
    onSlotClick: (slot: Slot) => void;
    onEventClick: (event: CalendarEvent) => void;
    events: CalendarEvent[];
    date: Date;
    slots: Slot[];
    mobileView: boolean;
}

const Component: FunctionComponent<Props> = ({ mobileView, events, slots, onSlotClick, onEventClick, date }) => {
    const { user } = useAuth();

    const getAttendees = (event: CalendarEvent) => {
        if (event.attendees.length === 0) {
            return "";
        }
        if (event.attendees.length === 1) {
            return event.attendees[0].name + " - ";
        }
        return "";
    };


    const getColor = (theme: Theme, type: EventType, color: string = "main"): string => {
        return (theme.palette as any)[`event${type}`][color];
    };

    const getDayCalendarHour = (startDate: Date, eventView: DateIterator<CalendarEvent>, teacherView: DateIterator<Slot>, allDay: boolean) => {
        let endOfDay = allDay ? addMinutes(startDate, 1) : addHours(startDate, 1);

        advance(eventView, startDate);
        advance(teacherView, startDate);
        let events: CalendarEvent[] = getUntil(eventView, endOfDay).filter(event => allDay === isAllDay(event.start, event.end, startDate));
        let slots: Slot[] = getUntil(teacherView, endOfDay).filter(slot => allDay === isAllDay(slot.start, slot.end, startDate));

        if (events.length === 0 && slots.length === 0 && allDay) return null;

        return <Box key={startDate.toISOString() + (allDay ? "All Day" : "")} sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}`, flex: 1, display: "flex", flexDirection: "row" }}>
            <Box sx={{
                display: "flex", flexDirection: "row",
                minWidth: theme => theme.spacing(8),
                p: 1,
                py: 2.75,
                borderRight: theme => `1px solid ${theme.palette.divider}`
            }}>
                <Typography variant="body2">
                    {allDay ? "All Day" : format(startDate, 'h aaa')}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, minWidth: 0, flex: 1, py: 0.5 }}>
                {slots.map(slot => <Button
                    key={slot.start.toISOString()}
                    disableElevation
                    onClick={() => { onSlotClick(slot); }}
                    variant="contained"
                    sx={{ borderRadius: 0, flex: 1, minWidth: 0, p: 1, textTransform: "none", display: "flex", flexDirection: "column", alignItems: 'flex-start' }}
                >
                    <Typography variant="body2" sx={{
                        textOverflow: "clip",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "100%"
                    }}>
                        {`Reschedule`}
                    </Typography>
                    <Typography variant="body2" sx={{
                        textOverflow: "clip",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "100%"
                    }}>
                        {allDay ? "All Day" : `${format(slot.start, "h:mmaaa")} - ${format(slot.end, "h:mmaaa")}`}
                    </Typography>
                </Button>
                )}
                {events.map(event => {
                    return <Button key={event.id}
                        disableElevation
                        onClick={() => { onEventClick(event); }}
                        variant="contained"
                        color={`event${event.type}` as any}
                        sx={{
                            borderRadius: 0,
                            flex: 1, minWidth: 0,
                            p: 1, textTransform: "none", display: "flex", flexDirection: "column", alignItems: 'flex-start',
                            background: (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? getAccountColor(event.owner.id) : undefined,
                            border: (theme) => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? `2px solid ${getColor(theme, event.type)}` : undefined,
                            color: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? theme.palette.getContrastText(getAccountColor(event.owner.id)) : undefined,
                            "&:hover": { background: theme => (hasRole(user, [Role.TEACHER, Role.ADMIN]) && hasRole(event.owner, Role.TEACHER)) ? theme.palette.augmentColor({ color: { main: getAccountColor(event.owner.id) } }).dark : undefined },
                        }}
                    >
                        <Typography variant="body2" sx={{
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100%"
                        }}>
                            {`${getAttendees(event)}${event.title}`}
                        </Typography>
                        <Typography variant="body2" sx={{
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100%"
                        }}>
                            {allDay ? "All Day" : `${format(event.start, "h:mmaaa")} - ${format(event.end, "h:mmaaa")}`}
                        </Typography>
                    </Button>;
                }
                )}
            </Box>
        </Box>;
    };

    let startDate = setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
    let hours = [];
    let eventView: DateIterator<CalendarEvent> = { index: 0, data: events };
    let teacherView: DateIterator<Slot> = { index: 0, data: slots };

    let allDay = getDayCalendarHour(startDate, eventView, teacherView, true);
    if (allDay) {
        hours.push(allDay);
    }

    for (let i = 0; i < 24; i++) {
        hours.push(getDayCalendarHour(startDate, eventView, teacherView, false));
        startDate = addHours(startDate, 1);
    }

    return <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }} >
        {hours}
    </Box>;
};

export default Component;;